import api from '../../lib/api';

export const UsersActionsTypes = {
  FETCH_USERS_STARTED: 'FETCH_USERS_STARTED',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',
  FETCH_USERS_COUNT_STARTED: 'FETCH_USERS_COUNT_STARTED',
  FETCH_USERS_COUNT_SUCCESS: 'FETCH_USERS_COUNT_SUCCESS',
  FETCH_USERS_COUNT_FAILURE: 'FETCH_USERS_COUNT_FAILURE',
  FETCH_USER_BYID_STARTED: 'FETCH_USER_BYID_STARTED',
  FETCH_USER_BYID_SUCCESS: 'FETCH_USER_BYID_SUCCESS',
  FETCH_USER_BYID_FAILURE: 'FETCH_USER_BYID_FAILURE',
  EDIT_USER_BYID_STARTED: 'EDIT_USER_BYID_STARTED',
  EDIT_USER_BYID_SUCCESS: 'EDIT_USER_BYID_SUCCESS',
  EDIT_USER_BYID_FAILURE: 'EDIT_USER_BYID_FAILURE',
  FETCH_RIDER_CASH_FLOW_SUCCESS: 'FETCH_RIDER_CASH_FLOW_SUCCESS',
  FETCH_DRIVER_CASH_FLOW_SUCCESS: 'FETCH_DRIVER_CASH_FLOW_SUCCESS',
  FETCH_PAYMENT_SOURCES_SUCCESS: 'FETCH_PAYMENT_SOURCES_SUCCESS',
  FETCH_SESSIONS_SUCCESS: 'FETCH_SESSIONS_SUCCESS',
  FETCH_DRIVER_SCORE_SUCCESS: 'FETCH_DRIVER_SCORE_SUCCESS',
  FETCH_AVERAGE_DRIVER_SCORES_SUCCESS: 'FETCH_AVERAGE_DRIVER_SCORES_SUCCESS',
  FETCH_RIDE_HISTORY_SUCCESS: 'FETCH_RIDE_HISTORY_SUCCESS',
  REFUND_RIDE_SUCCESS: 'REFUND_RIDE_SUCCESS',
  RELOAD_USER_SUCCESS: 'RELOAD_USER_SUCCESS',
  FETCH_DRIVE_HISTORY_SUCCESS: 'FETCH_DRIVE_HISTORY_SUCCESS',
  FETCH_PROMOTIONS_SUCCESS: 'FETCH_PROMOTIONS_SUCCESS',
  FETCH_AVAILABLE_PROMOTIONS_SUCCESS: 'FETCH_AVAILABLE_PROMOTIONS_SUCCESS',
  FETCH_BLACKLISTED_DATES_SUCCESS: 'FETCH_BLACKLISTED_DATES_SUCCESS',
  FETCH_BLACKLISTED_TIMES_SUCCESS: 'FETCH_BLACKLISTED_TIMES_SUCCESS',
  FETCH_CURRENT_LOCATION_SUCCESS: "FETCH_CURRENT_LOCATION_SUCCESS",
  FETCH_CURRENT_LOCATION_FAILURE: "FETCH_CURRENT_LOCATION_FAILURE",

  RESET_STATE: 'RESET_STATE',
  RESET_ERROR: 'RESET_ERROR',
};

export const fetchUsers = ({ offset, limit }) => async dispatch => {
  dispatch({
    type: UsersActionsTypes.FETCH_USERS_STARTED,
  });
  try {
    const users = await api.users.getUsers({ offset, limit });
    dispatch({
      type: UsersActionsTypes.FETCH_USERS_SUCCESS,
      payload: users,
    });
  } catch (error) {
    dispatch({
      type: UsersActionsTypes.FETCH_USERS_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    })
  }
};

export const fetchUsersCount = () => async dispatch => {
  dispatch({
    type: UsersActionsTypes.FETCH_USERS_COUNT_STARTED,
  });
  try {
    const users = await api.users.getUsers({ offset: 0, limit: 1 });
    dispatch({
      type: UsersActionsTypes.FETCH_USERS_COUNT_SUCCESS,
      payload: users.count,
    });
  } catch (error) {
    dispatch({
      type: UsersActionsTypes.FETCH_USERS_COUNT_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    })
  }
};

export const fetchUserById = (id) => async dispatch => {
  dispatch({
    type: UsersActionsTypes.FETCH_USER_BYID_STARTED,
  });
  try {
    const user = await api.users.getUser({ id });
    dispatch({
      type: UsersActionsTypes.FETCH_USER_BYID_SUCCESS,
      payload: user,
    });
    return { success: true };
  } catch (error) {
    dispatch({
      type: UsersActionsTypes.FETCH_USER_BYID_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    })
    return { success: false };
  }
};

export const fetchCurrentLocation = (userId) => async (dispatch) => {
  try {
    const location = await api.users.getCurrentLocation(userId);
    dispatch({
      type: UsersActionsTypes.FETCH_CURRENT_LOCATION_SUCCESS,
      payload: { userId, location },
    });
  } catch (error) {
    dispatch({
      type: UsersActionsTypes.FETCH_CURRENT_LOCATION_FAILURE,
      payload: { userId, error },
    });
  }
};

export const getRiderCashFlow = (userId) => async dispatch => {
  try {
    const data = await api.users.getRiderCashFlow({ id: userId });
    dispatch({
      type: UsersActionsTypes.FETCH_RIDER_CASH_FLOW_SUCCESS,
      payload: data,
    });
  } catch {
    return { success: false };
  }
};

export const getDriverCashFlow = (userId) => async dispatch => {
  try {
    const data = await api.users.getDriverCashFlow({ id: userId });
    dispatch({
      type: UsersActionsTypes.FETCH_DRIVER_CASH_FLOW_SUCCESS,
      payload: data,
    });
  } catch {
    return { success: false };
  }
};

export const getPaymentSources = (userId) => async dispatch => {
  try {
    const data = await api.users.getPaymentSources({ id: userId });
    dispatch({
      type: UsersActionsTypes.FETCH_PAYMENT_SOURCES_SUCCESS,
      payload: data,
    });
  } catch {
    return { success: false };
  }
};

export const removePaymentSource = (userId, sourceId) => async dispatch => {
  try {
    const data = await api.users.removePaymentSource({ id: userId, sourceId });
    dispatch({
      type: UsersActionsTypes.FETCH_PAYMENT_SOURCES_SUCCESS,
      payload: data,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const setDefaultPaymentSource = (userId, sourceId) => async dispatch => {
  try {
    const data = await api.users.setDefaultPaymentSource({ id: userId, sourceId });
    dispatch({
      type: UsersActionsTypes.FETCH_PAYMENT_SOURCES_SUCCESS,
      payload: data,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};


export const editUserById = (id, update) => async dispatch => {
  dispatch({
    type: UsersActionsTypes.EDIT_USER_BYID_STARTED,
  });
  try {
    const user = await api.users.editUser({ id, update });
    dispatch({
      type: UsersActionsTypes.EDIT_USER_BYID_SUCCESS,
      payload: user,
    });
    return { success: true };
  } catch (error) {
    return {
      success: false,
      error: error && error.response ? error.response.data.error : error.message,
    }
  }
};

export const getSessions = (userId) => async dispatch => {
  try {
    const sessions = await api.users.getSessions({ id: userId });
    dispatch({
      type: UsersActionsTypes.FETCH_SESSIONS_SUCCESS,
      payload: sessions,
    });
  } catch {
    return { success: false };
  }
};

export const revokeSession = (userId, sessionId) => async dispatch => {
  try {
    const sessions = await api.users.revokeSession({ id: userId, sessionId });
    dispatch({
      type: UsersActionsTypes.FETCH_SESSIONS_SUCCESS,
      payload: sessions,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const getDriverScore = (userId) => async dispatch => {
  try {
    const score = await api.users.getDriverScore({ id: userId });
    dispatch({
      type: UsersActionsTypes.FETCH_DRIVER_SCORE_SUCCESS,
      payload: score,
    });
  } catch {
    return { success: false };
  }
};

export const getAverageDriverScores = () => async dispatch => {
  try {
    const score = await api.users.getAverageDriverScores();
    dispatch({
      type: UsersActionsTypes.FETCH_AVERAGE_DRIVER_SCORES_SUCCESS,
      payload: score,
    });
  } catch {
    return { success: false };
  }
};

export const getRideHistory = (id, offset, limit) => async dispatch => {
  try {
    const history = await api.users.getRideHistory({ id, offset, limit });
    dispatch({
      type: UsersActionsTypes.FETCH_RIDE_HISTORY_SUCCESS,
      payload: history,
    });
  } catch {
    return { success: false };
  }
};

export const refundRide = (userId, rideId, amount) => async dispatch => {
  try {
    const ride = await api.users.refundRide({ rideId, amount });
    dispatch({
      type: UsersActionsTypes.REFUND_RIDE_SUCCESS,
      payload: ride,
    });

    const user = await api.users.getUser({ id: userId });
    dispatch({
      type: UsersActionsTypes.RELOAD_USER_SUCCESS,
      payload: user,
    });

    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

export const getDriveHistory = (id, offset, limit) => async dispatch => {
  try {
    const history = await api.users.getDriveHistory({ id, offset, limit });
    dispatch({
      type: UsersActionsTypes.FETCH_DRIVE_HISTORY_SUCCESS,
      payload: history,
    });
  } catch {
    return { success: false };
  }
};

export const getPromotions = (id) => async dispatch => {
  try {
    const promotions = await api.users.getPromotions({ id });
    dispatch({
      type: UsersActionsTypes.FETCH_PROMOTIONS_SUCCESS,
      payload: promotions,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const removePromotion = (id, promoId) => async dispatch => {
  try {
    await api.users.removePromo({ id,  promoId });
    const promotions = await api.users.getPromotions({ id });
    dispatch({
      type: UsersActionsTypes.FETCH_PROMOTIONS_SUCCESS,
      payload: promotions,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const getAvailablePromotions = (q) => async dispatch => {
  try {
    const promotions = await api.search.searchPromos({ q, offset: 0, limit: 50 });
    dispatch({
      type: UsersActionsTypes.FETCH_AVAILABLE_PROMOTIONS_SUCCESS,
      payload: promotions,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const addPromotions = (id, codes) => async dispatch => {
  try {
    await api.users.addPromos({ id,  codes });
    const promotions = await api.users.getPromotions({ id });
    dispatch({
      type: UsersActionsTypes.FETCH_PROMOTIONS_SUCCESS,
      payload: promotions,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const addBlacklistedDate = (id, date) => async dispatch => {
  try {
    await api.users.addBlacklistedDate({ id,  date });
    const user = await api.users.getUser({ id });
    dispatch({
      type: UsersActionsTypes.FETCH_BLACKLISTED_DATES_SUCCESS,
      payload: user.blacklistedDates,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const removeBlacklistedDate = (id, date) => async dispatch => {
  try {
    await api.users.removeBlacklistedDate({ id,  date });
    const user = await api.users.getUser({ id });
    dispatch({
      type: UsersActionsTypes.FETCH_BLACKLISTED_DATES_SUCCESS,
      payload: user.blacklistedDates,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const addBlacklistedDateTime = (id, dateTime) => async dispatch => {
  try {
    await api.users.addBlacklistedDateTime({ id,  dateTime });
    const user = await api.users.getUser({ id });
    dispatch({
      type: UsersActionsTypes.FETCH_BLACKLISTED_TIMES_SUCCESS,
      payload: user.blacklistedTimes,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const removeBlacklistedDateTime = (id, dateTime) => async dispatch => {
  try {
    await api.users.removeBlacklistedDateTime({ id,  dateTime });
    const user = await api.users.getUser({ id });
    dispatch({
      type: UsersActionsTypes.FETCH_BLACKLISTED_TIMES_SUCCESS,
      payload: user.blacklistedTimes,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const resetState = () => dispatch => {
  dispatch({
    type: UsersActionsTypes.RESET_STATE
  });
};