export default {
  checkr: {
    baseUrl: " https://dashboard.checkrhq-staging.net",
  },
  api: {
    baseUrl: "https://api.hitch.net",
  },
  pusher: {
    key: "465b70d7691cb62ad395",
  },
  googleapis: {
    apiKey: "AIzaSyATiMxp0FfFGNHGEX53sjv_WUU4VlkuAY8",
  },
};
