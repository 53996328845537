import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.min.css';

const style = {
    root: {
        height: '360px',
        backgroundColor: '#eaeaea',
        '> img': {
            visibility: 'hidden',
        },
    },
};

const toolbar = {
    zoomIn: 4,
    zoomOut: 4,
    rotateLeft: 4,
};

const uid = () => (Math.round(Math.random() * 1000000)).toString();

const ImagePreview = ({ id = uid(), src }) => {
    const [viewer, setViewer] = useState(null);
    const rootRef = useRef();

    useEffect(() => {
        const v = new Viewer(document.getElementById(id), {
            title: false,
            inline: true,
            backdrop: false,
            fullscreen: false,
            navbar: false,
            toolbar,
        });
        setViewer(v);

        const sizeListener = setInterval(() => {
            if (rootRef.current &&
                v && v.viewer && v.onResize &&
                (
                    rootRef.current.clientHeight !== v.viewer.clientHeight ||
                    rootRef.current.clientWidth !== v.viewer.clientWidth
                )
            ) {
                v.onResize();
            }
        }, 1000);

        return () => {
            viewer && viewer.destroy();
            clearInterval(sizeListener);
        };
    }, []);

    useEffect(() => {
        viewer && viewer.update();
    }, [src]);

    return <Box ref={rootRef} sx={style.root}>
        <img id={id} src={src} height="360" width="100%"/>
    </Box>;
};

export default ImagePreview;