import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Paper, DataGrid, Stack, Typography, Autocomplete, Avatar } from '../../ui/core';
import { useQuery, useFormatters } from '../../hooks';
import { fetchStations, fetchSelectedStation, fetchAmenities, setSelectedStation } from '../../redux/Stations/actions';
import { getCities } from '../../redux/Cities/actions';

import PartnerTypeChip from '../../components/widgets/Stations/PartnerTypeChip';
import StationInlineSchedule from '../../components/widgets/Stations/StationInlineSchedule';
import StationDetailsDialog from '../../components/widgets/Stations/StationDetailsDialog';
import AddStation from '../../components/widgets/Stations/AddStation';

const style = {
  dataGrid: {
    '.station--inoperational': {
      opacity: 0.6,
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '&:hover, &.Mui-selected': {
        backgroundColor: (theme) => theme.palette.action.selected,
      },
    },
  }
};

const columns = [
  { field: 'id', hide: true },
  { field: 'name', headerName: 'Location', flex: 1, minWidth: 160 },
  { field: 'address', headerName: 'Address', flex: 1.5, minWidth: 160 },
  {
    field: 'amenities',
    headerName: 'Amenities',
    width: 150,
    renderCell: ({ row }) => {
      return row.amenities.slice(0, 4).map((a, idx) => {
        if (idx === 3) {
          return <Box
            key="more"
            sx={{
              zIndex: row.amenities.length - idx,
              borderRadius: "100%",
              border: "2px solid #FFF",
              backgroundColor: "primary.main",
              marginLeft: "-8px",
              height: "32px",
              width: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Typography color="white.main" variant="body2">+{row.amenities.slice(3).length}</Typography>
          </Box>
        }

        return <Box
        key={a.id}
        sx={{
          zIndex: row.amenities.length - idx,
          borderRadius: "100%",
          backgroundColor: "#EBEBEB",
          border: "2px solid #FFF",
          marginLeft: "-8px",
          height: "32px",
          width: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Avatar src={a.iconUrl} sx={{ height: "16px", width: "16px" }} />
      </Box>
      })
    }
  },
  {
    field: 'partnerType',
    headerName: 'Type',
    width: 180,
    renderCell: ({ row }) => <PartnerTypeChip partnerType={row.partnerType} />
  },
  {
    field: 'schedule',
    headerName: 'Availability to book',
    flex: 2,
    renderCell: ({ row }) => <StationInlineSchedule station={row} />
  }
];

const StationsTable = () => {
  const history = useHistory();
  let { page, city, stationId, fullScreen } = useQuery();
  page = Math.max(+page || 1, 1);
  const f = useFormatters();

  const { data, count, loading } = useSelector((state) => state.stations.all);
  const selection = useSelector((state) => state.stations.selection);
  const cities = useSelector((state) => state.cities.all);

  const pageSize = Math.floor((window.innerHeight - 248 - 52 - 57) / 57);
  const height = pageSize * 57 + 57 + 52 + 12;

  const rows = data.map((d) => ({
      ...d,
      id: d.id,
      city: d.city ? `${d.city.cityName}, ${d.city.stateAbbr}` : ''
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    getCities()(dispatch);
    fetchAmenities()(dispatch);
    if (stationId) {
      fetchSelectedStation(stationId)(dispatch);
    }
  }, [dispatch]);

  useEffect(() => {
    const query = {
        offset: (page - 1) * pageSize,
        limit: pageSize,
        ...(city ? { city } : {})
    };
    fetchStations(query)(dispatch);
  }, [page, city, dispatch]);

  const onRowClick = ({ row }) => {
    const params = new URLSearchParams();
    params.append('page', page);
    params.append('stationId', row.id);
    params.append('fullScreen', false);
    if (city) {
      params.append('city', city);
    }
    history.push({ search: params.toString() });
    setSelectedStation(data.find((s) => s.id === row.id))(dispatch);
  };

  const onCitySelected = (e, value) => {
    const params = new URLSearchParams();
    params.append('page', 1);
    value && params.append('city', value.id);
    history.push({ search: params.toString() });
};

  const onPageChange = (p) => {
    const params = new URLSearchParams();
    params.append('page', p + 1);
    city && params.append('city', city);
    history.push({ search: params.toString() });
  };

  const onDetailsClose = () => {
    const params = new URLSearchParams();
    params.append('page', page);
    city && params.append('city', city);
    history.push({ search: params.toString() });
    setSelectedStation(null)(dispatch);
  };

  const onDetailsFullscreen = (fullScreen) => {
    const params = new URLSearchParams();
    params.append('page', page);
    city && params.append('city', city);
    params.append('stationId', stationId);
    params.append('fullScreen', fullScreen);
    history.push({ search: params.toString() });
  };

  return (<Box>
    <Paper elevation={0}>
      <Stack
        sx={{ padding: '32px 32px 12px 32px', marginBottom: '32px' }}
        direction="row"
        spacing={2}
      >
          <Typography
            sx={{ fontWeight: 700, fontSize: '32px', lineHeight: '40px', flex: 1 }}
            >
              All Stations
          </Typography>
          <Autocomplete
              id="cityFilter"
              label="City"
              placeholder="Filter by city"
              value={cities.data.find((c) => c.id === city) || null}
              sx={{ width: '300px' }}
              disablePortal
              getOptionLabel={(c) => `${c.cityName}, ${c.stateAbbr}`}
              options={cities.data}
              onChange={onCitySelected}
          />
          <AddStation />
      </Stack>
      <div style={{ width: '100%', height: height }}>
        <DataGrid
          sx={style.dataGrid}
          rows={rows}
          columns={columns}
          getRowClassName={({ row }) => {
            if (!row.operational) {
              return 'station--inoperational';
            }
            return '';
          }}
          onRowClick={onRowClick}
          pageSize={pageSize}
          loading={loading}
          paginationMode="server"
          rowCount={count}
          page={page - 1}
          onPageChange={onPageChange}
        />
      </div>
    </Paper>
    {
      <StationDetailsDialog
        station={selection}
        fullScreen={fullScreen === "true"}
        onClose={onDetailsClose}
        onFullscreen={onDetailsFullscreen}
      />
    }
  </Box>);
};

export default StationsTable;