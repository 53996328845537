import { SearchActionTypes as types } from './action';

const initialState = {
  drivers: { data: [], loading: false },
  users: { data: [], loading: false },
  stations: { data: [], loading: false },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SEARCH_DRIVERS_STARTED:
      return {
        ...state,
        drivers: { ...state.drivers, loading: true },
      };
    case types.SEARCH_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: { data: action.payload, loading: false },
      };
    case types.SEARCH_DRIVERS_FAILURE:
      return {
        ...state,
        drivers: { ...state.drivers, loading: false }
      };
    case types.SEARCH_USERS_STARTED:
      return {
        ...state,
        users: { ...state.users, loading: true },
      };
    case types.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        users: { data: action.payload, loading: false },
      };
    case types.SEARCH_USERS_FAILURE:
      return {
        ...state,
        users: { ...state.users, loading: false }
      };
      case types.SEARCH_STATIONS_STARTED:
      return {
        ...state,
        stations: { ...state.stations, loading: true },
      };
    case types.SEARCH_STATIONS_SUCCESS:
      return {
        ...state,
        stations: { data: action.payload, loading: false },
      };
    case types.SEARCH_STATIONS_FAILURE:
      return {
        ...state,
        stations: { ...state.stations, loading: false }
      };
    default:
      return state;
  }
}