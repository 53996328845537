import { useState, useRef, useEffect } from "react";
import { styled, alpha } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay as MuiPickersDay } from '@mui/x-date-pickers/PickersDay';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Moment from 'moment';
import MuiTextField from '@mui/material/TextField';

const CustomPickersDay = styled(MuiPickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.85),
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    backgroundColor: theme.palette.primary.main,
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    borderColor: theme.palette.primary.main,
  }),
}));

const DatePicker = ({ label, value, onChange, fullWidth, moment = false, startDate, endDate, ...props }) => {
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const textField = useRef(null);

  const _onChange = (value) => {
    if (moment) {
      return onChange(value);
    }
    onChange && onChange({ target: { value: value.format("YYYY-MM-DD") } });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const input = textField.current ? textField.current.querySelector("input") : null;
      const key = event.key && event.key.toLowerCase();
      if (key === 'enter' && input && event.target && input.id === event.target.id) {
        setOpen(true);
      }
    };

    if (focused) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [focused]);

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value || (!startDate && !endDate)) {
      return <MuiPickersDay {...pickersDayProps} />;
    }

    const start = Moment(startDate || value);
    const end = Moment(endDate || value);
    console.log(startDate, endDate, value);
    console.log(start.format(), end.format());

    const dayIsBetween = date.isBetween(start, end, null, '[]');
    const isFirstDay = date.isSame(start, 'day');
    const isLastDay = date.isSame(end, 'day');

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return <LocalizationProvider dateAdapter={AdapterMoment}>
    <MuiDatePicker
      open={open}
      label={label}
      inputFormat="MM/DD/YYYY"
      value={value}
      onChange={_onChange}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false);
      }}
      renderDay={renderWeekPickerDay}
      renderInput={({ inputRef, inputProps, InputProps }) => {
          return <MuiTextField
          label={label}
          fullWidth={fullWidth}
          ref={textField}
          inputRef={inputRef}
          inputProps={{
            ...inputProps,
            onClick: () => !open && setOpen(true),
            onFocus: () => setFocused(true),
            onBlur: () => setFocused(false),
          }}
          InputProps={{
            ...InputProps,
          }}
        />;
      }}
        {...props}
      />
  </LocalizationProvider>
};

export default DatePicker;
