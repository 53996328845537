import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  CircularProgress,
  Chip,
  Avatar,
  IconButton,
  Dialog,
  DialogContent,
  AutocompleteInline,
  Button,
  Typography,
} from '../../../ui/core';
import { PlusIcon, CheckBoxBlankIcon, CheckBoxIcon } from '../../../ui/icons';
import { isEqual } from 'lodash';
import { updateStation } from '../../../redux/Stations/actions';

const AddAmenitiesDialog = ({ station, open, onClose }) => {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.stations.amenities);
  const [value, setValue] = useState(station.amenities);
  const [inputValue, setInputValue] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const hasChanges = !isEqual(station.amenities.map((a) => a.id).sort(), value.map((a) => a.id).sort());

  const isSelected = (o) => !!value.find((d) => d.id === o.id);

  const onInputChange = (_, value, reason) => {
    if (reason === 'input') {
      setInputValue(value);
    }
  }

  const save = async () => {
    setIsSaving(true);
    const { success } = await updateStation(station.id, { amenities: value.map((a) => a.id )})(dispatch);
    setIsSaving(false);
    if (success) {
      onClose();
    }
  };

  return <Dialog open={open} onClose={onClose}>
    <DialogContent sx={{ minWidth: 600, minHeight: 400, padding: 0 }}>
      <AutocompleteInline
        sx={{
          '.MuiInputBase-root': {
            padding: '12px 16px',
            width: '100%'
          }
        }}
        value={value}
        open
        freeSolo
        multiple
        autoSelect={false}
        renderTags={() => null}
        placeholder="Add amenities"
        options={options}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(o, v) => v.id === o.id}
        renderOption={(props, o) => (
          <Box
          key={o.id}
          component="li"
          sx={{
            display: "flex",
            padding: "10px 20px !important",
            alignItems: "center !important"
          }}
          {...props}
          >
            <Box mr="8px" mt="4px" display="flex">
              { isSelected(o) ? <CheckBoxIcon sx={{ color: "text.secondary" }} /> : <CheckBoxBlankIcon sx={{ color: "text.secondary" }} /> }
            </Box>
            <Avatar sx={{ height: "24px", width: "24px", marginRight: "10px" }} src={o.iconUrl} />
            <Typography>{o.name}</Typography>
          </Box>
        )}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onChange={(_, v) => setValue(v)}
      />
    </DialogContent>
    <Button
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        width: "100%",
        padding: "20px",
      }}
      size="large"
      variant="contained"
      color="primary"
      disabled={!hasChanges}
      loading={isSaving}
      onClick={save}
    >
        Confirm
    </Button>
  </Dialog>
};

const StationAmenitiesField = ({ station }) => {
  const [showAddDialog, setShowAddDialog] = useState(false);

  return <>
    <Box display="flex" flexWrap="wrap" margin="-8px" alignItems="center">
      {
        station.amenities.map((a) => <Chip
          sx={{ margin: "8px" }}
          key={a.id}
          label={a.name}
          avatar={<Avatar
            sx={{
              marginRight: "5px !important",
              height: "16px !important",
              width: "16px !important"
            }}
            alt={a.name}
            src={a.iconUrl}
          />}
        />)
      }
      <IconButton
        sx={{
          height: "32px",
          width: "32px",
          backgroundColor: "primary.main",
          "&:hover": {
            backgroundColor: "primary.main",
          },
          "&:focus": {
            backgroundColor: "primary.main",
          }
        }}
        size="small"
        onClick={() => setShowAddDialog(true)}
      >
        <PlusIcon sx={{ color: "white.main", fontSize: "24px" }} />
      </IconButton>
    </Box>
    {
      showAddDialog && <AddAmenitiesDialog
        station={station}
        open
        onClose={() => setShowAddDialog(false)}
      />
    }
  </>
};

export default StationAmenitiesField;
