import { useEffect } from "react";
import { DriverActionsTypes } from "../redux/Drivers/actions";
import { subscribe, unsubscribe } from "pusher-redux";

const additionalParams = () => {};

const useDriverLocationSub = () => {
  useEffect(() => {
    subscribe(
      `admin-drivers`,
      "driver-location-update",
      DriverActionsTypes.DRIVER_LOCATION_UPDATE,
      additionalParams
    );
    return () => {
      unsubscribe(
        `admin-drivers`,
        "driver-location-update",
        DriverActionsTypes.DRIVER_LOCATION_UPDATE
      );
    };
  }, []);
};

export default useDriverLocationSub;
