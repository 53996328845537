import React, { useEffect, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import DataGrid from './DataGrid';
import ChevronDownIcon from '../../icons/ChevronDownIcon';
import ChevronUpIcon from '../../icons/ChevronUpIcon';

const GroupedDataGrid = ({
  groupingColumn,
  columns,
  rows,
  onRowClick,
  getRowClassName,
  getRowHeight,
  getRowId,
  ...other
}) => {
  const [groups, setGroups] = useState({});
  const expanded = useMemo(() => !!groupingColumn.groupExpanded, [groupingColumn.groupExpanded])

  useEffect(() => {
    const _groups = rows.reduce((_rows, r) => {
      const v = r[groupingColumn.field];
      _rows[v] = _rows[v] || {
        value: v,
        expanded: expanded,
        getGroupOrder: groupingColumn.getGroupOrder ? groupingColumn.getGroupOrder : () => 1,
        rows: []
      };
      _rows[v].rows.push(r);
      return _rows;
    }, {});
    setGroups(_groups);
  }, [groupingColumn, columns, rows, expanded]);

  const _columns = columns.map((c, idx) => {
    return {
      ...c,
      renderCell: ({ row }) => {
        if (row.isDataGridGroup) {
          if (idx === 0) {
            return groupingColumn.renderGroupHeader ? groupingColumn.renderGroupHeader(row) : row.value;
          }
          if (idx === columns.length - 1) {
            return <Box flex="1" display="flex" alignContent="center" justifyContent="flex-end">
              {
                row.expanded ?  <ChevronDownIcon sx={{ fontSize: 20 }} /> : <ChevronUpIcon sx={{ fontSize: 20 }} />
              }
            </Box>;
          }
          return '';
        }
        return c.renderCell ? c.renderCell({ row }) : row[c.field];
      },
    };
  });

  const _rows = Object.values(groups)
    .sort((g1, g2) => g1.getGroupOrder({ value: g1.value }) - g2.getGroupOrder({ value: g2.value }))
    .reduce((_rows, group) => {
      _rows.push({
        id: `DataGrid_group_${group.value}`,
        isDataGridGroup: true,
        value: group.value,
        count: groupingColumn.getGroupCount ? groupingColumn.getGroupCount(group) : group.rows.length,
        expanded: group.expanded,
      });
      if (group.expanded) {
        group.rows.forEach((r) => _rows.push(r));
      }
      return _rows;
    }, []);

  const _getRowClassName = ({ row }) => {
    if (row.isDataGridGroup) {
      return 'DataGrid-row-group';
    }
    return getRowClassName ? getRowClassName({ row }) : '';
  };

  const _getRowHeight = ({ model }) => {
    if (model.isDataGridGroup) {
      return 48;
    }
    return getRowHeight ? getRowHeight({ model }) : '';
  };

  const _getRowId = (row) => {
    if (row.isDataGridGroup) {
      return row.id;
    }
    return getRowId ? getRowId(row): row.id;
  }

  const _onRowClick = ({ row }) => {
    if (row.isDataGridGroup) {
      setGroups({
        ...groups,
        [row.value]: {
          ...groups[row.value],
          expanded: !groups[row.value].expanded,
        },
      });
    } else {
      onRowClick && onRowClick({ row });
    }
  };

  return <DataGrid
    columns={_columns}
    rows={_rows}
    onRowClick={_onRowClick}
    getRowClassName={_getRowClassName}
    getRowHeight={_getRowHeight}
    getRowId={_getRowId}
    {...other}
  />
};

export default GroupedDataGrid;