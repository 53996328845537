import { useSelector } from "react-redux";

const RESTRICTED_IDS = [
  "6328ef71b2507a001f26bd6a",
  "6328efb3b2507a001f26bd77",
  "6328efceb2507a001f26bd78",
  "6328efebb2507a001f26bd79",
];

const usePermissions = () => {
  const decodedToken = useSelector((state) => state.auth.decodedToken);

  return {
    canViewDriverApprovals: true,
    canViewMetrics: !RESTRICTED_IDS.includes(decodedToken.sub),
    canViewUsers: !RESTRICTED_IDS.includes(decodedToken.sub),
    canViewRides: !RESTRICTED_IDS.includes(decodedToken.sub),
    canViewStations: !RESTRICTED_IDS.includes(decodedToken.sub),
  };
};

export default usePermissions;
