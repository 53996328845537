import MuiLink from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

const Link = ({ to, children, ...props }) => {
  if (to) {
    return <MuiLink component={RouterLink} to={to} {...props}>
      { children }
    </MuiLink>
  }

  return <MuiLink {...props}>
      { children }
    </MuiLink>
};

export default Link;
