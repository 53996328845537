import citiesApi from "./citiesApi";
import driversApi from "./DriversApi";
import searchApi from "./SearchApi";
import usersApi from "./UsersApi";
import tripsApi from "./TripsApi";
import tagsApi from "./TagsApi";
import locationsApi from "./LocationsApi";
import stationsApi from './StationsApi';

export default {
  cities: citiesApi,
  drivers: driversApi,
  search: searchApi,
  users: usersApi,
  trips: tripsApi,
  tags: tagsApi,
  locations: locationsApi,
  stations: stationsApi,
};
