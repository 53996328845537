import api from '../../lib/api';

export const SearchActionTypes = {
  SEARCH_DRIVERS_STARTED: 'SEARCH_DRIVERS_STARTED',
  SEARCH_DRIVERS_SUCCESS: 'SEARCH_DRIVERS_SUCCESS',
  SEARCH_DRIVERS_FAILURE: 'SEARCH_DRIVERS_FAILURE',
  SEARCH_USERS_STARTED: 'SEARCH_USERS_STARTED',
  SEARCH_USERS_SUCCESS: 'SEARCH_USERS_SUCCESS',
  SEARCH_USERS_FAILURE: 'SEARCH_USERS_FAILURE',
  SEARCH_STATIONS_STARTED: 'SEARCH_STATIONS_STARTED',
  SEARCH_STATIONS_SUCCESS: 'SEARCH_STATIONS_SUCCESS',
  SEARCH_STATIONS_FAILURE: 'SEARCH_STATIONS_FAILURE',
};

export const searchDrivers = ({ q, offset = 0, limit = 20 } = {}) => async dispatch => {
  dispatch({
    type: SearchActionTypes.SEARCH_DRIVERS_STARTED,
    payload: { q, offset, limit },
  });
   try {
    const drivers = q ? await api.search.searchDrivers({ q, offset, limit }) : [];
    dispatch({
      type: SearchActionTypes.SEARCH_DRIVERS_SUCCESS,
      payload: drivers,
    });
   } catch(error) {
    dispatch({
      type: SearchActionTypes.SEARCH_DRIVERS_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    })
   }
};

export const searchUsers = ({ q, offset = 0, limit = 20 } = {}) => async dispatch => {
  dispatch({
    type: SearchActionTypes.SEARCH_USERS_STARTED,
    payload: { q, offset, limit },
  });
   try {
    const users = q ? await api.search.searchUsers({ q, offset, limit }) : [];
    dispatch({
      type: SearchActionTypes.SEARCH_USERS_SUCCESS,
      payload: users,
    });
   } catch(error) {
    dispatch({
      type: SearchActionTypes.SEARCH_USERS_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    })
   }
};

export const searchStations = ({ q, offset = 0, limit = 20 } = {}) => async dispatch => {
  dispatch({
    type: SearchActionTypes.SEARCH_STATIONS_STARTED,
    payload: { q, offset, limit },
  });
   try {
    const stations = q ? await api.search.searchStations({ q, offset, limit }) : [];
    dispatch({
      type: SearchActionTypes.SEARCH_STATIONS_SUCCESS,
      payload: stations,
    });
   } catch(error) {
    dispatch({
      type: SearchActionTypes.SEARCH_STATIONS_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    })
   }
};