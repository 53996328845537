import { useState } from "react";

import { useContext, createContext } from "react";
import { Toast, Typography } from "../ui/core";

const ToastContext = createContext(null);

export const ToastProvider = ({ children }) => {
  const [severity, setSeverity] = useState(null);
  const [message, setMessage] = useState(null);

  const showToast = !!severity && !!message;

  const info = (message) => {
    setSeverity('info');
    setMessage(message);
  };
  const error = (message) => {
    setSeverity('error');
    setMessage(message);
  };
  const success = (message) => {
    setSeverity('success');
    setMessage(message);
  };

  const doHideToast = () => {
    setMessage(null);
  }

  return <ToastContext.Provider value={{ info, error, success }}>
    {children}
    <Toast
      open={showToast}
      severity={severity || "info"}
      variant="filled"
      onClose={doHideToast}
    >
      <Typography>{message}</Typography>
    </Toast>
  </ToastContext.Provider>
};

const useToast = () => useContext(ToastContext);

export default useToast;
