import {
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '../../ui/core';
import { scrollY } from '../../ui/styles';
import { ExpandMoreIcon } from '../../ui/icons';

export default ({title,jsonObject}) => {
  return (<Accordion variant="outlined" defaultExpanded={false} sx={{ padding: "0 24px"}}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
        <Typography variant="subtitle1">{title}</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ padding: '0 0 24px 0' }}>
      <Stack spacing="4px" sx={{
              ...scrollY,
              maxWidth: 580
            }}>
         <pre style={{ whiteSpace:"pre-wrap" }}>
           {JSON.stringify(jsonObject, null, 2)}
         </pre>
      </Stack>
    </AccordionDetails> 
  </Accordion>)
}