import { Chip } from "../../../ui/core";
import { RideIcon, DriveIcon } from "../../../ui/icons";

export function DriverTypeIcon({ driverType, isCustomDriver, sx }) {
  if (driverType === "professional" || !!isCustomDriver) {
    return <DriveIcon sx={{ fontSize: 14, marginRight: "6px", ...sx }} />
  }
  return <RideIcon sx={{ fontSize: 14, marginRight: "6px", ...sx }} />
}

const driverTypeLabel = ({ driverType, isCustomDriver }) => {
  if (driverType === "professional" || !!isCustomDriver) {
    return "On-call driver";
  }
  return "Going anyway driver";
}

export default function DriverTypeChip({ driverType, isCustomDriver }) {
  return <Chip
    icon={<DriverTypeIcon driverType={driverType} isCustomDriver={isCustomDriver} />}
    label={driverTypeLabel({ driverType, isCustomDriver })}
  />
}