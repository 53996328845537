import development from "./development";
import staging from "./staging";
import production from "./production";

const env = process.env.REACT_APP_HITCH_ENV || "production";

const configs = {
  development,
  staging,
  production,
};

const config = configs[env];

export default config;
