import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RideIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 16 16">
    <path d="M7.99977 6.59584C9.46884 6.59584 10.6598 5.34317 10.6598 3.79792C10.6598 2.25267 9.46884 1 7.99977 1C6.53069 1 5.33977 2.25267 5.33977 3.79792C5.33977 5.34317 6.53069 6.59584 7.99977 6.59584Z" />
    <path d="M6 12.4335C6.01 12.423 6.02 12.4124 6.02 12.4019L10.72 7.71068C10.01 7.01646 9.05001 6.58521 8.00001 6.58521C5.79 6.58521 4 8.46801 4 10.7926V12.4335H6Z" />
    <path d="M12 12.4334V10.7925C12 9.87738 11.72 9.0359 11.25 8.34167L7.15002 12.4334H12Z" />
    <path d="M4 13.275V13.9481C4 14.5267 4.45 15 5 15H10.99C11.54 15 11.99 14.5267 11.99 13.9481V13.275H4Z" />
  </SvgIcon>
};

export default RideIcon;
