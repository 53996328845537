import { useState, useEffect } from 'react';
import axios from 'axios';
import { getDocumentUploadUrl } from '../redux/Drivers/actions';

const useUploadDriverFile = (driver) => {
  const [driverId, setDriverId] = useState(driver.driver_id);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setDriverId(driver.driver_id);
  }, [driver]);

  const upload = async (file) => {
    setUploading(true);
    const { data: { url, fileName, fileUrl } } = await getDocumentUploadUrl(driverId)();
    await axios.put(url, new File([file], fileName, { type: file.type }), {
      headers: { 'Content-Type': 'image/jpeg' }
    });
    setUploading(false);
    return { fileUrl };
  };

  return { upload, isUploading: uploading };
};

export default useUploadDriverFile;