import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PlusIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M19 12.998H13V18.998H11V12.998H5V10.998H11V4.99805H13V10.998H19V12.998Z" />
  </SvgIcon>
};

export default PlusIcon;
