import {
  sendDriverTypeEmail,
  sendDriverApprovalEmail,
  sendDriverRejectionEmail,
  sendDriverAwaitingDocsEmail,
  sendDriverBackgroundCheckEmail,
  sendDriverApplicationReceiptEmail,
  sendDriverWaitlistEmail,
  sendDriverWaitlistSubmittedEmail
} from "../../helpers/api/emails";
import {DriverActionsTypes} from "../Drivers/actions";

export const EmailActionsTypes = {
  EMAIL_DRIVER_APPROVAL_STARTED: 'EMAIL_DRIVER_APPROVAL_STARTED',
  EMAIL_DRIVER_APPROVAL_SUCCESS: 'EMAIL_DRIVER_APPROVAL_SUCCESS',
  EMAIL_DRIVER_APPROVAL_FAILURE: 'EMAIL_DRIVER_APPROVAL_FAILURE',
  EMAIL_DRIVER_REJECTION_STARTED: 'EMAIL_DRIVER_REJECTION_STARTED',
  EMAIL_DRIVER_REJECTION_SUCCESS: 'EMAIL_DRIVER_REJECTION_SUCCESS',
  EMAIL_DRIVER_REJECTION_FAILURE: 'EMAIL_DRIVER_REJECTION_FAILURE',
  EMAIL_DRIVER_TYPE_STARTED: 'EMAIL_DRIVER_TYPE_STARTED',
  EMAIL_DRIVER_TYPE_SUCCESS: 'EMAIL_DRIVER_TYPE_SUCCESS',
  EMAIL_DRIVER_TYPE_FAILURE: 'EMAIL_DRIVER_TYPE_FAILURE',
  EMAIL_DRIVER_AWAITING_DOCS_STARTED: 'EMAIL_DRIVER_AWAITING_DOCS_STARTED',
  EMAIL_DRIVER_AWAITING_DOCS_SUCCESS: 'EMAIL_DRIVER_AWAITING_DOCS_SUCCESS',
  EMAIL_DRIVER_AWAITING_DOCS_FAILURE: 'EMAIL_DRIVER_AWAITING_DOCS_FAILURE',
  EMAIL_DRIVER_BACKGROUND_CHECK_STARTED: 'EMAIL_DRIVER_BACKGROUND_CHECK_STARTED',
  EMAIL_DRIVER_BACKGROUND_CHECK_SUCCESS: 'EMAIL_DRIVER_BACKGROUND_CHECK_SUCCESS',
  EMAIL_DRIVER_BACKGROUND_CHECK_FAILURE: 'EMAIL_DRIVER_BACKGROUND_CHECK_FAILURE',
  EMAIL_DRIVER_APPLICATION_RECEIPT_STARTED: 'EMAIL_DRIVER_APPLICATION_RECEIPT_STARTED',
  EMAIL_DRIVER_APPLICATION_RECEIPT_SUCCESS: 'EMAIL_DRIVER_APPLICATION_RECEIPT_SUCCESS',
  EMAIL_DRIVER_APPLICATION_RECEIPT_FAILURE: 'EMAIL_DRIVER_APPLICATION_RECEIPT_FAILURE',
  EMAIL_DRIVER_WAITLIST_STARTED: 'EMAIL_DRIVER_WAITLIST_STARTED',
  EMAIL_DRIVER_WAITLIST_SUCCESS: 'EMAIL_DRIVER_WAITLIST_SUCCESS',
  EMAIL_DRIVER_WAITLIST_FAILURE: 'EMAIL_DRIVER_WAITLIST_FAILURE',
  EMAIL_DRIVER_WAITLIST_SUBMITTED_STARTED: 'EMAIL_DRIVER_WAITLIST_SUBMITTED_STARTED',
  EMAIL_DRIVER_WAITLIST_SUBMITTED_SUCCESS: 'EMAIL_DRIVER_WAITLIST_SUBMITTED_SUCCESS',
  EMAIL_DRIVER_WAITLIST_SUBMITTED_FAILURE: 'EMAIL_DRIVER_WAITLIST_SUBMITTED_FAILURE',
  RESET_STATE: 'RESET_STATE',
  RESET_ERROR: 'RESET_ERROR',
};

export const sendApprovalEmailToDriver = (email) => dispatch => {
  dispatch({
    type: EmailActionsTypes.EMAIL_DRIVER_APPROVAL_STARTED,
  });
  return sendDriverApprovalEmail(email)
    .then(response => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_APPROVAL_SUCCESS,
      payload: response,
    }))
    .catch(error => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_APPROVAL_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    }));
};

export const sendTypeEmailToDriver = (firstName, email, isCustomDriver) => dispatch => {
  dispatch({
    type: EmailActionsTypes.EMAIL_DRIVER_TYPE_STARTED,
  });
  return sendDriverTypeEmail(firstName, email, isCustomDriver)
    .then(response => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_TYPE_SUCCESS,
      payload: response,
    }))
    .catch(error => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_TYPE_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    }));
};

export const sendRejectionEmailToDriver = (firstName, email) => dispatch => {
  dispatch({
    type: EmailActionsTypes.EMAIL_DRIVER_REJECTION_STARTED,
  });
  return sendDriverRejectionEmail(firstName, email)
    .then(response => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_REJECTION_SUCCESS,
      payload: response,
    }))
    .catch(error => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_REJECTION_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    }));
};

export const sendAwaitingDocsEmailToDriver = (missingDocs, email) => dispatch => {
  dispatch({
    type: EmailActionsTypes.EMAIL_DRIVER_AWAITING_DOCS_STARTED,
  });
  return sendDriverAwaitingDocsEmail(missingDocs, email)
    .then(response => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_AWAITING_DOCS_SUCCESS,
      payload: response,
    }))
    .catch(error => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_AWAITING_DOCS_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    }));
};

export const sendBackgroundCheckEmailToDriver = (email) => dispatch => {
  dispatch({
    type: EmailActionsTypes.EMAIL_DRIVER_BACKGROUND_CHECK_STARTED,
  });
  return sendDriverBackgroundCheckEmail(email)
    .then(response => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_BACKGROUND_CHECK_SUCCESS,
      payload: response,
    }))
    .catch(error => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_BACKGROUND_CHECK_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    }));
};

export const sendApplicationReceiptEmailToDriver = (email) => dispatch => {
  dispatch({
    type: EmailActionsTypes.EMAIL_DRIVER_APPLICATION_RECEIPT_STARTED,
  });
  return sendDriverApplicationReceiptEmail(email)
    .then(response => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_APPLICATION_RECEIPT_SUCCESS,
      payload: response,
    }))
    .catch(error => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_APPLICATION_RECEIPT_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    }));
};

export const sendWaitlistEmailToDriver = (email) => dispatch => {
  dispatch({
    type: EmailActionsTypes.EMAIL_DRIVER_WAITLIST_STARTED,
  });
  return sendDriverWaitlistEmail(email)
    .then(response => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_WAITLIST_SUCCESS,
      payload: response,
    }))
    .catch(error => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_WAITLIST_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    }));
};

export const sendWaitlistToSubmittedEmailToDriver = (email) => dispatch => {
  dispatch({
    type: EmailActionsTypes.EMAIL_DRIVER_WAITLIST_SUBMITTED_STARTED,
  });
  return sendDriverWaitlistSubmittedEmail(email)
    .then(response => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_WAITLIST_SUBMITTED_SUCCESS,
      payload: response,
    }))
    .catch(error => dispatch({
      type: EmailActionsTypes.EMAIL_DRIVER_WAITLIST_SUBMITTED_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    }));
};

export const resetState = () => dispatch => {
  dispatch({
    type: DriverActionsTypes.RESET_STATE
  });
};
