import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PrivateRoute from "../../components/shared/PrivateRoute";
import DashboardLayout from "../../layouts/DashoardLayout";
import { Avatar, Typography } from "../../ui/core";

import StationsTable from "./StationsTable";
import StationsMap from "./StationsMap"; // Import the new component
import StationsSearch from "../../components/widgets/Stations/StationsSearch";
import { fetchStationsCount } from "../../redux/Stations/actions";
import { useFormatters } from "../../hooks";

const Rides = () => {
  const dispatch = useDispatch();
  const f = useFormatters();
  const count = useSelector((state) => state.stations.count);
  const navigation = [
    {
      label: "Stations",
      link: "/stations",
      sublinks: [
        {
          label: (
            <>
              <span>All</span>
              <span>{count ? f.number.decimal(count) : ""}</span>
            </>
          ),
          link: "/stations",
        },
        {
          label: "Map",
          link: "/stations/map", // Add a new sublink for the map
        },
      ],
    },
  ];

  useEffect(() => {
    fetchStationsCount()(dispatch);
  }, [dispatch]);

  return (
    <DashboardLayout
      title="Stations"
      avatar={
        <Avatar sx={{ backgroundColor: "#FCA5A5" }} variant="rounded">
          <Typography sx={{ color: "#B91C1C" }} variant="pageAvatar">
            S
          </Typography>
        </Avatar>
      }
      navigation={navigation}
      appBarContent={<StationsSearch />}
    >
      <Switch>
        <PrivateRoute exact path="/stations" component={StationsTable} />
        <PrivateRoute path="/stations/map" component={StationsMap} /> // Add a
        new route for the map
      </Switch>
    </DashboardLayout>
  );
};

export default Rides;
