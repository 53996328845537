import client from './client';
import { gql } from '@apollo/client';
import { get } from '../../helpers/api';

const getUsers = async ({ offset = 0, limit = 10 } = {}) => {
  const data = await client.query(
    gql`
      query users($offset: Int!, $limit: Int!) {
        users(offset: $offset, limit: $limit) {
          data {
            id
            firstName
            lastName
            email
            phoneNumber
            driver_id
            isCustomDriver
            driverType
          }
          count
        }
      }
    `,
    { offset, limit },
  );
  return data.users;
};

const getUser = async ({ id }) => {
  const data = await client.query(
    gql`
      query user($id: String!) {
        user(id: $id) {
          id
          firstName
          middleName
          lastName
          email
          phoneNumber
          imageUrl
          driver_id
          rider_id
          customer_id
          driverApplicationStatus
          isCustomDriver
          driverType
          creditAmount
          referralCredit
          cancellationCredit
          cancellationReferralCredit
          blacklistedDates
          blacklistedTimes
          homeCity {
            timeZone
          }
        }
        userRawUnsafe(id: $id) {
          jsonString
        }
      }
    `,
    { id },
  );
  data.user.userRawUnsafe = data.userRawUnsafe;
  return data.user;
};

const editUser = async ({ id, update }) => {
  const data = await client.query(
    gql`
      mutation updateUser($id: String!, $update: UserUpdateInput!) {
        updateUser(id: $id, input: $update) {
          id
          firstName
          middleName
          lastName
          email
          phoneNumber
          imageUrl
          driver_id
          rider_id
          customer_id
          driverApplicationStatus
          isCustomDriver
          driverType
          creditAmount
          referralCredit
          cancellationCredit
          cancellationReferralCredit
        }
      }
    `,
    { id, update },
  );
  return data.updateUser;
};

const getRiderCashFlow = async ({ id }) => {
  const data = await client.query(
    gql`
      query riderCashFlow($id: String!) {
        riderCashFlow(id: $id) {
          totalSeats
          totalRides
          totalTipped
          volume
        }
      }
    `,
    { id },
  );
  return data.riderCashFlow;
};

const getDriverCashFlow = async ({ id }) => {
  const data = await client.query(
    gql`
      query driverCashFlow($id: String!) {
        driverCashFlow(id: $id) {
          totalEarnings
          totalTrips
          totalRides
          totalTips
        }
      }
    `,
    { id },
  );
  return data.driverCashFlow;
};

const getPaymentSources = async ({ id }) => {
  const data = await client.query(
    gql`
      query paymentSources($id: String!) {
        paymentSources(id: $id) {
          id
          object
          isDefault
          brand
          last4
          exp_month
          exp_year
        }
      }
    `,
    { id },
  );
  return data.paymentSources;
};

const removePaymentSource = async ({ id, sourceId }) => {
  const data = await client.query(
    gql`
      mutation removePaymentSource($id: String!, $sourceId: String!) {
        removePaymentSource(id: $id, sourceId: $sourceId) {
          id
          object
          isDefault
          brand
          last4
          exp_month
          exp_year
        }
      }
    `,
    { id, sourceId },
  );
  return data.removePaymentSource;
};

const setDefaultPaymentSource = async ({ id, sourceId }) => {
  const data = await client.query(
    gql`
      mutation setDefaultPaymentSource($id: String!, $sourceId: String!) {
        setDefaultPaymentSource(id: $id, sourceId: $sourceId) {
          id
          object
          isDefault
          brand
          last4
          exp_month
          exp_year
        }
      }
    `,
    { id, sourceId },
  );
  return data.setDefaultPaymentSource;
};

const getSessions = async ({ id }) => {
  const data = await client.query(
    gql`
      query sessions($id: String!) {
        sessions(id: $id) {
          id
          startedAt
          lastAccessedAt
          expiresAt
          attributes {
            ipAddress
            userAgent
          }
        }
      }
    `,
    { id },
  );
  return data.sessions;
};

const revokeSession = async ({ id, sessionId }) => {
  const data = await client.query(
    gql`
      mutation revokeSession($id: String!, $sessionId: String!) {
        revokeSession(id: $id, sessionId: $sessionId) {
          id
          startedAt
          lastAccessedAt
          expiresAt
          attributes {
            ipAddress
            userAgent
          }
        }
      }
    `,
    { id, sessionId },
  );
  return data.revokeSession;
};

const getAverageDriverScores = async () => {
  const data = await client.query(
    gql`
      query averageDriverRatings {
        averageDriverRatings {
          id
          npsScore
          conversationScore
          cleanlinessScore
          drivingQualityScore
          carQualityScore
          totalScore
          percentile
        }
      }
    `
  );
  return data.averageDriverRatings;
};

const getDriverScore = async ({ id }) => {
  const data = await client.query(
    gql`
      query driverRatings($id: String!) {
        driverRatings(id: $id) {
          id
          npsScore
          conversationScore
          cleanlinessScore
          drivingQualityScore
          carQualityScore
          totalScore
          percentile
        }
      }
    `,
    { id },
  );
  return data.driverRatings;
};

const getRideHistory = async ({ id, offset = 0, limit = 25 }) => {
  const data = await client.query(
    gql`
      query rideHistory($id: String!, $offset: Int!, $limit: Int!) {
        rideHistory(id: $id, offset: $offset, limit: $limit) {
         count
         completed
         data {
          id
          status
          departureTime
          product
          didRefundCash
          fare {
            finalAmount
          }
          originStation {
            stationName
            city {
              cityName
            }
          }
          destinationStation {
            stationName
            city {
              cityName
            }
          }
          pickUp {
            name
            address
          }
          dropOff {
            name
            address
          }
         }
        }
      }
    `,
    { id, offset, limit },
  );
  return data.rideHistory;
};

const refundRide = async ({ rideId, amount }) => {
  const data = await client.query(
    gql`
      mutation refundRide($rideId: String!, $amount: Float!) {
        refundRide(rideId: $rideId, amount: $amount) {
          id
          didRefundCash
        }
      }
    `,
    { rideId, amount },
  );
  return data.refundRide;
};

const getDriveHistory = async ({ id, offset = 0, limit = 25 }) => {
  const data = await client.query(
    gql`
      query driveHistory($id: String!, $offset: Int!, $limit: Int!) {
        driveHistory(id: $id, offset: $offset, limit: $limit) {
          count
          data {
            id
            status
            departureTime
            payout
            originCity {
              cityName
            }
            destinationCity {
              cityName
            }
          }
        }
      }
    `,
    { id, offset, limit },
  );
  return data.driveHistory;
};

const getPromotions = async ({ id }) => {
  const data = await client.query(
    gql`
      query promotions($id: String!) {
        promotions(id: $id) {
          id
          code
          usesLeft
          addedOn
          description
          subtitle
          type
          kind
          beforeEventCount
          afterEventCount
          startsOn
          endsOn
          startOn
          endOn
          maxUses
          minimumPurchaseAmount
          maximumDiscountAmount
          isReferral
          referralCredit
          personType
          shouldAutoApplyToNewUsers
          isActive
          options {
            id
            type
            amount
            priceHike
            routeType
            seatDiscounts
          }
        }
      }
    `,
    { id },
  );
  return data.promotions;
};

const removePromo = async ({ id, promoId }) => {
  await client.query(
    gql`
      mutation removePromo($id: String!, $promoId: String!) {
        removePromo(id: $id, promoId: $promoId)
      }
    `,
    { id, promoId },
  );
};

const addPromos = async ({ id, codes }) => {
  await client.query(
    gql`
      mutation addPromos($id: String!, $codes: [String!]!) {
        addPromos(id: $id, codes: $codes)
      }
    `,
    { id, codes },
  );
};

const addBlacklistedDate = async ({ id, date }) => {
  await client.query(
    gql`
      mutation addBlacklistedDate($id: String!, $date: String!) {
        addBlacklistedDate(id: $id, date: $date)
      }
    `,
    { id, date },
  );
};

const removeBlacklistedDate = async ({ id, date }) => {
  await client.query(
    gql`
      mutation removeBlacklistedDate($id: String!, $date: String!) {
        removeBlacklistedDate(id: $id, date: $date)
      }
    `,
    { id, date },
  );
};

const addBlacklistedDateTime = async ({ id, dateTime }) => {
  await client.query(
    gql`
      mutation addBlacklistedDateTime($id: String!, $dateTime: String!) {
        addBlacklistedDateTime(id: $id, dateTime: $dateTime)
      }
    `,
    { id, dateTime },
  );
};

const removeBlacklistedDateTime = async ({ id, dateTime }) => {
  await client.query(
    gql`
      mutation removeBlacklistedDateTime($id: String!, $dateTime: String!) {
        removeBlacklistedDateTime(id: $id, dateTime: $dateTime)
      }
    `,
    { id, dateTime },
  );
};

const getCurrentLocation = async (userId) => {
  try {
    const response = await get(`/admin/location/current/${userId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default {
  getUsers,
  getUser,
  editUser,
  getRiderCashFlow,
  getDriverCashFlow,
  getPaymentSources,
  removePaymentSource,
  setDefaultPaymentSource,
  getSessions,
  revokeSession,
  getDriverScore,
  getAverageDriverScores,
  getRideHistory,
  refundRide,
  getDriveHistory,
  getPromotions,
  removePromo,
  addPromos,
  addBlacklistedDate,
  removeBlacklistedDate,
  addBlacklistedDateTime,
  removeBlacklistedDateTime,
  getCurrentLocation,
};