import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Stack, Paper, Typography, DataGrid, Autocomplete } from '../../ui/core';
import DriverDaysAgoChip from '../../components/widgets/DriversApproval/DriverDaysAgoChip';
import { fetchDrivers } from '../../redux/Drivers/actions';
import { getCities } from '../../redux/Cities/actions';
import { useQuery, useFormatters } from '../../hooks';


const columns = [
    { field: 'id', hide: true },
    { field: 'name', headerName: 'First name, Last name', flex: 1, minWidth: 160 },
    { field: 'email', headerName: 'Email', flex: 1, minWidth: 160 },
    { field: 'phoneNumber', headerName: 'Phone Number', minWidth: 240 },
    { field: 'type', headerName: 'Type', minWidth: 240 },
    { field: 'daysAgo', headerName: 'Days Ago', minWidth: 160, renderCell: ({ row }) => <DriverDaysAgoChip driver={row} />},
    { field: 'driver_id', headerName: 'Driver ID', minWidth: 300 },
];

const DriversTriage = () => {
    const history = useHistory();
    const { driverApplicationStatus } = useParams();
    let { homeCity, page } = useQuery();
    page = Math.max(+page || 1, 1);
    const f = useFormatters();

    const cities = useSelector((state) => state.cities.all);
    const { data, count, loading } = useSelector((state) => state.drivers.all);
    const pageSize = Math.floor((window.innerHeight - 248 - 52 - 57) / 57);
    const tableHeight = pageSize * 57 + 57 + 52 + 12;

    const rows = data.map((d) => ({
        ...d,
        id: d.id,
        name: `${d.firstName} ${d.lastName}`,
        phoneNumber: f.phone.toInternational(d.phoneNumber),
        type: d.isCustomDriver ? 'On-call driver' : 'Going anyway driver',
        daysAgo: d.driver_id,
    }));
    const dispatch = useDispatch();

    useEffect(() => {
        getCities()(dispatch);
    }, [dispatch]);

    useEffect(() => {
        const query = {
            offset: (page - 1) * pageSize,
            limit: pageSize,
            driverApplicationStatus,
            ...(homeCity ? { homeCity } : {})
        };
        fetchDrivers(query)(dispatch);
    }, [driverApplicationStatus, homeCity, page, dispatch]);

    const onRowClick = ({ row }) => {
        history.push(`/drivers-approval/funnel/${row.driver_id}`);
    };

    const onCitySelected = (e, value) => {
        const params = new URLSearchParams();
        params.append('page', 1);
        value && params.append('homeCity', value.id);
        history.push({ search: params.toString() });
    };

    const onPageChange = (p) => {
        const params = new URLSearchParams();
        params.append('page', p + 1);
        homeCity && params.append('homeCity', homeCity);
        history.push({ search: params.toString() });
    };

    return (<Box>
        <Paper sx={{ padding: '32px 32px 12px 32px' }} elevation={0}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Typography
                 sx={{ fontWeight: 700, fontSize: '32px', lineHeight: '40px', marginBottom: '32px' }}
                 >
                    Driver Triage
                </Typography>
                <Autocomplete
                    id="cityFilter"
                    label="City"
                    placeholder="Filter by city"
                    value={cities.data.find((c) => c.id === homeCity) || null}
                    sx={{ width: '300px' }}
                    disablePortal
                    getOptionLabel={(c) => `${c.cityName}, ${c.stateAbbr}`}
                    options={cities.data}
                    onChange={onCitySelected}
                />
            </Stack>
           <div style={{ width: '100%', height: tableHeight }}>
            <DataGrid
                rows={rows}
                columns={columns}
                onRowClick={onRowClick}
                pageSize={pageSize}
                loading={loading}
                paginationMode="server"
                rowCount={count}
                page={page - 1}
                onPageChange={onPageChange}
            />
           </div>
        </Paper>
    </Box>);
};

export default DriversTriage;