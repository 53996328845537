import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Menu, MenuItem, Typography, CircularProgress } from '../../../ui/core';
import PartnerTypeChip from "./PartnerTypeChip";
import { updateStation } from '../../../redux/Stations/actions';

const PartnerTypeField = ({ station }) => {
  const { partnerType } = station;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectType = async (v) => {
    handleClose();
    if (v !== partnerType) {
      setIsSubmitting(true);
      await updateStation(station.id, { partnerType: v })(dispatch);
      setIsSubmitting(false);
    }
  };

  return <Box>
    <Button
      id="station-partner-type-button"
      aria-controls={open ? 'station-partner-type-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
      disabled={isSubmitting}
     >
      <PartnerTypeChip partnerType={partnerType} />
      { isSubmitting && <CircularProgress size={16} sx={{ ml: "10px" }} /> }
    </Button>
    <Menu
        id="station-partner-type-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'station-partner-type-button',
        }}
      >
        <MenuItem disabled divider>
          <Typography>Change type ...</Typography>
        </MenuItem>
        <MenuItem
          sx={{ paddingTop: '8px', paddingBottom: '8px' }}
          onClick={() => onSelectType("general")}
        >
          <PartnerTypeChip partnerType="general" />
        </MenuItem>
        <MenuItem
          sx={{ paddingTop: '8px', paddingBottom: '8px' }}
          onClick={() => onSelectType("hub")}
        >
          <PartnerTypeChip partnerType="hub" />
        </MenuItem>
        <MenuItem
          sx={{ paddingTop: '8px', paddingBottom: '8px' }}
          onClick={() => onSelectType("partner")}
        >
          <PartnerTypeChip partnerType="partner" />
        </MenuItem>
      </Menu>
  </Box>
};

export default PartnerTypeField;
