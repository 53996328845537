import { Chip, Stack } from '../../../ui/core';

import EventIcon from '../../../ui/icons/EventIcon';
import StarIcon from '../../../ui/icons/StarIcon';

const ICONS = {
  Event: <EventIcon sx={{ fontSize: 16 }} />,
  VIP: <StarIcon sx={{ fontSize: 16 }} />
}

const TagChip = ({ tag }) => {
  return <Chip icon={ICONS[tag]} label={tag} />
};

const TripTags = ({ tags }) => {
  return (<Stack direction="row" spacing="16px">
    { tags.map((t) => <TagChip key={t} tag={t} />) }
  </Stack>)
}

export default TripTags;
