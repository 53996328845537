import moment from "moment";

const today = moment(new Date());

export const date = value => value ? moment.utc(new Date(value), 'L').isValid() ? undefined : 'Invalid date' : undefined;
export const expired = value => value ? moment.utc(new Date(value), 'L').isAfter(today) ? undefined : 'Date Expired' : undefined;
export const required = value => value ? undefined : 'Required';
export const requiredAllowZero = value => value || value === 0 ? undefined : 'Required';
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;
export const monthYearDate = value => {
  if (value && value.length <= 7) {
    return moment.utc(value, 'MM/YYYY').isValid() ? undefined : 'Invalid date';
  }
  return value ? moment.utc(value, 'YYYY-MM-DD HH:mm Z').isValid() ? undefined : 'Invalid date' : undefined;
};
export const expiredMonthYearDate = value => {
  if (value && value.length <= 7) {
    return moment.utc(value, 'MM/YYYY').isAfter(today) ? undefined : 'Date Expired';
  }
  return value ? moment.utc(value, 'YYYY-MM-DD HH:mm Z').isAfter(today) ? undefined : 'Date Expired' : undefined;
};

