import { TagsActionsTypes } from "./actions";

const initialState = {
  all: { data: [], loading: true },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TagsActionsTypes.FETCH_TAGS_STARTED:
      return {
        ...state,
        all: {
          ...state.all,
          loading: true,
        }
      };
    case TagsActionsTypes.FETCH_TAGS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          loading: false,
        },
      };
    case TagsActionsTypes.FETCH_TAGS_FAILURE:
      return {
        ...state,
        all: {
          ...state.all,
          loading: false,
        }
      };
    default:
      return {
        ...state,
      };
  }
  return state;
}