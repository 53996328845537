import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Chip, Box, CircularProgress } from "../../../ui/core";
import EventIcon from '../../../ui/icons/EventIcon';
import StarIcon from '../../../ui/icons/StarIcon';
import Input from '@mui/material/InputBase';
import { updateTripTags } from '../../../redux/Trips/actions';
import { useToast } from '../../../hooks';
import { addTag } from '../../../redux/Tags/actions';
import { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions();

const TAGS = {
  Event: 'Event',
  VIP: 'VIP',
};

const ICONS = {
  [TAGS.Event]: <EventIcon sx={{ fontSize: 16 }} />,
  [TAGS.VIP]: <StarIcon sx={{ fontSize: 16 }} />
};

const TripTagsField = ({ trip }) => {
  const dispatch = useDispatch();
  const t = useToast();
  const [value, setValue] = useState(trip.tags);
  const [inputValue, setInputValue] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const { data } = useSelector((state) => state.tags.all);

  const options = [
    ...data,
    ...value.filter((t) => !data.includes(t)),
  ];

  const maybeAddNewTag = async (value) => {
    const newTag = value.find((t) => !data.includes(t));
    if (newTag) {
      return addTag(newTag)(dispatch);
    }
    return { success: true };
  };

  const onChange = async (v) => {
    if (v.join(",") !== trip.tags.join(",")) {
      setIsSaving(true);
      const { success: canContinue } = await maybeAddNewTag(v);
      if (!canContinue) {
        t.error("Tags could not be updated");
        return;
      }
      const { success } = await updateTripTags(trip.id, v)(dispatch);
      success ? t.success("Tags updated") : t.error("Tags could not be updated");
      setIsSaving(false);
    }
  };

  useEffect(() => {
    setValue(trip.tags);
  }, [trip.tags]);

  return <Autocomplete
    value={value}
    multiple
    options={options}
    inputValue={inputValue}
    clearOnBlur
    selectOnFocus
    handleHomeEndKeys
    freeSolo
    loading={isSaving}
    filterOptions={(options, params) => {
      const filtered = filter(options, params);
      if (params.inputValue && !filtered.includes(params.inputValue)) {
        filtered.push(params.inputValue);
      }
      return filtered;
    }}
    renderInput={(params) => {
      return <Box display="flex" id={params.id} className={params.InputProps.className}>
        {params.InputProps.startAdornment}
        <Box display="flex" ml="8px" ref={params.InputProps.ref}>
          <Input
            placeholder="+ Add tag"
            sx={{ width: '268px' }}
            inputProps={params.inputProps}
            endAdornment={isSaving ? <CircularProgress size={16} /> : null}
          />
        </Box>
      </Box>;
    }}
    renderOption={(props, tag) => <Box component="li" key={tag} {...props}>
      <Chip label={tag} icon={ICONS[tag]} />
    </Box>}
    renderTags={(value, getTagProps) => value.map((tag, index) => (
      <Chip icon={ICONS[tag]} label={tag} {...getTagProps({ index })} />
    ))}
    onChange={(_, v) => onChange(v)}
    onInputChange={(_, newInputValue) => {
      setInputValue(newInputValue);
    }}
   />;
};

export default TripTagsField;
