import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CloseFullscreenIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 48 48">
   <path d="M6.1 44 4 41.9 18.9 27H9v-3h15v15h-3v-9.9ZM24 24V9h3v9.9L41.9 4 44 6.1 29.1 21H39v3Z"/>
  </SvgIcon>
};

export default CloseFullscreenIcon;
