import React from 'react';
import { Chip } from '../../../ui/core';
import { parseDateCreatedToTime, parseDateCreatedDaysAgo } from '../../../helpers/utils/parser';

const DriverDaysAgoChip = ({ driver }) => {
  const createdTime = parseDateCreatedToTime(driver.driver_id);
  const daysAgo = parseDateCreatedDaysAgo(createdTime);

  let color = "error";

  if (daysAgo < 7) {
    color = "warning";
  }
  if (daysAgo < 4) {
    color = "success";
  }
  return <Chip color={color} label={daysAgo} />;
};

export default DriverDaysAgoChip;
