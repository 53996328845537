import React, { useEffect, useState, useCallback } from "react";
import Map from "../../ui/core/Map";
import { Marker } from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import { fetchStations } from "../../redux/Stations/actions";
import {
  Box,
  Paper,
  DataGrid,
  Stack,
  Typography,
  Autocomplete,
  Avatar,
} from "../../ui/core";

const center = {
  lat: -3.745,
  lng: -38.523,
};

const StationsMap = () => {
  const { data } = useSelector((state) => state.stations.all);
  const dispatch = useDispatch();

  const [map, setMap] = useState(null);

  const onMapLoad = useCallback((map) => setMap(map), []);

  //   useEffect(() => {
  //     if (map) {
  //       const mapBounds = new window.google.maps.LatLngBounds();
  //       mapBounds.extend(
  //         new window.google.maps.LatLng(bounds[0][1], bounds[0][0])
  //       );
  //       mapBounds.extend(
  //         new window.google.maps.LatLng(bounds[1][1], bounds[1][0])
  //       );
  //       map.fitBounds(mapBounds);
  //     }
  //   }, [map]);

  useEffect(() => {
    const query = {
      offset: 0,
      limit: 100,
    };
    fetchStations(query)(dispatch);
  }, [dispatch]);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Paper elevation={0}>
          <Map
            center={center}
            zoom={10}
            onLoad={onMapLoad}
            mapContainerStyle={{ width: "100%", height: "100%" }}
          >
            {data.map((station) => (
              <Marker
                key={station.id}
                position={{
                  lat: station.coord.coordinates[1],
                  lng: station.coord.coordinates[0],
                }}
              />
            ))}
          </Map>
        </Paper>
      </Box>
    </div>
  );
};
export default StationsMap;
