import { Stack, Typography } from '../../../ui/core';

import CheckCircleIcon from '../../../ui/icons/CheckCircleIcon';

const HubIcon = () => {
  return <svg width="16" height="16" viewBox="0 0 22 22" fill="none">
    <g id="Frame 91958">
    <rect x="1.25" y="1.25" width="19.5" height="19.5" rx="9.75" fill="#F5AF01"/>
    <path id="Star 2" d="M10.6816 5.60348C10.8167 5.34747 11.1833 5.34747 11.3184 5.60348L12.7513 8.31931C12.8034 8.41804 12.8983 8.48702 13.0083 8.50605L16.034 9.02957C16.3192 9.07892 16.4325 9.42763 16.2308 9.6352L14.0907 11.8372C14.0129 11.9172 13.9766 12.0289 13.9925 12.1393L14.4296 15.1787C14.4708 15.4652 14.1742 15.6808 13.9144 15.553L11.1588 14.1981C11.0587 14.1489 10.9413 14.1489 10.8412 14.1981L8.0856 15.553C7.82584 15.6807 7.52921 15.4652 7.57041 15.1787L8.00751 12.1393C8.0234 12.0289 7.98713 11.9172 7.90933 11.8372L5.76922 9.6352C5.56747 9.42763 5.68078 9.07892 5.966 9.02957L8.99169 8.50605C9.10168 8.48702 9.19662 8.41804 9.24871 8.31931L10.6816 5.60348Z" fill="white"/>
    <rect x="1.25" y="1.25" width="19.5" height="19.5" rx="9.75" stroke="white" strokeWidth="1.5"/>
    </g>
  </svg>
}

const PartnerTypeChip = ({ partnerType }) => {
  switch (partnerType) {
    case 'general':
    case 'regular':
      return <Typography color="text.secondary">Regular</Typography>;
    case 'hub':
      return <Stack
        direction="row"
        alignItems="center"
        spacing="6px"
        padding="4px 8px"
        borderRadius="4px"
        border="1px solid #F5AF01"
        backgroundColor="#FFF8C1"
      >
        <HubIcon />
        <Typography>Hub</Typography>
      </Stack>
    case 'partner':
      return <Stack
        direction="row"
        alignItems="center"
        spacing="6px"
        padding="4px 8px"
        borderRadius="4px"
        border="1px solid #2075CE"
        backgroundColor="#DCF1FD"
      >
        <CheckCircleIcon sx={{ fontSize: 16, color: "info.main" }} />
        <Typography>Partner</Typography>
      </Stack>
    default:
      return null;
  }
};

export default PartnerTypeChip;