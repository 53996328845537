import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HitchLogoIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 46 30">
     <path d="M27.995 1.11769C29.5109 -0.372564 31.9687 -0.372564 33.4845 1.11769L33.8137 1.44149L26.1287 8.99686C26.1249 9.00061 26.1208 9.00466 26.117 9.00833C26.0142 9.10802 25.9039 9.19529 25.7878 9.27071C24.9439 9.81837 23.7974 9.72708 23.0546 8.99686C22.9485 8.89255 22.8557 8.78014 22.7761 8.66164C22.2191 7.83218 22.3118 6.70495 23.0546 5.97472L27.995 1.11769Z"/>
    <path d="M35.3511 2.95251L38.425 5.97468L30.74 13.5301C29.8912 14.3646 28.5147 14.3646 27.6659 13.5301C27.5598 13.4258 27.467 13.3133 27.3874 13.1948C26.8332 12.3695 26.9222 11.2494 27.6547 10.5189L27.6562 10.5174C27.6594 10.5142 27.6626 10.5111 27.6659 10.5079L35.3511 2.95251Z"/>
    <path d="M43.0359 10.508L39.9619 7.48581L32.2768 15.0412C31.4279 15.8758 31.4279 17.2288 32.2768 18.0634C32.3828 18.1677 32.4971 18.259 32.6178 18.3372C33.4592 18.8834 34.6018 18.7941 35.3447 18.0695C35.3467 18.0674 35.3489 18.0654 35.351 18.0634L43.0359 10.508Z"/>
    <path d="M44.5731 12.019L44.8354 12.277C46.3884 13.8036 46.3884 16.2787 44.8354 17.8053L39.962 22.5966C39.7369 22.818 39.4746 22.9806 39.1948 23.0846C39.145 23.103 39.0948 23.1197 39.0439 23.1345C38.3048 23.3494 37.4727 23.1705 36.8892 22.5978L36.8879 22.5966C36.0403 21.7632 36.0394 20.4126 36.8847 19.5777L44.5731 12.019Z"/>
    <path d="M21.5183 10.5079C20.7309 9.73383 20.3086 8.73676 20.2515 7.72353L17.9317 10.0041C16.234 11.6732 13.4816 11.6732 11.7836 10.0041L10.7591 8.99683C8.21253 6.49316 4.0837 6.49316 1.53708 8.99683L0 10.5079L1.5308 12.0129L1.53708 12.019L15.3699 25.6186C21.3121 31.4605 30.9461 31.4605 36.8882 25.6186L37.2994 25.2143C36.5857 25.0269 35.9112 24.658 35.3513 24.1076C34.4333 23.2049 34.0118 21.999 34.0866 20.8179C32.885 20.8915 31.6584 20.477 30.7403 19.5744C29.822 18.6717 29.4005 17.4658 29.4754 16.2846C28.274 16.3583 27.0474 15.9437 26.1293 15.0411C25.211 14.1385 24.7895 12.9326 24.8644 11.7514C23.663 11.825 22.4363 11.4105 21.5183 10.5079Z"/>
  </SvgIcon>
};

export default HitchLogoIcon;