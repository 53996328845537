import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CircleIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/>
  </SvgIcon>
};

export default CircleIcon;
