import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MoreVertIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 40 40">
   <path d="M20 33.333q-1 0-1.729-.729-.729-.729-.729-1.729t.729-1.708Q19 28.458 20 28.458t1.729.709q.729.708.729 1.708 0 1.042-.729 1.75T20 33.333Zm0-10.875q-1 0-1.729-.729Q17.542 21 17.542 20t.729-1.729Q19 17.542 20 17.542t1.729.729q.729.729.729 1.729t-.729 1.729q-.729.729-1.729.729Zm0-10.916q-1 0-1.729-.709-.729-.708-.729-1.708 0-1.042.729-1.75T20 6.667q1 0 1.729.729.729.729.729 1.729t-.729 1.708q-.729.709-1.729.709Z"/>
  </SvgIcon>
};

export default MoreVertIcon;
