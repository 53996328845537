import api from "../../lib/api";

export const LocationsActionsTypes = {
  FETCH_LOCATIONS_FOR_VIEWPORT_STARTED: "FETCH_LOCATIONS_FOR_VIEWPORT_STARTED",
  FETCH_LOCATIONS_FOR_VIEWPORT_SUCCESS: "FETCH_LOCATIONS_FOR_VIEWPORT_SUCCESS",
  FETCH_LOCATIONS_FOR_VIEWPORT_FAILURE: "FETCH_LOCATIONS_FOR_VIEWPORT_FAILURE",
};

export const getLocationsForViewport = (boundingBox) => async (dispatch) => {
  dispatch({
    type: LocationsActionsTypes.FETCH_LOCATIONS_FOR_VIEWPORT_STARTED,
  });

  try {
    const locations = await api.locations.getLocationsForViewport(boundingBox);
    
    dispatch({
      type: LocationsActionsTypes.FETCH_LOCATIONS_FOR_VIEWPORT_SUCCESS,
      payload: locations,
    });
  } catch (e) {
    dispatch({
      type: LocationsActionsTypes.FETCH_LOCATIONS_FOR_VIEWPORT_FAILURE,
    });
  }
};
