import client from './client';
import { gql } from '@apollo/client';

const GET_TAGS = gql`
  query availableTags {
    availableTags {
      name
    }
  }
`;

const getAll = async () => {
  const data = await client.query(GET_TAGS, {}, 'no-cache');
  return data.availableTags.map((t) => t.name);
};

const addTag = async (name) => {
  const data = await client.mutate(
    gql`
      mutation addTag($name: String!) {
        addTag(name: $name) {
          name
        }
      }
    `,
    { name },
  );
  return data.addTag.name;
}

export default {
  getAll,
  addTag,
};