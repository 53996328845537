export default {
  checkr: {
    baseUrl: ' https://dashboard.checkrhq-staging.net',
  },
  api: {
    baseUrl: 'https://api.hitch-dev.net',
  },
  pusher: {
    key: '465b70d7691cb62ad395'
  },
  googleapis: {
    apiKey: 'AIzaSyBRR79it9lPoLWhrAjMCfmTL0baZ90v_CY'
  }
}