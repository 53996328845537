import { useState, useRef, useEffect } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import MuiTextField from '@mui/material/TextField';

const TimeField = ({ label, value, onChange, format = "HH:mm", fullWidth, ...props }) => {
  const textField = useRef(null);

  const _onChange = (v) => {
    onChange(v);
  };

  return <LocalizationProvider dateAdapter={AdapterMoment}>
    <MuiTimePicker
      label={label}
      value={value}
      onChange={_onChange}
      format={format}
      renderInput={({ inputRef, inputProps, InputProps }) => {
        return <MuiTextField
          fullWidth={fullWidth}
          label={label}
          ref={textField}
          inputRef={inputRef}
          inputProps={{
            ...inputProps,
          }}
          InputProps={{
            ...InputProps,
          }}
        />;
      }}
    {...props}
    />
  </LocalizationProvider>;
};

export default TimeField;
