export const Cities = [
  {label:"Austin", value:"austin, tx"},
  {label:"Houston", value:"houston, tx"},
  {label:"Dallas", value:"dallas, tx"},
  {label:"San Antonio", value:"san antonio, tx"},
  {label:"San Marcos", value:"san marcos, tx"},
  {label:"Waco", value:"waco, tx"},
  {label:"Oklahoma City", value:"oklahoma city, ok"},
];

export const RideStatus = {
  // ride booked, added to a trip, no driver accepted the trip
  processing: "processing",
  // accepted by a driver but the details of the ride is not yet exposed
  // we only expose details of the riders 1 hour before because a single trip
  // can go through 5-10 drivers and we don’t want the rider to get like 10 notifications about their driver changing
  finalizing_details: "finalizing_details",
  // accepted by a driver
  accepted: "accepted",
  // driver picked-up rider
  en_route: "en_route",
  // ride completed
  completed: "completed",
  // ride cancelled
  cancelled: "cancelled",
};