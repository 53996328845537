import moment from 'moment';

const date = (format) => (v) => {
  const _d = moment(v, format);
  const valid = !v || _d.isValid() && _d.format(format) === v;
  return valid || 'Invalid date';
};

const future = (format) => (v) => {
  const valid = !v || moment(v, format).isAfter(moment.now());
  return valid || 'Date expired';
}

const dateValidators = {
  date,
  dateShort: date('MM/DD/YYYY'),
  monthShort: date('MM/YYYY'),
  future,
  dateShortFuture: future('MM/DD/YYYY'),
  monthShortFuture: future('MM/YYYY'),
};

const textValidators = {
  length: (length) => (v) => {
    v = v || '';
    return !v.length || v.length === length || `Should be ${length} characters`
  },
  currency: () => (v) => {
    v = v || '';
    const valid = /^\$\d+(.\d+)?$/.test(v);
    return valid || 'Invalid currency value';
  },
};

const validators = () => ({
  date: dateValidators,
  text: textValidators,
});

export default validators;