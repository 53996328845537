import api from "../../lib/api";
import * as ridesAPI from "../../helpers/api/rides";
import moment from "moment";

export const TripActionTypes = {
  FETCH_DAILY_TRIPS_SUCCESS: "FETCH_DAILY_TRIPS_SUCCESS",
  FETCH_NATIONAL_TRIPS_SUCCESS: "FETCH_NATIONAL_TRIPS_SUCCESS",
  TRIP_UPDATED: "TRIP_UPDATED",
  FETCH_TRIP_ETA_SUCCESS: "FETCH_TRIP_ETA_SUCCESS",
  TRIP_ETA_UPDATED: "TRIP_ETA_UPDATED",
  FETCH_TRIPS_ROUTES_SUCCESS: "FETCH_TRIPS_ROUTES_SUCCESS",
  FETCH_TRIP_ROUTE_SUCCESS: "FETCH_TRIP_ROUTE_SUCCESS",
  FETCH_TRIP_DRIVER_HISTORY_SUCCESS: "FETCH_TRIP_DRIVER_HISTORY_SUCCESS",
  TRIP_ROUTE_UPDATED: "TRIP_ROUTE_UPDATED",
  TRIPS_RECALCULATE_DISPLAY_STATUS: "TRIPS_RECALCULATE_DISPLAY_STATUS",
};

export const getDailyTrips = (date) => async (dispatch) => {
  const start = moment(date).startOf("day").toISOString();
  const end = moment(date).endOf("day").toISOString();
  try {
    dispatch({
      type: TripActionTypes.FETCH_DAILY_TRIPS_SUCCESS,
      payload: { date, start, end, data: [], loading: true },
    });
    let data = await api.trips.getDailyTrips({ start, end });
    dispatch({
      type: TripActionTypes.FETCH_DAILY_TRIPS_SUCCESS,
      payload: { date, start, end, data: data || [], loading: false },
    });
    return { success: true };
  } catch (e) {
    dispatch({
      type: TripActionTypes.FETCH_DAILY_TRIPS_SUCCESS,
      payload: { date, start, end, data: [], loading: false },
    });
    return { success: false };
  }
};

export const getNationalTrips = () => async (dispatch) => {
  const start = moment(new Date()).startOf("day").add(-7, "days").toISOString();
  try {
    let data = await api.trips.getNationalTrips({ start });
    dispatch({
      type: TripActionTypes.FETCH_NATIONAL_TRIPS_SUCCESS,
      payload: { data: data || [], loading: false },
    });
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};

export const getTripETA = (tripId) => async (dispatch) => {
  try {
    const tripEta = await api.trips.getTripETA(tripId);
    dispatch({
      type: TripActionTypes.FETCH_TRIP_ETA_SUCCESS,
      payload: tripEta,
    });
    return { success: true };
  } catch {
    return getTripRoute(tripId)(dispatch);
  }
};

export const getTripRoute = (tripId) => async (dispatch) => {
  try {
    const tripRoute = await api.trips.getTripRoute(tripId);
    dispatch({
      type: TripActionTypes.FETCH_TRIP_ROUTE_SUCCESS,
      payload: tripRoute,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const getTripDriverHistory = (tripId) => async (dispatch) => {
  try {
    const history = await api.trips.getTripDriverHistory(tripId);
    dispatch({
      type: TripActionTypes.FETCH_TRIP_DRIVER_HISTORY_SUCCESS,
      payload: { tripId, history },
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const cancelTripRide = (rideId) => async () => {
  try {
    await ridesAPI.cancelRide(rideId);
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};

export const reinstateTripRide = (tripId, rideId) => async () => {
  try {
    await ridesAPI.reinstateRide(rideId, { trip_id: tripId });
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};

export const pickUpRide = (tripId, rideId) => async () => {
  try {
    await ridesAPI.pickUpRide(tripId, { ride_ids: [rideId] });
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};

export const dropOffRide = (tripId, rideId) => async () => {
  try {
    await ridesAPI.dropOffRide(tripId, { ride_ids: [rideId] });
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};

export const dispatchTripDriver = (tripId, driverId) => async () => {
  await ridesAPI.dispatchDriver(tripId, {
    autoAcceptReturn: true,
    driver_id: driverId,
  });
};

export const cancelTripDriverEstimate = (tripId) => async () => {
  try {
    const estimate = await api.trips.cancelEstimate(tripId);
    return { success: true, estimate };
  } catch (e) {
    return { success: false };
  }
};

export const cancelTripDriver = (tripId, cancelEstimate) => async () => {
  try {
    const success = await api.trips.cancelDriver(tripId, cancelEstimate);
    return { success };
  } catch (e) {
    return { success: false };
  }
};

export const updateTripTags = (tripId, tags) => async () => {
  try {
    await api.trips.updateTags(tripId, tags);
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};

export const updateTripPayout = (tripId, payout) => async () => {
  try {
    await ridesAPI.updatePayout(tripId, payout.manualBonus);
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};

export const addTripOverrideCity = (tripId, data) => async () => {
  try {
    await ridesAPI.addOverrideCity(tripId, data);
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};

export const updateAllowedDrivers = (tripId, driverIds) => async () => {
  try {
    await api.trips.updateAllowedDrivers(tripId, driverIds);
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};

export const updateDeniedDrivers = (tripId, driverIds) => async () => {
  try {
    await api.trips.updateDeniedDrivers(tripId, driverIds);
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};
