import React from 'react';
import MuiAutocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import SearchIcon from '../icons/SearchIcon';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  input: {
    '.MuiInputBase-root': {
      background: 'rgba(0, 0, 0, 0.04)',
      borderRadius: '24px',
      padding: '4px 20px',
      'fieldset': {
        borderColor: 'transparent !important',
      }
    }
  },
};

const SearchField = ({ label, placeholder, loading, ...other }) => {
  return <MuiAutocomplete
    freeSolo
    filterOptions={(x) => x}
    disableClearable
    renderInput={(params) => <MuiTextField
      {...params}
      sx={style.input}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...params.InputProps,
        startAdornment: <SearchIcon color="text.secondary" sx={{ fontSize: 20 }} />,
        endAdornment: loading ? <CircularProgress size={16} color="inherit" /> : null,
      }}
      label={label}
      placeholder={placeholder}
    />}
    {...other}
  />;
};

export default SearchField;
