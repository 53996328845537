import { Divider, Box, Typography } from '../../../ui/core';
import { useStationSchedule } from "../../../hooks";
import React from 'react';
import moment from 'moment';

const DAYS = ['Su', 'Mo', "Tu", 'We', "Th", "Fr", "Sa"];

const StationInlineSchedule = ({ station }) => {
  const { today, currentSchedule, regularSchedule, formatTime } = useStationSchedule(station);

  const schedule = [0, 1, 2, 3, 4, 5, 6].map((idx) => {
    const date = today.clone().add(idx, 'd');
    let hours = currentSchedule.find((h) => h.day === date.day() && h.date === date.format("YYYY-MM-DD"));
    if (hours) {
      return { ...hours, week: date.isoWeek() };
    }
    hours = regularSchedule.find((h) => h.day === date.day());
    return hours ? { ...hours, week: date.isoWeek() } : hours;
  })
  .filter((hours) => !!hours);

  let groups = {};
  schedule.forEach((day) => {
    let key = `${day.week}-${day.opensAt}-${day.closesAt}`;
    groups[key] = groups[key] || [];
    groups[key].push(day);
  });
  groups = Object.values(groups);

  return <Box display="flex" gap="2px 16px">
    {
      groups.map((group, idx) => {
        return (<React.Fragment key={idx}>
          <Box component="span">
            <Typography component="span">
              { group.map((d) => DAYS[d.day]).join(', ') }
            </Typography>
            <Typography color="text.secondary" component="span" sx={{ margin: "0 8px"}}>•</Typography>
            <Typography component="span" variant="body2">
              { formatTime(group[0]) }
            </Typography>
          </Box>
          {
            idx < groups.length - 1 && <Divider orientation="vertical" flexItem />
          }
        </React.Fragment>)
      })
    }
  </Box>
};

export default StationInlineSchedule;