import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ChevronRightIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 48 48">
    <path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/>
  </SvgIcon>
};

export default ChevronRightIcon;
