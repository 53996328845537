import { Button, Typography } from "../../../ui/core";
import { OpenInNewIcon } from '../../../ui/icons';
import config from "../../../config";

const DriverScreeningLink = ({ driver }) => {
  const link = driver && driver.candidate_id ? `${config.checkr.baseUrl}/candidates/${driver.candidate_id}` : null;

  if (!link) {
    return null;
  }

  return <Button
    href={link}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Typography sx={{ marginRight: "8px", color: "text.secondary" }} noWrap>Open in Checkr</Typography>
    <OpenInNewIcon sx={{ fontSize: 16, color: "text.secondary" }} />
  </Button>
};

export default DriverScreeningLink;
