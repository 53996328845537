import React, { useState, useEffect } from 'react';
import { uniqueId } from 'lodash';
import { lighten } from '../../../ui/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Stack,
  Typography,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from '../../../ui/core';
import CarIcon from '../../../ui/icons/CarIcon';
import MoreVertIcon from '../../../ui/icons/MoreVertIcon';
import { useWatch } from 'react-hook-form';

const style = {
  item: {
    '.MuiListItemButton-root': {
      minHeight: '56px',
      border: '1px solid',
      borderColor: 'transparent',
    },
    '.MuiListItemButton-root.Mui-selected': {
      border: '1px solid',
      borderColor: (theme) => theme.palette.info.main,
      backgroundColor: (theme) => lighten(theme.palette.info.main, 0.96),
    }
  }
};

const VehicleItem = ({ id, vehicle, selected, onSelect, onActivate, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const closeMenu = () => setAnchorEl(null);

  return <ListItem
    sx={style.item}
    disablePadding
    secondaryAction={ !vehicle.active &&
      <>
        <IconButton
          id={`${id}-menu-button`}
          aria-controls={menuOpen ? `${id}-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          onClick={event => setAnchorEl(event.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={`${id}-menu`}
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{'aria-labelledby': `${id}-menu-button`}}
        >
          <MenuItem onClick={() => {
            onActivate(vehicle);
            closeMenu();
          }}>
            <Typography>Set as active vehicle</Typography>
          </MenuItem>
          <MenuItem onClick={() => {
            onDelete(vehicle);
            closeMenu();
          }}>
            <Typography color="error">Delete vehicle</Typography>
          </MenuItem>
        </Menu>
      </>
    }
  >
    <ListItemButton selected={selected} onClick={() => onSelect(vehicle)}>
      <ListItemIcon>
        <CarIcon
          color="primary"
          sx={{ height: '18px', width: '21px' }}
        />
      </ListItemIcon>
      <ListItemText>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
          <Typography>
            {vehicle.year} {vehicle.make} {vehicle.model} { vehicle.licensePlate }
          </Typography>
          { vehicle.active && <Chip label="Active" color="info" /> }
        </Stack>
      </ListItemText>
    </ListItemButton>
  </ListItem>;
};

const DriverVehicleList = ({ form, fields, fieldArray, selectedVehicleIdx, onSelect }) => {
  const vehicles = useWatch({ name: 'vehicles', control: form.control });

  const onAddVehicle = () => {
    const _id = uniqueId('vehicle');
    fieldArray.append({
      _id,
      licensePlate: '',
      registrationExpDate: '',
      registrationImageUrl: null,
      make: '',
      model: '',
      year: '',
      seatCount: '',
      vin: '',
      category: '',
      active: false,
      pending: true,
      deleted: false,
    });
    onSelect(_id);
  };

  const onActivateVehicle = (idx) => {
    fields.forEach((v, _idx) => {
      form.setValue(`vehicles.[${_idx}].active`, _idx === idx, { shouldDirty: true, shouldTouch: true });
    });
  };

  // start deleting vehicle by marking it to be deleted
  const onDeleteVehicle = (idx) => {
    if (idx === selectedVehicleIdx) {
      onSelect(vehicles.find(v => v.active)._id);
    }
    form.setValue(`vehicles.[${idx}].deleted`, true);
  };

  // finish deleting vehicle after selected index has been moved to another vehicle
  useEffect(() => {
    const deletedIdx = vehicles.findIndex((v) => !!v.deleted);
    if (deletedIdx !== -1 && deletedIdx !== selectedVehicleIdx) {
      fieldArray.remove(deletedIdx);
    }
  }, [vehicles, selectedVehicleIdx]);

  return <>
    <List>
      {
        fields
        .filter((v, idx) => vehicles[idx] && !vehicles[idx].deleted)
        .map((v, idx) => ({ ...v, ...vehicles[idx] }))
        .map((v, idx) => <VehicleItem
          key={v.id}
          id={v.id}
          vehicle={v}
          selected={idx === selectedVehicleIdx && vehicles.length > 0}
          onSelect={() => onSelect(v._id)}
          onActivate={() => onActivateVehicle(idx)}
          onDelete={() => onDeleteVehicle(idx)}
        />)
      }
    </List>
    <Button color="accent" onClick={onAddVehicle}>+ Add new vehicle</Button>
  </>
};

export default DriverVehicleList;
