import { combineReducers } from "redux";
import userReducer from "./Users/reducer";
import driverReducer from "./Drivers/reducer";
import authReducer from "./Auth/reducer";
import emailReducer from "./Email/reducer";
import productsReducer from "./Products/reducer";
import citiesReducer from "./Cities/reducer";
import searchReducer from "./Search/reducer";
import tripsReducer from "./Trips/reducer";
import tagsReducer from "./Tags/reducer";
import locationsReducer from "./Locations/reducer";
import stationsReducer from './Stations/reducer';

export default combineReducers({
  users: userReducer,
  drivers: driverReducer,
  auth: authReducer,
  email: emailReducer,
  products: productsReducer,
  cities: citiesReducer,
  search: searchReducer,
  trips: tripsReducer,
  tags: tagsReducer,
  stations: stationsReducer,
  locations: locationsReducer,
});
