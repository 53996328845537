import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CloseIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 32 32">
   <path d="M24 9.61143L22.3886 8L16 14.3886L9.61143 8L8 9.61143L14.3886 16L8 22.3886L9.61143 24L16 17.6114L22.3886 24L24 22.3886L17.6114 16L24 9.61143Z"/>
  </SvgIcon>
};

export default CloseIcon;
