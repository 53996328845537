import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ChevronUpIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"/>
  </SvgIcon>
};

export default ChevronUpIcon;
