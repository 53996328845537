export const StationRouteType = {
  pickUp: 'pickUp',
  dropOff: 'dropOff',
  pickUpAndDropOff: 'pickUpAndDropOff'
};

export const StationPartnerType = {
  regular: 'regular',
  partner: 'partner',
  hub: 'hub'
};

export const StationTypes = ['airport', 'establishment'];