import { useEffect } from 'react';
import {
  Stack,
  Box,
  Skeleton,
  Typography,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../../ui/core';
import { ExpandMoreIcon } from '../../../ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatters } from '../../../hooks';
import { getDriverCashFlow } from '../../../redux/Users/actions';

const DriverCashFlowCard = ({ user }) => {
  const dispatch = useDispatch();
  const f = useFormatters();
  const data = useSelector((state) => state.users.selection.driverCashFlow);

  useEffect(() => {
    getDriverCashFlow(user.id)(dispatch);
  }, []);

  return (<Paper variant="outlined" sx={{ padding: "0 24px"}}>
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
        <Typography variant="subtitle1">Driver Cash Flow</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0 0 24px 0' }}>
        <Box display="flex" gap="16px" flexWrap="wrap">
          <Stack spacing="8px">
            <Typography color="text.secondary">Total Earnings</Typography>
            {
              data ?
                <Typography variant="subtitle1">{f.number.currency(data.totalEarnings)}</Typography> :
                <Skeleton variant="rectangular" height={32} />
            }
          </Stack>
          <Stack spacing="8px">
            <Typography color="text.secondary">Total Trips</Typography>
            {
              data ?
                <Typography variant="subtitle1">{f.number.decimal(data.totalTrips)}</Typography> :
                <Skeleton variant="rectangular" height={32} />
            }
          </Stack>
          <Stack spacing="8px">
            <Typography color="text.secondary">Total Tips</Typography>
            {
              data ?
                <Typography variant="subtitle1">{f.number.currency(data.totalTips)}</Typography> :
                <Skeleton variant="rectangular" height={32} />
            }
          </Stack>
          <Stack spacing="8px">
            <Typography color="text.secondary">Total Rides</Typography>
            {
              data ?
                <Typography variant="subtitle1">{f.number.decimal(data.totalRides)}</Typography> :
                <Skeleton variant="rectangular" height={32} />
            }
          </Stack>
        </Box>
    </AccordionDetails>
    </Accordion>
  </Paper>)
};

export default DriverCashFlowCard;