import client from './client';
import { gql } from '@apollo/client';

const GET_CITIES = gql`
  query getCities {
    cities {
      data {
        id
        cityName
        stateName
        stateAbbr
        timeZone
      }
      count
    }
  }
`;

const getCities = async () => {
  const data = await client.query(GET_CITIES, {}, 'cache-first');
  return data;
};

export default {
  getCities,
};