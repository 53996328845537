import React, { useState } from 'react';
import { FormControlLabel, Switch } from '../../../ui/core';
import { useController } from 'react-hook-form';

const ValidDocSwitch = ({ name, control, onFocus }) => {
  const { field } = useController({ name, control });
  const [defaultValue] = useState(field.value);

  return <FormControlLabel
    control={<Switch
      name={name}
      color="success"
      defaultChecked={!defaultValue}
      onChange={e => field.onChange({ target: { value: !e.target.checked }})}
      onFocus={onFocus}
    />}
    label="Valid"
  />
}

export default ValidDocSwitch;