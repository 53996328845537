import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Stack, Tabs, Tab, Button, ImagePreview } from '../../../ui/core';
import { useUploadDriverFile } from '../../../hooks';
import { editDriver } from '../../../redux/Drivers/actions';

const TABS = {
    INSURANCE: 'insurance',
    LICENSE: 'license',
    REGISTRATION: 'registration',
};

const style = {
    root: {

    },
    heading: {
        marginBottom: '24px',
    },
};

const TabPanel = (props) => {
    const { children, value, id } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== id}
        id={id}
        aria-labelledby={`driver-document-${id}`}
      >
        {value === id && (
          <Box>
            { children }
          </Box>
        )}
      </div>
    );
  }

const DriverDocumentsForm = ({ driver, uploads, setUploads, section }) => {
    const [tab, setTab] = useState(section || TABS.LICENSE);
    const fileRef = useRef();
    const { upload, isUploading } = useUploadDriverFile(driver);
    const dispatch = useDispatch();
    const documents = {
        license: driver.licenseImageUrl,
        insurance: driver.insuranceImageUrl,
        registration: uploads.files[uploads.uploadId],
    };

    const openFileUpload = () => {
        fileRef.current.click();
    };

    const uploadFile = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        const { fileUrl } = await upload(file);
        if (!fileUrl) {
            return;
        }
        switch (tab) {
            case TABS.LICENSE:
                editDriver(driver, { licenseImageUrl: fileUrl })(dispatch);
                return;
            case TABS.INSURANCE:
                editDriver(driver, { insuranceImageUrl: fileUrl })(dispatch);
                return;
            case TABS.REGISTRATION:
                if (uploads.canSaveUpload) {
                    await editDriver(
                        driver,
                        { vehicles: driver.vehicles.map((v, idx) => ({ ...v, registrationImageUrl: idx === uploads.vehicleIdx ? fileUrl: v.registrationImageUrl })) }
                    )(dispatch);
                }
                setUploads({ ...uploads, files: { ...uploads.files, [uploads.uploadId]: fileUrl } });
                return;
            default:
                return;
        }
    };

    useEffect(() => {
        setTab(section || TABS.INSURANCE);
    }, [section]);

    return <Box sx={style.root}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={4} sx={style.heading}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                value={tab} onChange={(_, v) => setTab(v)}
                aria-label="driver documents tabs"
                >
                    <Tab
                        id={TABS.INSURANCE}
                        value={TABS.INSURANCE}
                        label="Insurance"
                        aria-controls="driver-document-insurance"
                    />
                    <Tab
                        id={TABS.LICENSE}
                        value={TABS.LICENSE}
                        label="License"
                        aria-controls="driver-document-license"
                    />
                    <Tab
                        id={TABS.REGISTRATION}
                        value={TABS.REGISTRATION}
                        label="Registration"
                        aria-controls="driver-document-registration"
                    />

                </Tabs>
            </Box>
            <Button color="accent" onClick={openFileUpload} loading={isUploading}>+ Upload document</Button>
        </Stack>
        <TabPanel id={TABS.LICENSE} value={tab}>
            <ImagePreview id="license-document" src={documents.license} />
            <input ref={fileRef} hidden type="file" accept=".png, .jpg, .jpeg, .svg" onChange={uploadFile}/>
        </TabPanel>
        <TabPanel id={TABS.REGISTRATION} value={tab}>
            <ImagePreview key={documents.vehicleIdx} id="registration-document" src={documents.registration} />
            <input ref={fileRef} hidden type="file" accept=".png, .jpg, .jpeg, .svg" onChange={uploadFile}/>
        </TabPanel>
        <TabPanel id={TABS.INSURANCE} value={tab}>
            <ImagePreview id="insurance-document" src={documents.insurance} />
            <input ref={fileRef} hidden type="file" accept=".png, .jpg, .jpeg, .svg" onChange={uploadFile}/>
        </TabPanel>
    </Box>;
};

export default DriverDocumentsForm;