import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SearchField, Typography, Box, Backdrop, Stack } from '../../../ui/core';
import { StationIcon } from '../../../ui/icons';

import { searchStations } from '../../../redux/Search/action';
import { fetchSelectedStation } from '../../../redux/Stations/actions';

const style = {
  item: {
    padding: '12px 18px',
  },
  backdrop: {
    top: '84px',
    marginLeft: 0,
  },
};

const StationsSearch = () => {
  const [inputValue, setInputValue] = useState('');
  const [showBackdrop, setShowBackdrop] = useState(false);
  const { data: stations, loading } = useSelector((state) => state.search.stations);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      searchStations({ q: inputValue })(dispatch);
    }, 500);
    return () => clearTimeout(timer);
  }, [inputValue, dispatch]);

  const selectStation = async (_, value) => {
    setInputValue(inputValue);
    fetchSelectedStation(value.id)(dispatch);
  };

  return <div>
    <SearchField
      id="stations_search"
      sx={{ width: 500 }}
      placeholder="Search"
      value={null}
      inputValue={inputValue}
      options={stations}
      getOptionLabel={(station) => station.id}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={selectStation}
      renderOption={(props, station) => (<Box component="li" key={station.id} {...props} sx={style.item}>
        <StationIcon sx={{ fontSize: 14 }} />
        <Stack spacing="8px" marginLeft="10px">
          <Typography component="span">{station.stationName}</Typography>
          <Typography component="span" color="text.secondary" variant="small">
            {station.stationAddress}
          </Typography>
        </Stack>
      </Box>)}
      blurOnSelect
      loading={loading}
      onOpen={() => setShowBackdrop(true)}
      onClose={() => setShowBackdrop(false)}
    />
    <Backdrop open={showBackdrop} sx={style.backdrop} />
  </div>
};

export default StationsSearch;