import { Typography, Stack, Box, Avatar, LinearProgress } from '../../../ui/core';
import { useFormatters } from '../../../hooks';
import { useSelector } from 'react-redux';
import { TripStatus, getTripWaypointETA } from '../../../models/Trips';
import moment from 'moment';

const WaypointVisual = ({ trip, idx }) => {
  const currentWaypoint = trip.currentWaypoint;
  const isActive = idx === currentWaypoint;
  const isCompleted = idx < currentWaypoint;
  const isLast = idx === trip.waypoints.length - 1;

  if (trip.status === TripStatus.completed || isCompleted) {
    return <Box position="relative" width="24px">
      {
        !isLast && <Box
          position="absolute"
          left="11px"
          top="0"
          bottom="0"
          width="2px"
          backgroundColor="primary.main"
          zIndex="1"
        />
      }
      <Box position="relative" zIndex="2">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect width="24" height="24" rx="12" fill="#252525"/>
          <path d="M18 8.81549L10.1143 16.7012L6.5 13.0869L7.42657 12.1603L10.1143 14.8415L17.0734 7.88892L18 8.81549Z" fill="white"/>
        </svg>
      </Box>
    </Box>;
  }

  if (isActive && trip.status === TripStatus.in_progress) {
    return <Box position="relative" width="24px">
      {
        !isLast && <Box
          position="absolute"
          left="11px"
          top="0"
          bottom="0"
          zIndex="1"
        >
          <svg width="4" height="100%" fill="none">
            <line x1="0" y1="0" x2="0" y2="100%" stroke="#ACACAC" strokeWidth="4" strokeDasharray="5 5" />
          </svg>
        </Box>
      }
      <Box
        position="relative"
        zIndex="2"
        height="24px"
        width="24px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="primary.main"
        borderRadius="90px"
      >
        <Typography color="white.main" variant="body2">{ idx + 1}</Typography>
      </Box>
    </Box>;
  }


  return <Box position="relative" width="24px">
   {
    !isLast && <Box
      position="absolute"
      left="11px"
      top="0"
      bottom="0"
      zIndex="1"
    >
      <svg width="4" height="100%" fill="none">
        <line x1="0" y1="0" x2="0" y2="100%" stroke="#ACACAC" strokeWidth="4" strokeDasharray="5 5" />
      </svg>
    </Box>
   }
    <Box
      position="relative"
      zIndex="2"
      height="24px"
      width="24px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="white.main"
      borderRadius="90px"
    >
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="4" r="4" fill="#686868"/>
      </svg>
    </Box>
  </Box>;
};

const TripItineraryPoint = ({ trip, tripEta, idx }) => {
  const f = useFormatters();
  const currentWaypoint = trip.currentWaypoint;
  const isCurrent = idx === currentWaypoint;

  const waypoint = trip.waypoints[idx];
  const rides = waypoint.map((w) => trip.rides.find((r) => r.id === w.ride_id));
  let location = null;
  if (rides[0]) {
   location = waypoint[0].type === 'pick-up' ? rides[0].pickUp : rides[0].dropOff;
  }
  const eta = getTripWaypointETA(waypoint, tripEta);

  return <Box display="flex">
    <WaypointVisual trip={trip} idx={idx} />
    <Stack spacing="4px" flex={1} ml="16px" mb="16px">
      <Box display="flex" justifyContent="space-between">
        <Typography color={ isCurrent ? "primary" : "text.secondary" }  variant="body2" fontWeight={700}>
          { location ? location.name || location.address : '' } <Box component="span" whiteSpace="nowrap">({waypoint[0].type})</Box>
        </Typography>
        <Typography
          ml="4px"
          color={ isCurrent ? "primary" : "text.secondary" }
          variant="body2"
          whiteSpace="nowrap"
          fontWeight={ isCurrent ? 700 : 500 }
        >
          { eta && f.date.toTimezoneLocaleString(eta, trip.originCity.timeZone, f.date.DateFormats.TIME_SIMPLE_WITH_TIMEZONE) }
        </Typography>
      </Box>
      <Typography color="text.secondary" variant="body2">
      { location ? location.address : '' }
      </Typography>
      <Stack direction="row">
      {
        rides.map((r) => <Avatar key={r.id} sx={{ width: 32, height: 32 }} src={r.rider.imageUrl} />)
      }
      </Stack>
      <Typography color="text.secondary" variant="body2">
        { rides.map((r) => r.rider.firstName).join(' & ') }
      </Typography>
    </Stack>
  </Box>
};

const TripItineraryHeading = ({ trip, tripEta }) => {
  const f = useFormatters();
  const currentWaypoint = trip.currentWaypoint;
  const percentage = (currentWaypoint / trip.waypoints.length) * 100;
  const waypoint = trip.waypoints[currentWaypoint] || [];
  const eta = getTripWaypointETA(waypoint, tripEta);

  if (!eta) {
    return (<Box mb="24px">
    <Typography mb="8px" fontWeight="700" variant="h2">No estimate available</Typography>
    <LinearProgress variant="determinate" value={percentage} />
  </Box>);
  }

  const pickUp = waypoint.find((w) => w.type === 'pick-up');

  if (!pickUp) {
    return (<Box mb="24px">
    <Typography mb="8px" fontWeight="700" variant="h2" color="text.secondary">
      Arriving to drop-off at <Box component="span" color="primary.main">
        { eta && f.date.toTimezoneLocaleString(eta, trip.originCity.timeZone, f.date.DateFormats.TIME_SIMPLE) }</Box>
    </Typography>
    <LinearProgress variant="determinate" value={percentage} />
  </Box>);
  }

  const ride = trip.rides.find((r) => r.id === pickUp.ride_id);
  const departureTime = ride.departureTime;
  const departureDiff = moment(departureTime).diff(moment(eta), 'minutes');
  const nowDiff = moment(eta).diff(moment(), 'minutes');

  if (nowDiff < 0) {
    return null;
  }

  let delayed = null;
  if (departureDiff < 0) {
    delayed = (<Typography mb="8px" fontWeight="700" variant="h2" color="error.main">
      {Math.abs(departureDiff)} minutes delayed
    </Typography>)
  }

  return (<Box mb="24px" display="flex">
    <Typography
      mb="8px"
      fontWeight="700"
      variant="h2"
      sx={{marginRight:"20px"}}
    >ETA: {nowDiff} mins</Typography>
    {delayed}
  </Box>);
};

const TripItinerary = ({ trip }) => {
  const tripsEta = useSelector((state) => state.trips.tripsETA);
  const tripEta = tripsEta[trip.id];

  return (<Box width="384px">
    {
      trip.status === TripStatus.in_progress && tripEta && <TripItineraryHeading trip={trip} tripEta={tripEta} />
    }
    <Typography fontSize="20px" mb="24px">Itinerary</Typography>
    {
      trip.waypoints
        .filter((w) => w && w.length > 0)
        .map((w, idx) => <TripItineraryPoint key={idx} trip={trip} tripEta={tripEta} idx={idx} />)
    }
  </Box>)
};

export default TripItinerary;
