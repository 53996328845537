import React from 'react';
import MuiAutocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import MUIInputBase from '@mui/material/InputBase';
import MUIBox from '@mui/material/Box';
import MUIDivider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  width: '100%',
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: '#fff',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const AutocompleteInline = ({ label, placeholder, ...other}) => {
  return (<div>
    <MuiAutocomplete
      open
      PopperComponent={PopperComponent}
      disablePortal
      renderInput={(params) => (
        <MUIBox>
          <MUIInputBase
            sx={{ padding: "10px 16px" }}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus
            placeholder={placeholder}
          />
          <MUIDivider />
        </MUIBox>
      )}
      {...other}
     />
  </div>);
};

export default AutocompleteInline;