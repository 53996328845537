import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SearchCircleIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
    />
    <path
      d="M15.3778 16L12.5778 13.2C12.3556 13.3778 12.1 13.5185 11.8111 13.6222C11.5222 13.7259 11.2148 13.7778 10.8889 13.7778C10.0815 13.7778 9.39822 13.4982 8.83911 12.9391C8.2797 12.3797 8 11.6963 8 10.8889C8 10.0815 8.2797 9.39807 8.83911 8.83867C9.39822 8.27956 10.0815 8 10.8889 8C11.6963 8 12.3797 8.27956 12.9391 8.83867C13.4982 9.39807 13.7778 10.0815 13.7778 10.8889C13.7778 11.2148 13.7259 11.5222 13.6222 11.8111C13.5185 12.1 13.3778 12.3556 13.2 12.5778L16 15.3778L15.3778 16ZM10.8889 12.8889C11.4444 12.8889 11.9167 12.6945 12.3058 12.3058C12.6945 11.9167 12.8889 11.4444 12.8889 10.8889C12.8889 10.3333 12.6945 9.86104 12.3058 9.472C11.9167 9.08326 11.4444 8.88889 10.8889 8.88889C10.3333 8.88889 9.86104 9.08326 9.472 9.472C9.08326 9.86104 8.88889 10.3333 8.88889 10.8889C8.88889 11.4444 9.08326 11.9167 9.472 12.3058C9.86104 12.6945 10.3333 12.8889 10.8889 12.8889Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
  </SvgIcon>
};

export default SearchCircleIcon;
