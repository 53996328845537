import { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  Pagination,
} from '../../../ui/core';
import { ExpandMoreIcon } from '../../../ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatters } from '../../../hooks';
import { getDriveHistory } from '../../../redux/Users/actions';

import { DriverTypeIcon } from '../DriversApproval/DriverTypeChip';
import TripStatusChip from '../Trips/TripStatusChip';

const DriveItem = ({ user, trip }) => {
  const f = useFormatters();

  let route = '';
  if (trip.originCity && trip.destinationCity) {
    route = `${trip.originCity.cityName} to ${trip.destinationCity.cityName}`;
  }

  return ( <ListItem>
    <ListItemIcon>
      <Box padding="8px" display="flex" justifyContent="center" alignItems="center" borderRadius="25px" backgroundColor="#EEE">
        <DriverTypeIcon
          sx={{ fontSize: 24, color: "primary.main", marginRight: 0 }}
          driverType={user.driverType}
          isCustomDriver={user.isCustomDriver}
        />
      </Box>
    </ListItemIcon>
    <Box display="flex" flex="1 0 0">
      <Stack spacing="4px" flex="1" marginRight="16px">
      <Box display="flex" position="relative">
          <Typography fontWeight={600}>
            {f.date.toLocaleString(trip.departureTime, f.date.DateFormats.DATETIME_MED) || '-'}
          </Typography>
          <Box position="absolute" left="200px" top="-6px">
            <TripStatusChip status={trip.status} />
          </Box>
      </Box>
        <Typography variant="body2" color="text.secondary">
          { route }
        </Typography>
      </Stack>
      <Stack spacing="4px" alignItems="flex-end">
        <Box display="flex" position="relative" paddingLeft="42px">
          {
            trip.status === 'completed' &&  <Typography fontWeight={600} color="success.main">
              +{ f.number.currency(trip.payout) }
            </Typography>
          }
          {
            trip.status !== 'completed' &&  <Typography fontWeight={600} color="text.secondary">
              { f.number.currency(trip.payout) }
            </Typography>
          }
        </Box>
      </Stack>
    </Box>
  </ListItem>);
};

const DriveHistoryCard = ({ user }) => {
  const dispatch = useDispatch();
  const history = useSelector((state) => state.users.selection.driveHistory);
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const pages = history ? Math.ceil(history.count / pageSize) : 0;

  const onPageChange = (_, value) => {
    setPage(value);
  };

  useEffect(() => {
    getDriveHistory(user.id, (page - 1) * 5, 5)(dispatch);
  }, [page]);

  return (<Accordion variant="outlined" sx={{ padding: "0 24px"}}>
   <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
      <Typography variant="subtitle1">Drive History</Typography>
   </AccordionSummary>
   <AccordionDetails sx={{ padding: '0 0 24px 0' }}>
    <Stack spacing="4px">
      {
        !history && <>
          <Skeleton variant="rectangular" height={48} />
          <Skeleton variant="rectangular" height={48} />
        </>
      }
      {
        history && <Stack>
          <List>
            {
              history.data
                .map((trip, idx) => (<DriveItem
                  key={`${trip.id}-${idx}`}
                  user={user}
                  trip={trip}
                />))
            }
          </List>
         { pages > 1 &&  <Pagination count={pages} size="small" onChange={onPageChange} /> }
        </Stack>
      }
    </Stack>
   </AccordionDetails>
  </Accordion>)
};

export default DriveHistoryCard;