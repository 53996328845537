import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactMapGL, { Marker, Popup } from "react-map-gl";

import { getLocationsForViewport } from "../../redux/Locations/actions";
import useSupercluster from "use-supercluster";
import { Typography } from "../../ui/core";

import "mapbox-gl/dist/mapbox-gl.css";

const TripsSatellite = () => {
  const [viewport, setViewport] = useState({
    width: "100wh",
    height: "100vh",
    latitude: 30.2671,
    longitude: -97.74308,
    zoom: 13,
  });
  const [mapInstance, setMapInstance] = useState(null);
  const [showSearchButton, setShowSearchButton] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [bounds, setBounds] = useState([]);
  const [hexagons, setHexagons] = useState([]);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [clickedMarker, setClickedMarker] = useState(null);

  const { clusters, _ } = useSupercluster({
    points: markers,
    bounds,
    zoom: viewport.zoom,
    options: { radius: 75, maxZoom: 20 },
  });
  // console.log(clusters.length, markers.length);

  const locations = useSelector((state) => state.locations.locationsForBounds);

  const dispatch = useDispatch();

  useEffect(() => {
    if (mapInstance) {
      const boundingBox = getBounds();
      setBounds(mapInstance.getBounds().toArray().flat());
      getLocationsForViewport(boundingBox)(dispatch);
    }
  }, [mapInstance]);

  useEffect(() => {
    // Update the markers state
    const newMarkers = locations.map((location, index) => ({
      type: "Feature",
      properties: { cluster: false, id: `marker-${index}`, ...location },
      geometry: {
        type: "Point",
        coordinates: [location.location.lon, location.location.lat],
      },
    }));
    setMarkers(newMarkers);
  }, [locations]);

  useEffect(() => {
    onViewportChange();
  }, [viewport]);

  const onLoad = (event) => {
    setMapInstance(event.target);
    setShowSearchButton(false);
  };

  const onViewportChange = () => {
    setShowSearchButton(true);
  };

  const handleSearchClick = () => {
    const boundingBox = getBounds();
    // const h3Indexes = polygonToCells(boundingBox, 8);
    // const newHexagons = h3Indexes.map((index) => {
    //   const geoBoundary = cellToBoundary(index).map((coord) => coord.reverse());
    //   return {
    //     type: "Feature",
    //     properties: {},
    //     geometry: {
    //       type: "Polygon",
    //       coordinates: [geoBoundary],
    //     },
    //   };
    // });
    // setHexagons(newHexagons);

    setShowSearchButton(false);
    setMarkers([]);
    getLocationsForViewport(boundingBox)(dispatch);
  };

  const getBounds = () => {
    if (mapInstance) {
      const bounds = mapInstance.getBounds();
      bounds._nw = { lat: bounds._ne.lat, lng: bounds._sw.lng };
      bounds._se = { lat: bounds._sw.lat, lng: bounds._ne.lng };

      const fuzzLng = (bounds._se.lng - bounds._sw.lng) * 0.1;
      const fuzzLat = (bounds._se.lat - bounds._ne.lat) * 0.1;

      const boundingBox = [
        [bounds._sw.lng - fuzzLng, bounds._sw.lat + fuzzLat].reverse(),
        [bounds._nw.lng - fuzzLng, bounds._nw.lat - fuzzLat].reverse(),
        [bounds._ne.lng + fuzzLng, bounds._ne.lat - fuzzLat].reverse(),
        [bounds._se.lng + fuzzLng, bounds._se.lat + fuzzLat].reverse(),
        [bounds._sw.lng - fuzzLng, bounds._sw.lat + fuzzLat].reverse(),
      ];
      return boundingBox;
    }
  };

  const style = {
    color: "#fff",
    background: "#1978c8",
    borderRadius: "50%",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  //hello

  return (
    <div
      style={{
        height: "calc(100vh - 178px)",
        width: "calc(100vw - 314px)",
        position: "relative",
      }}
    >
      {showSearchButton && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
          }}
        >
          <button
            style={{
              borderRadius: "20px",
              padding: "10px 20px",
              background: "white",
            }}
            onClick={handleSearchClick}
          >
            Search this area
          </button>
        </div>
      )}

      <ReactMapGL
        mapboxAccessToken={
          "pk.eyJ1IjoidGFudWpoaXRjaCIsImEiOiJjam51bnE1bDMwNDJjM3FvY3R6eGZzbWY2In0.IrzzBXNrINsnUrboExej4A"
        }
        {...viewport}
        maxZoom={20}
        onMove={(evt) => setViewport(evt.viewState)}
        onLoad={onLoad}
        mapStyle="mapbox://styles/tanujhitch/cljqjqmrw00zy01ns4o3550qb"
      >
        {/* {hexagons.map((hexagon, index) => {
          const id = `hex-${index}`;
          return (
            <Source id={id} type="geojson" data={hexagon} key={index}>
              <Layer
                id={id}
                type="fill"
                source="hexSource"
                paint={{
                  "fill-color": "#888888",
                  "fill-opacity": 0.4,
                }}
              />
            </Source>
          );
        })} */}
        {/* Below is causing rendering issues */}
        {/* {clusters.map((cluster) => {
          // every cluster point has coordinates...
          const [longitude, latitude] = cluster.geometry.coordinates;
          // the point may be either a cluster or a crime point
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            console.log("Returning cluster", cluster.id, latitude, longitude);
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                latitude={latitude}
                longitude={longitude}
              >
                <div
                  style={{
                    ...style,
                    width: `${10 + (pointCount / markers.length) * 20}px`,
                    height: `${10 + (pointCount / markers.length) * 20}px`,
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }
          console.log(
            "Returning regular",
            cluster.properties.id,
            latitude,
            longitude
          );
          return (
            <Marker
              key={`${cluster.properties.id}`}
              latitude={latitude}
              longitude={longitude}
            />
          );
        })} */}

        {markers.map((marker, index) => {
          return (
            <div
              key={index}
              onMouseEnter={() => {
                setHoveredMarker(marker);
              }}
              onMouseLeave={() => {
                setHoveredMarker(null);
              }}
              onClick={() => {
                setClickedMarker(marker);
              }}
              style={{
                height: "20px",
                width: "20px",
                position: "relative",
                top: "-10px",
                left: "-10px",
              }}
            >
              <Marker
                key={index}
                latitude={marker.geometry.coordinates[1]}
                longitude={marker.geometry.coordinates[0]}
              >
                <div
                  style={{
                    height: "10px",
                    width: "10px",
                    backgroundColor: "black",
                    borderRadius: "50%",
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                ></div>
              </Marker>

              {(hoveredMarker === marker || clickedMarker === marker) && (
                <Popup
                  latitude={marker.geometry.coordinates[1]}
                  longitude={marker.geometry.coordinates[0]}
                  closeOnClick={false}
                  onClose={() => setClickedMarker(null)}
                >
                  <div>
                    <Typography variant="h6" component="div">
                      {marker.properties.user
                        ? marker.properties.user.firstName
                        : "?"}{" "}
                      {marker.properties.user
                        ? marker.properties.user.lastName
                        : "?"}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      {marker.properties.user
                        ? marker.properties.user.homeCity
                        : "?"}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {new Intl.DateTimeFormat("en-US", {
                        dateStyle: "full",
                        timeStyle: "long",
                      }).format(new Date(marker.properties.timestamp))}
                    </Typography>
                  </div>
                </Popup>
              )}
            </div>
          );
        })}
      </ReactMapGL>
    </div>
  );
};
//
export default TripsSatellite;
