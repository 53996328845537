import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Paper, DataGrid } from '../../ui/core';
import { fetchUsers } from '../../redux/Users/actions';
import { useQuery, useFormatters } from '../../hooks';
import { parseDateCreated } from '../../helpers/utils/parser';

const columns = [
  { field: 'id', hide: true },
  { field: 'createdAt', headerName: 'Created Date', flex: 1, minWidth: 160 },
  { field: 'name', headerName: 'Name', flex: 1, minWidth: 160 },
  { field: 'email', headerName: 'Email', flex: 1, minWidth: 160 },
  { field: 'phoneNumber', headerName: 'Phone Number', minWidth: 240 },
  { field: 'type', headerName: 'Type', minWidth: 240 },
  { field: 'driver_id', headerName: 'Driver ID', minWidth: 300 },
];

const UsersTable = () => {
  const history = useHistory();
  let { page } = useQuery();
  page = Math.max(+page || 1, 1);
  const f = useFormatters();

  const { data, count, loading } = useSelector((state) => state.users.all);
  const pageSize = Math.floor((window.innerHeight - 132 - 52 - 57) / 57);
  const height = pageSize * 57 + 57 + 52 + 12;

  const rows = data.map((d) => ({
      ...d,
      id: d.id,
      createdAt: parseDateCreated(d.id),
      name: `${d.firstName} ${d.lastName}`,
      phoneNumber: f.phone.toInternational(d.phoneNumber),
      type: d.driver_id ? d.isCustomDriver ? 'On-call driver' : 'Going anyway driver' : '',
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    const query = {
        offset: (page - 1) * pageSize,
        limit: pageSize,
    };
    fetchUsers(query)(dispatch);
  }, [page, dispatch]);

  const onRowClick = ({ row }) => {
    history.push(`/users/${row.id}`);
  };

  const onPageChange = (p) => {
    const params = new URLSearchParams();
    params.append('page', p + 1);
    history.push({ search: params.toString() });
  };

  return (<Box>
    <Paper elevation={0}>
      <div style={{ width: '100%', height: height }}>
        <DataGrid
          rows={rows}
          columns={columns}
          onRowClick={onRowClick}
          pageSize={pageSize}
          loading={loading}
          paginationMode="server"
          rowCount={count}
          page={page - 1}
          onPageChange={onPageChange}
        />
      </div>
    </Paper>
  </Box>);
};

export default UsersTable;