import React from 'react';
import {
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '../../../ui/core';
import { scrollY } from '../../../ui/styles';
import { ExpandMoreIcon } from '../../../ui/icons';

const ExperimentItem = ({ name, data }) => {
  let rowData = [
    ['Type', data.experiment ? 'Experiment' : 'Feature flag'],
    ['Status', data.on ? 'On' : 'Off']
  ];

  if (data.experiment) {
    if (data.experimentResult) {
      if (data.experimentResult.inExperiment) {
        rowData.push(['Group', data.experimentResult.name]);
        rowData.push(['Value', JSON.stringify(data.experimentResult.value)])
      } else {
        rowData.push(['Group', 'Not included'])
      }
    }
  } else {
    rowData.push(['Value', JSON.stringify(data.value)])
  }

  let rows = []
  for (const row of rowData) {
    rows.push((
      <Typography key={row[0]}>
          <Typography variant="small" component="span" color="text.secondary">{row[0]}:</Typography>
          <Typography variant="small" component="span"> {row[1]}</Typography>
      </Typography>
    ));
  }

  return (
    <ListItem key={name} sx={{ display: "flex", alignItems: "flex-start", gap: "16px", justifyContent: "space-between" }}>
      <Stack spacing="2px">
        <Typography>{name}</Typography>
        {rows}
      </Stack>
    </ListItem>
  )
};

export default class ExperimentsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { features: null }
  }

  componentDidMount() {
    const growthbook = this.props.growthbook;

    growthbook.loadFeatures().then(() => {
      let features = {};
      Object.keys(growthbook.getFeatures()).forEach((featureId) => {
        features[featureId] = growthbook.evalFeature(featureId);
      })

      this.setState({ features });
    });
  }

  buildList() {
    const features = this.state.features;
    let items = [];

    for (const featureId in features) {
      const featureData = features[featureId]
      items.push(<ExperimentItem 
        key={featureId}
        name={featureId}
        data={featureData}
      />)
    }

    return <List
    sx={{
      maxHeight: "300px",
      ...scrollY,
    }} >
        {items}
    </List>
  }

  render() {
    try {
      return (<Accordion variant="outlined" defaultExpanded={true} sx={{ padding: "0 24px"}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
            <Typography variant="subtitle1">Experiments</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0 0 24px 0' }}>
          {
            !this.state.features ?
              <div>Loading...</div> :
              <Stack spacing="4px" sx={{
                      ...scrollY,
                      maxWidth: 580
                    }}>
                {this.buildList()}
              </Stack>
          }
        </AccordionDetails> 
      </Accordion>)
    } catch (e) {
      console.error(e);
      return <div>Error</div>
    }
  }
}