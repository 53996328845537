import { useState, useEffect } from 'react';
import {
  Stack,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../../ui/core';
import { EditNoteIcon, ExpandMoreIcon, CloseIcon } from '../../../ui/icons';

import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useDispatch } from 'react-redux';
import { useToast } from '../../../hooks';
import { editUserById } from '../../../redux/Users/actions';

import DriverStatusChip from "../DriversApproval/DriverStatusChip";
import DriverTypeChip from '../DriversApproval/DriverTypeChip';


const style = {
  dl: {
    display: "grid",
    gridTemplateColumns: "108px 1fr",
    gridTemplateRows: "1fr 1fr 1fr 1fr 1fr",
    gridRowGap: "16px",
    margin: 0,
    dt: {
      display: "flex",
      alignItems: "center"
    },
    dd: {
      display: "flex",
      alignItems: "center"
    }
  }
};

const UserEditDialog = ({ user, onClose }) => {
  const dispatch = useDispatch();
  const t = useToast();
  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        email: yup.string().required("Email is required").email("Email not valid"),
        phoneNumber: yup.string()
          .required("Phone Number is required")
          .test({
            name: "phone",
            message: "Phone not valid",
            test: (value) => value && value.startsWith("+"),
          })
      })
    )
  });
  const { isDirty, isValid, isSubmitting } = useFormState({ control: form.control });

  const onSubmit = async (data) => {
    const { success } = await editUserById(user.id, data)(dispatch);
    success ? t.success("User data updated") : t.error("User data not saved");
    success && onClose();
  };

  useEffect(() => {
    form.reset({
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
    });
  }, [user])

  return (<Dialog open onClose={onClose}>
    <Box display="flex" alignItems="center" justifyContent="space-between" padding="32px 32px 0 32px">
      <DialogTitle sx={{ padding: 0 }}>Edit user</DialogTitle>
      <IconButton onClick={onClose}><CloseIcon /></IconButton>
    </Box>
    <Box component="form" onSubmit={form.handleSubmit((data) => onSubmit(data))}>
      <Box padding="32px 32px 32px 32px">
        <Grid container spacing="16px">
          <Grid item xs={6}>
            <TextField
              label="First Name"
              fullWidth
              control={form.control}
              name="firstName"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Middle Name"
              fullWidth
              control={form.control}
              name="middleName"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              fullWidth
              control={form.control}
              name="lastName"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Email"
              fullWidth
              control={form.control}
              name="email"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Phone Number"
              fullWidth
              control={form.control}
              name="phoneNumber"
            />
          </Grid>
        </Grid>
      </Box>
      <Button
        type="submit"
        disabled={!(isValid && isDirty)}
        loading={isSubmitting}
        variant="contained"
        size="large"
        disableElevation
        sx={{ width: "100%", padding: "20px", borderRadius: "0 0 4px 4px" }}
      >Save</Button>
    </Box>
  </Dialog>)
};

export default function UserDetailsCard({ user }) {
  const isDriver = !!user.driver_id;
  const [showDialog, setShowDialog] = useState(false);

  const openDialog = (e) => {
    e.stopPropagation();
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (<>
    <Accordion variant="outlined" sx={{ padding: "0 24px"}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1">Details</Typography>
          <IconButton
            onClick={openDialog}
            sx={{
              position: "absolute",
              left: "80px"
            }}
          >
            <EditNoteIcon sx={{ color: "text.primary" }} />
          </IconButton>
        </Box>
    </AccordionSummary>
    <AccordionDetails sx={{ padding: '0 0 24px 0' }}>
      <Stack direction="row" spacing="16px">
        <Box component="dl" sx={style.dl}>
          <Box component="dt">
            <Typography color="text.secondary" variant="body2">Name</Typography>
          </Box>
          <Box component="dd">{user.firstName} {user.middleName} {user.lastName}</Box>
          <Box component="dt">
            <Typography color="text.secondary">Email</Typography>
          </Box>
          <Box component="dd">{user.email}</Box>
          <Box component="dt">
            <Typography color="text.secondary">Phone</Typography>
          </Box>
          <Box component="dd">{user.phoneNumber}</Box>
          <Box component="dt">
            <Typography color="text.secondary">User ID</Typography>
          </Box>
          <Box component="dd">{user.id}</Box>
          <Box component="dt">
            <Typography color="text.secondary">Stripe ID</Typography>
          </Box>
          <Box component="dd">{user.customer_id}</Box>
        </Box>
        <Box component="dl" sx={style.dl}>
          <Box component="dt">
            <Typography color="text.secondary">Rider ID</Typography>
          </Box>
          <Box component="dd">{user.rider_id}</Box>
          <Box component="dt">
            <Typography color="text.secondary">Driver ID</Typography>
          </Box>
          <Box component="dd">{user.driver_id}</Box>
          <Box component="dt">
            <Typography color="text.secondary">Driver Status</Typography>
          </Box>
          <Box component="dd">
            { isDriver && <DriverStatusChip value={user.driverApplicationStatus} /> }
          </Box>
          <Box component="dt">
            <Typography color="text.secondary">Driver Type</Typography>
          </Box>
          <Box component="dd">
            { 
              isDriver && <DriverTypeChip
                driverType={user.driverType}
                isCustomDriver={user.isCustomDriver}
              />
            }
          </Box>
        </Box>
      </Stack>
    </AccordionDetails>
    </Accordion>
   { showDialog && <UserEditDialog user={user} onClose={closeDialog} /> }
  </>);
}