import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useFormatters } from "../../../hooks";
import {
  Timeline,
  TimelineItem,
  TimelineDot,
  TimelineContent,
  TimelineOppositeContent,
  TimelineConnector,
  TimelineSeparator,
  Typography,
  Box,
} from '../../../ui/core'
import { getTripDriverHistory } from "../../../redux/Trips/actions";
import { RideStatus } from "../../../models/Rides";

const UserLink = ({ user, defaultLabel }) => {
  if (!user) {
    return <span>{defaultLabel}</span>
  }

  return  <Box
    component="a"
    href={`/users/${user.id}/`}
    target="_blank"
    rel="noreferrer"
    sx={{ color: "accent.main" }}
  >{user.firstName} {user.lastName}</Box>
}

const DriverLink = ({ driver }) => <UserLink user={driver}  defaultLabel="Driver" />

const RiderLink = ({ rider }) => <UserLink user={rider}  defaultLabel="Rider" />

const TripActivity = ({ trip }) => {
  const f = useFormatters();
  const dispatch = useDispatch();

  const driverHistory = trip.driverHistory || [];
  const detailedDriverHistory = useSelector((state) => state.trips.tripDriversHistory[trip.id] || [])

  useEffect(() => {
    getTripDriverHistory(trip.id)(dispatch)
  }, [trip.id, dispatch, driverHistory.length])

  const timeline = [];

  // add driver accepted / cancelled history to timeline
  detailedDriverHistory.forEach((item) => {
    if (item.acceptedAt) {
      timeline.push({
        key: Date.parse(item.acceptedAt),
        time: f.date.toTimezoneLocaleString(
          item.acceptedAt,
          trip.originCity.timeZone,
          f.date.DateFormats.DATETIME_SIMPLE_WITH_TIMEZONE
        ),
        action: <Typography>
          <DriverLink driver={item.driver} /> accepted
        </Typography>
      });
    }
    if (item.cancelledAt) {
      timeline.push({
        key: Date.parse(item.cancelledAt),
        time: f.date.toTimezoneLocaleString(
          item.cancelledAt,
          trip.originCity.timeZone,
          f.date.DateFormats.DATETIME_SIMPLE_WITH_TIMEZONE
        ),
        action: <Typography>
          <DriverLink driver={item.driver} /> cancelled ({item.reason})
        </Typography>
      });
    }
  });
  if (trip.acceptedAt && trip.driver) {
    timeline.push({
      key: Date.parse(trip.acceptedAt),
      time: f.date.toTimezoneLocaleString(
        trip.acceptedAt,
        trip.originCity.timeZone,
        f.date.DateFormats.DATETIME_SIMPLE_WITH_TIMEZONE
      ),
      action: <Typography>
        <DriverLink driver={trip.driver} /> accepted
      </Typography>
    });
  }

  // add riders pick-up / drop-off history to timeline
  trip.rides.forEach((ride) => {
    const actions = [
      ride.statusUpdates.find((u) => u.status === RideStatus.en_route),
      ride.statusUpdates.find((u) => u.status === RideStatus.completed)
    ];
    actions.forEach((action) => {
      if (!action) return;
      timeline.push({
        key: Date.parse(action.timestamp),
        time: f.date.toTimezoneLocaleString(
          action.timestamp,
          trip.originCity.timeZone,
          f.date.DateFormats.DATETIME_SIMPLE_WITH_TIMEZONE
        ),
        action: <Typography>
          <DriverLink driver={trip.driver} /> marked <RiderLink rider={ride.rider} /> as <b>{
            action.status === RideStatus.en_route ? 'picked-up' : 'dropped-off'
          }</b>
        </Typography>
      })
    })
  })

  return <Timeline>
    {
      timeline.sort((i1, i2) => i2.key - i1.key).map((item, idx) => (<TimelineItem key={item.key}>
        <TimelineOppositeContent color="text.secondary" sx={{ flex: "0 1 auto" }}>
          {item.time}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
         { idx !== timeline.length - 1 &&  <TimelineConnector /> }
        </TimelineSeparator>
        <TimelineContent>{item.action}</TimelineContent>
      </TimelineItem>))
    }
  </Timeline>
}

export default TripActivity;
