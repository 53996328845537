import {EmailActionsTypes as types} from "./actions";

const initialState = {
  error: null,
  isPending: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.EMAIL_DRIVER_TYPE_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case types.EMAIL_DRIVER_TYPE_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case types.EMAIL_DRIVER_TYPE_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case types.EMAIL_DRIVER_REJECTION_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case types.EMAIL_DRIVER_REJECTION_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case types.EMAIL_DRIVER_REJECTION_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case types.EMAIL_DRIVER_APPROVAL_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case types.EMAIL_DRIVER_APPROVAL_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case types.EMAIL_DRIVER_APPROVAL_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case types.EMAIL_DRIVER_AWAITING_DOCS_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case types.EMAIL_DRIVER_AWAITING_DOCS_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case types.EMAIL_DRIVER_AWAITING_DOCS_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case types.EMAIL_DRIVER_BACKGROUND_CHECK_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case types.EMAIL_DRIVER_BACKGROUND_CHECK_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case types.EMAIL_DRIVER_BACKGROUND_CHECK_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case types.EMAIL_DRIVER_APPLICATION_RECEIPT_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case types.EMAIL_DRIVER_APPLICATION_RECEIPT_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case types.EMAIL_DRIVER_APPLICATION_RECEIPT_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case types.EMAIL_DRIVER_WAITLIST_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case types.EMAIL_DRIVER_WAITLIST_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case types.EMAIL_DRIVER_WAITLIST_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case types.EMAIL_DRIVER_WAITLIST_SUBMITTED_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case types.EMAIL_DRIVER_WAITLIST_SUBMITTED_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case types.EMAIL_DRIVER_WAITLIST_SUBMITTED_FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case types.RESET_STATE:
      return initialState;
    case types.RESET_ERROR:
      return  {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
