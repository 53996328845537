import {login} from "../../helpers/api/auth";
import Cookies from 'cookies-js';

export const AuthActions = {
  REGISTER_STARTED: 'ADMIN_REGISTER_STARTED',
  REGISTER_SUCCESS: 'ADMIN_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'ADMIN_REGISTER_FAILURE',

  LOGIN_STARTED: 'ADMIN_LOGIN_STARTED',
  LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'ADMIN_LOGIN_FAILURE',

  LOGOUT_STARTED: 'ADMIN_LOGOUT_STARTED',
  LOGOUT_SUCCESS: 'ADMIN_LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'ADMIN_LOGOUT_FAILURE',

  DELETE_STARTED: 'ADMIN_DELETE_STARTED',
  DELETE_SUCCESS: 'ADMIN_DELETE_SUCCESS',
  DELETE_FAILURE: 'ADMIN_DELETE_FAILURE',
  RESET_ERROR: 'RESET_ERROR',
};

export const loginAction = (email, password) => dispatch => {
  dispatch({
    type: AuthActions.LOGIN_STARTED,
  });
  return login(email, password)
    .then(response => {
      Cookies.set('user', response.data.token, {
        secure: false,
        domain: window.location.hostname,
        expires: 3600 * 1000, // ONE HOUR
      });
      return  dispatch({
        type: AuthActions.LOGIN_SUCCESS,
        payload: response.data.token
      })
    })
    .catch(error => dispatch({
      type: AuthActions.LOGIN_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    }));
};

export const logout = () => dispatch => {
  Cookies.expire('user');
  dispatch({
    type: AuthActions.LOGOUT_SUCCESS,
    payload: null
  });
};