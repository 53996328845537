const copy = (text) => {
  navigator && navigator.clipboard && navigator.clipboard.writeText(text);
}

const useCopyToClipboard = () => {
  return {
    copy
  };
};

export default useCopyToClipboard;