import { IMask } from 'react-imask';

const dateMasks = {
  // Format: MM/DD/YYYY
  dateShort: IMask.createPipe({
    mask: 'MM/DD/YYYY',
    blocks: {
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        autofix: 'pad',
      },
      DD: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        autofix: 'pad',
      },
      YYYY: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 9999
      },
    },
  }),
  // Format: MM/YYYY
  monthShort: IMask.createPipe({
    mask: 'MM/YYYY',
    blocks: {
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        autofix: 'pad',
      },
      YYYY: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 9999
      },
    },
  })
};

const textMasks = {
  uppercase: (value) => value ? value.toUpperCase() : value,
  number: (value) => value ? Number.parseInt(value) : value,
  number2F: IMask.createPipe({
    mask: Number,
    scale: 2,
    signed: false,
    thousandsSeparator: '',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: '.',
  }),
  currency: IMask.createPipe({
    mask: '{$}0#.00',
    autofix: 'pad',
    blocks: {
      '#': {
        mask: IMask.MaskedNumber,
        scale: 0,
        min: 0,
      }
    }
  })
};

const masks = () => ({
  date: dateMasks,
  text: textMasks,
});

export default masks;