import {get, post} from '..';
import domain from '../hitchEndPointBase';
import axios from "axios";
import Cookies from "cookies-js";

const getDrivers = () => get(`/users/drivers`);

const generateBackgroundCheckReport = (candidateId) => post(
  `$/screening/admin/reports`,
  {candidate_id: candidateId}
);


const runBackgroundCheck = (user_id) => post(
  `/screening/driver/${user_id}/run`,
  {
    headers: { 'Authorization': Cookies.get('user') }
  }
);
const getBackgroundCheckStatus = (user_id) => post(
  `/screening/driver/${user_id}/status`,
  {
    headers: { 'Authorization': Cookies.get('user') }
  }
);
const getBackgroundCheckDetails = (user_id) => post(
  `/screening/driver/${user_id}/details`,
  {
    headers: { 'Authorization': Cookies.get('user') }
  }
);
const postDocumentImage = (formData, driverId) => axios.post(
  `${domain}/users/drivers/${driverId}/image`,
  formData,
  {
    headers: {
      'Authorization': Cookies.get('user'),
      'Content-Type': 'multipart/form-data',
    }
  }
);


export {
  getDrivers,
  runBackgroundCheck,
  getBackgroundCheckStatus,
  getBackgroundCheckDetails,
  postDocumentImage,
  generateBackgroundCheckReport
}
