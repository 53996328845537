import { StationsActionsTypes } from "./actions";

const initialState = {
  all: { data: [], count: 0, loading: true },
  selection: null,
  count: null,
  amenities: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StationsActionsTypes.FETCH_STATIONS_SUCCESS:
      return {
        ...state,
        all: { ...action.payload, loading: false },
      };
    case StationsActionsTypes.STATION_UPDATE_SUCCESS:
      const station = action.payload;
      return {
        ...state,
        all: {
          ...state.all,
          data: state.all.data.map((s) => s.id === station.id ? station : s),
        },
        selection: station,
      };
    case StationsActionsTypes.FETCH_AMENITIES_SUCCESS:
      return {
        ...state,
        amenities: action.payload,
      };
    case StationsActionsTypes.SET_SELECTED_STATION:
      return {
        ...state,
        selection: action.payload,
      };
    default:
      return state;
  }
}