import client from './client';
import { gql } from '@apollo/client';

const getDrivers = async ({ offset = 0, limit = 10, driverApplicationStatus, homeCity, driverType } = {}) => {
  const data = await client.query(
    gql`
      query drivers($offset: Int!, $limit: Int!, $driverApplicationStatus: String, $homeCity: String, $driverType: String) {
        drivers(offset: $offset, limit: $limit, driverApplicationStatus: $driverApplicationStatus, homeCity: $homeCity, driverType: $driverType) {
          data {
            id
            firstName
            lastName
            email
            phoneNumber
            driver_id
            isCustomDriver
            driverType
            driverApplicationStatus
          }
          count
        }
      }
    `,
    { offset, limit, driverApplicationStatus, homeCity, driverType },
  );
  return data.drivers;
};

const getDriversCountByStatus = async () => {
  const data = await client.query(
    gql`
      query countByStatus {
        countByStatus {
          driverApplicationStatus
          count
        }
      }
    `,
    {}
  );
  return data.countByStatus;
};

const getDriver = async (id) => {
  const data = await client.query(
    gql`
      query driver($id: String!) {
        driver(id: $id) {
          id
          firstName
          lastName
          email
          phoneNumber
          dob
          gender
          address {
            city
            state
            line1
            line2
            postal_code
          }
          homeCity
          driver_id
          check_id
          candidate_id
          isCustomDriver
          isDriverVerified
          driverType
          driverApplicationStatus
          validCategories
          vehicles {
            model
            make
            year
            vin
            seatCount
            licensePlate
            type
            category
            active
            registrationExpDate
            registrationImageUrl
            registrationInvalid
          }
          insuranceExpDate
          licenseExpDate
          insuranceImageUrl
          licenseImageUrl
          invalidDocuments
        }
      }
    `,
    { id },
  );
  return data.driver;
};

const updateDriver = async (driverId, update) => {
  const data = await client.mutate(
    gql`
      mutation updateDriver($id: String!, $update: DriverUpdateInput!) {
        updateDriver(id: $id, input: $update) {
          id
          firstName
          lastName
          email
          phoneNumber
          dob
          gender
          address {
            city
            state
            line1
            line2
            postal_code
          }
          homeCity
          driver_id
          check_id
          candidate_id
          isCustomDriver
          driverType
          driverApplicationStatus
          validCategories
          vehicles {
            model
            make
            year
            vin
            seatCount
            licensePlate
            type
            category
            active
            registrationExpDate
            registrationImageUrl
            registrationInvalid
          }
          insuranceExpDate
          licenseExpDate
          insuranceImageUrl
          licenseImageUrl
          invalidDocuments
        }
      }
    `,
    { id: driverId, update },
  );
  return data.updateDriver;
};

const decodeVIN = async (vin) => {
  const data = await client.query(
    gql`
      query decodeVIN($vin: String!) {
        decodeVIN(vin: $vin) {
          make
          model
          year
          type
          vin
        }
      }
    `,
    { vin },
    'cache-first',
  );
  return data.decodeVIN;
};

const getDocumentUploadUrl = async (id) => {
  const data = await client.query(
    gql`
      query presignedUrl($id: String!) {
        presignedUrl(id: $id) {
          url
          fileName
          fileUrl
        }
      }
    `,
    { id },
  );
  return data.presignedUrl;
}

const createScreeningReport = async (id) => {
  const data = await client.query(
    gql`
      mutation createScreeningReport($id: String!) {
        createScreeningReport(id: $id)
      }
    `,
    { id },
  );
  return data.createScreeningReport;
};

const createScreeningInvitation = async (id) => {
  const data = await client.query(
    gql`
      mutation createScreeningInvitation($id: String!) {
        createScreeningInvitation(id: $id)
      }
    `,
    { id },
  );
  return data.createScreeningInvitation;
};

export default {
  getDrivers,
  getDriversCountByStatus,
  getDriver,
  updateDriver,
  decodeVIN,
  getDocumentUploadUrl,
  createScreeningReport,
  createScreeningInvitation,
};