import { useState } from 'react';
import { StationRouteType, StationPartnerType } from '../../../models/Stations';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Autocomplete,
  MenuItem,
} from '../../../ui/core';
import { CloseIcon } from '../../../ui/icons';
import { useHistory } from 'react-router-dom';
import { useForm, useFormState } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useToast, useTextMasks } from '../../../hooks';
import { capitalize } from 'lodash';

import { createStation, setSelectedStation } from '../../../redux/Stations/actions';

const style = {
  fields: {
    padding: "32px 32px 32px 32px",
    display: "flex",
    gap: "16px",
    flexWrap: "wrap",
    '.MuiTextField-root': {
      width: "250px"
    }
  }
};

const AddDialog = ({ onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useToast();
  const m = useTextMasks();

  const cities = useSelector((state) => state.cities.all);

  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      name: '',
      address: '',
      type: '',
      operational: true,
      placeId: '',
      routeType: '',
      city_id: '',
      timeZone: '',
      partnerType: '',
      lat: '',
      lon: ''
    },
  });
  const cityId = form.watch('city_id');
  const { isDirty, isValid, isSubmitting } = useFormState({ control: form.control });

  const onSubmit = async (data) => {
    const coordinates = [Number.parseFloat(data.lon), Number.parseFloat(data.lat)];
    const input = {
      name: data.name,
      address: data.address,
      type: data.type || null,
      operational: data.operational,
      placeId: data.placeId || null,
      routeType: data.routeType || null,
      city_id: data.city_id || null,
      timeZone: data.timeZone || null,
      partnerType: data.partnerType || null,
      coord: coordinates[0] && coordinates[1] ? coordinates : null,
    };
    const { success, station } = await createStation(input)(dispatch);
    if (success) {
      t.success("Station added");
      onClose();
      const params = new URLSearchParams(window.location.search);
      params.append('stationId', station.id);
      params.append('fullScreen', false);
      history.push({ search: params.toString() });
      setSelectedStation(station)(dispatch);
    } else {
      t.error("Station could not be added");
    }
  };

  return (<Dialog open onClose={onClose}>
    <Box display="flex" alignItems="center" justifyContent="space-between" padding="32px 32px 0 32px">
      <DialogTitle sx={{ padding: 0 }}>Add station</DialogTitle>
      <IconButton onClick={onClose}><CloseIcon /></IconButton>
    </Box>
    <Box component="form" onSubmit={form.handleSubmit((data) => onSubmit(data))}>
      <Box sx={style.fields}>
         <TextField
          label="Name"
          control={form.control}
          name="name"
        />
         <TextField
          label="Address"
          control={form.control}
          name="address"
        />
        <TextField
          label="Type"
          control={form.control}
          name="type"
        ></TextField>
        <TextField
          label="Partner Type"
          control={form.control}
          name="partnerType"
          select
        >
          {Object.keys(StationPartnerType).map((option) => (
            <MenuItem key={option} value={option}>
               {capitalize(option)}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          id="citySelector"
          label="City"
          placeholder="Filter by city"
          value={cities.data.find((c) => c.id === cityId) || null}
          sx={{ width: '250px' }}
          getOptionLabel={(c) => `${c.cityName}, ${c.stateAbbr}`}
          options={cities.data}
          onChange={(e, city) => {
            form.setValue('city_id', city.id, { shouldDirty: true, shouldTouch: true });
            form.setValue('timeZone', city.timeZone, { shouldDirty: true, shouldTouch: true });
          }}
        />
        <TextField
          label="Time Zone"
          control={form.control}
          name="timeZone"
        />
        <TextField
          label="Place ID"
          control={form.control}
          name="placeId"
        />
        <TextField
          label="Route Type"
          control={form.control}
          name="routeType"
          select
        >
          {Object.keys(StationRouteType).map((option) => (
            <MenuItem key={option} value={option}>
              {capitalize(option)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Lat"
          control={form.control}
          name="lat"
        ></TextField>
        <TextField
          label="Lon"
          control={form.control}
          name="lon"
        ></TextField>
        <Box padding="5px 24px" width="100%">
          <FormControlLabel
            sx={{ '.MuiFormControlLabel-label': { marginLeft: '24px' }}}
            control={<Switch
              sx={{ transform: "scale(2)" }}
              name="operational"
              control={form.control}
            />}
            label="Operational"
          />
        </Box>
      </Box>
      <Button
        type="submit"
        disabled={!(isValid && isDirty)}
        loading={isSubmitting}
        variant="contained"
        size="large"
        disableElevation
        sx={{ width: "100%", padding: "20px", borderRadius: "0 0 4px 4px" }}
      >Save</Button>
    </Box>
  </Dialog>)
};

const AddStation = () => {
  const [showAddDialog, setShowAddDialog] = useState(false);

  const openAddDialog = () => setShowAddDialog(true);
  const closeAddDialog = () => setShowAddDialog(false);

  return (<>
    <Button variant="outlined" color="info" onClick={openAddDialog}>+ Add new station</Button>
    {showAddDialog && <AddDialog onClose={closeAddDialog} />}
  </>);
};

export default AddStation;