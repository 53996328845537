import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TripActionTypes } from "../redux/Trips/actions";
import { subscribe, unsubscribe } from "pusher-redux";

const useTripsSubscription = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const additionalParams = () => {};
    subscribe(
      "admin-trips",
      "trip-update",
      TripActionTypes.TRIP_UPDATED,
      additionalParams,
    );
    if (false) {
      subscribe(
        `admin-trips`,
        "trip-eta-update",
        TripActionTypes.TRIP_ETA_UPDATED,
        additionalParams,
      );
    }
    // subscribe(
    //   `admin-trips`,
    //   "trip-route-updated",
    //   TripActionTypes.TRIP_ROUTE_UPDATED,
    //   additionalParams
    // );
    return () => {
      unsubscribe("admin-trips", "trip-update", TripActionTypes.TRIP_UPDATED);
      unsubscribe(
        `admin-trips`,
        "trip-eta-update",
        TripActionTypes.TRIP_ETA_UPDATED,
        additionalParams,
      );
      unsubscribe(
        `admin-trips`,
        "trip-route-updated",
        TripActionTypes.TRIP_ROUTE_UPDATED,
        additionalParams,
      );
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(
      () => {
        dispatch({ type: TripActionTypes.TRIPS_RECALCULATE_DISPLAY_STATUS });
      },
      5 * 60 * 1000,
    );

    return () => {
      clearInterval(interval);
    };
  }, []);
};

export default useTripsSubscription;
