import {
  post,
} from '..';
import {isUndefined, isEmpty} from 'lodash';
import {email} from "../../utils/validations";

const sendDriverTypeEmail = (firstName, driverEmail, isCustomDriver) => {
  if (!isEmpty(firstName) && isUndefined(email(driverEmail)) && !isUndefined(isCustomDriver)) {
    const data = {
      firstName,
      driverEmail,
      isCustomDriver
    };
    return post('/email/driver/type', data);
  }
  return null;
};

const sendDriverApprovalEmail = (driverEmail) => {
  if (isUndefined(email(driverEmail))) {
    const data = {
      driverEmail,
    };
    return post('/email/driver/approval', data);
  }
  return null;
};

const sendDriverRejectionEmail = (firstName, driverEmail) => {
  if (!isEmpty(firstName) && isUndefined(email(driverEmail))) {
    const data = {
      firstName,
      driverEmail,
    };
    return post('/email/driver/rejection', data);
  }
  return null;
};

const sendDriverAwaitingDocsEmail = (missingDocs, driverEmail) => {
  if (!isEmpty(missingDocs) && isUndefined(email(driverEmail))) {
    const data = {
      missingDocs,
      driverEmail,
    };
    return post('/email/driver/awaiting', data);
  }
  return null;
};

const sendDriverBackgroundCheckEmail = (driverEmail) => {
  if (isUndefined(email(driverEmail))) {
    const data = {
      driverEmail,
    };
    return post('/email/driver/background', data);
  }
  return null;
};

const sendDriverApplicationReceiptEmail = (driverEmail) => {
  if (isUndefined(email(driverEmail))) {
    const data = {
      driverEmail,
    };
    return post('/email/driver/receipt', data);
  }
  return null;
};

const sendDriverWaitlistEmail = (driverEmail) => {
  if (isUndefined(email(driverEmail))) {
    const data = {
      driverEmail,
    };
    return post('/email/driver/waitlist', data);
  }
  return null;
};

const sendDriverWaitlistSubmittedEmail = (driverEmail) => {
  if (isUndefined(email(driverEmail))) {
    const data = {driverEmail,};
    return post('/email/driver/waitlist_submitted', data);
  }
  return null;
};

export {
  sendDriverTypeEmail,
  sendDriverApprovalEmail,
  sendDriverRejectionEmail,
  sendDriverAwaitingDocsEmail,
  sendDriverBackgroundCheckEmail,
  sendDriverApplicationReceiptEmail,
  sendDriverWaitlistEmail,
  sendDriverWaitlistSubmittedEmail
}
