import React from 'react';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const Button = ({ loading, disabled, startIcon, ...other }) => {
  if (loading) {
    startIcon = <CircularProgress size={16} color="inherit" />;
    disabled = true;
  }

  return <MuiButton startIcon={startIcon} disabled={disabled} {...other} />
};

export default Button;
