import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../components/shared/PrivateRoute";
import DashboardLayout from "../../layouts/DashoardLayout";
import { Avatar, Typography } from "../../ui/core";

import TripsDaily from "./TripsDaily";
import TripsSatellite from "./TripsSatellite";
import TripsNational from "./TripsNational";
import { useTripsSubscription, useDriverLocationSub } from "../../hooks";

const Rides = () => {
  const navigation = [
    {
      label: "Today's trips",
      link: "/trips/daily",
    },
    {
      label: "National Trips",
      link: "/trips/national",
    },
    {
      label: "Satellite View",
      link: "/trips/satellite",
    },
  ];
  useTripsSubscription();
  useDriverLocationSub();

  return (
    <DashboardLayout
      title="Trips"
      avatar={
        <Avatar sx={{ backgroundColor: "#97D2BE" }} variant="rounded">
          <Typography sx={{ color: "#0D3A17" }} variant="pageAvatar">
            T
          </Typography>
        </Avatar>
      }
      navigation={navigation}
    >
      <Switch>
        <PrivateRoute exact path="/trips/daily" component={TripsDaily} />
        <PrivateRoute exact path="/trips/satellite" component={TripsSatellite} />
        <PrivateRoute exact path="/trips/national" component={TripsNational} />
      </Switch>
    </DashboardLayout>
  );
};

export default Rides;
