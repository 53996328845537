import { TripActionTypes } from "./actions";
import { getTripDisplayStatus, isTripNational } from "../../models/Trips";

const initialState = {
  dailyTrips: { date: null, start: null, end: null, data: [], loading: true },
  nationalTrips: { data: [], loading: true },
  tripsETA: {},
  tripRoutes: {},
  obsoleteTripRoutes: {},
  tripDriversHistory: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TripActionTypes.FETCH_DAILY_TRIPS_SUCCESS:
      return {
        ...state,
        dailyTrips: {
          ...action.payload,
          data: action.payload.data.map((t) => ({
            ...t,
            displayStatus: getTripDisplayStatus(t),
          })),
        },
      };
    case TripActionTypes.FETCH_NATIONAL_TRIPS_SUCCESS:
      return {
        ...state,
        nationalTrips: {
          loading: false,
          data: action.payload.data.map((t) => ({
            ...t,
            displayStatus: getTripDisplayStatus(t),
          })),
        },
      };
    case TripActionTypes.TRIPS_RECALCULATE_DISPLAY_STATUS:
      return {
        ...state,
        dailyTrips: {
          ...state.dailyTrips,
          data: state.dailyTrips.data.map((t) => ({
            ...t,
            displayStatus: getTripDisplayStatus(t),
          })),
        },
        nationalTrips: {
          ...state.nationalTrips,
          data: state.nationalTrips.data.map((t) => ({
            ...t,
            displayStatus: getTripDisplayStatus(t),
          })),
        },
      };
    case TripActionTypes.TRIP_UPDATED:
      const trip = {
        ...action.data,
        displayStatus: getTripDisplayStatus(action.data),
      };
      const { dailyTrips, nationalTrips } = state;

      let update = { ...state };

      if (
        dailyTrips.date &&
        trip.departureTime >= dailyTrips.start &&
        trip.departureTime <= dailyTrips.end
      ) {
        const data = dailyTrips.data;
        const isNewTrip = data.findIndex((t) => t.id === trip.id) === -1;
        update = {
          ...update,
          dailyTrips: {
            ...update.dailyTrips,
            data: isNewTrip
              ? [...data, trip]
              : data.map((t) => (t.id === trip.id ? trip : t)),
          },
        };
      }

      if (!nationalTrips.loading && isTripNational(trip)) {
        const data = nationalTrips.data;
        const isNewTrip = data.findIndex((t) => t.id === trip.id) === -1;
        update = {
          ...update,
          nationalTrips: {
            ...update.nationalTrips,
            data: isNewTrip
              ? [...data, trip]
              : data.map((t) => (t.id === trip.id ? trip : t)),
          },
        };
      }

      return update;
    case TripActionTypes.FETCH_TRIP_ETA_SUCCESS:
      const tripEta = action.payload;
      return {
        ...state,
        tripsETA: { ...state.tripsETA, [tripEta.tripId]: tripEta },
        tripRoutes: {
          ...state.tripRoutes,
          [tripEta.tripId]: { polyline: tripEta.tripPolyline },
        },
      };
    case TripActionTypes.TRIP_ETA_UPDATED:
      const tripEtaUpdate = action.data;
      if (!tripEtaUpdate.tripId) {
        return state;
      }
      const tripEtaNew = {
        tripId: tripEtaUpdate.tripId,
        rides: [],
        updatedAt: new Date(),
      };
      tripEtaUpdate.etasForLegs.forEach((etaForLeg) => {
        const eta = etaForLeg.eta;
        etaForLeg.rides.forEach((ride) => {
          let rideEta = tripEtaNew.rides.find((r) => r.rideId === ride.ride_id);
          if (!rideEta) {
            rideEta = {
              rideId: ride.ride_id,
              pickUp: { eta: null },
              dropOff: { eta: null },
            };
            tripEtaNew.rides.push(rideEta);
          }
          if (ride.type === "pick-up") {
            rideEta.pickUp.eta = eta;
          }
          if (ride.type === "drop-off") {
            rideEta.dropOff.eta = eta;
          }
        });
      });
      return {
        ...state,
        tripsETA: { ...state.tripsETA, [tripEtaNew.tripId]: tripEtaNew },
      };
    case TripActionTypes.FETCH_TRIP_ROUTE_SUCCESS:
      const tripRoute = action.payload;
      tripRoute.polyline = (tripRoute.polyline || "").replaceAll(/\\\\/g, "\\");
      return {
        ...state,
        tripRoutes: {
          ...state.tripRoutes,
          [tripRoute.tripId]: { polyline: tripRoute.polyline },
        },
        obsoleteTripRoutes: {
          ...state.obsoleteTripRoutes,
          [tripRoute.tripId]: false,
        },
      };
    case TripActionTypes.TRIP_ROUTE_UPDATED:
      const { tripId } = action.data;

      return {
        ...state,
        obsoleteTripRoutes: {
          ...state.obsoleteTripRoutes,
          [tripId]: true,
        },
      };
    case TripActionTypes.FETCH_TRIP_DRIVER_HISTORY_SUCCESS:
      return {
        ...state,
        tripDriversHistory: {
          ...state.tripDriversHistory,
          [action.payload.tripId]: action.payload.history,
        },
      };
    default:
      return state;
  }
}
