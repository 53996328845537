import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Chip, Menu, MenuItem, Typography, CircularProgress } from '../../../ui/core';
import WarningIcon from '../../../ui/icons/WarningIcon';
import CircleIcon from '../../../ui/icons/CircleIcon';
import CheckCircleIcon from '../../../ui/icons/CheckCircleIcon';
import { DriverStatusOptions } from '../../../models/Drivers';
import { editDriver, fetchDriversCountByStatus } from '../../../redux/Drivers/actions';

const StatusChip = ({ value, loading }) => {
  const option = DriverStatusOptions.find((o) => o.value === value);
  const label = option ? option.label : value;

  switch (value) {
    case 'waitlist':
    case 'submitted':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : null}
        color="info"
        label={label}
      />;
    case 'awaiting_docs':
    case 'background_check_invitation':
    case 'background_check':
    case 'consider':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : <WarningIcon sx={{ fontSize: 16 }} />}
        color="warning"
        label={label}
      />;
    case 'approved':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : <CheckCircleIcon sx={{ fontSize: 16 }} />}
        variant="filled"
        color="info"
        label={label}
      />;
    case 'rejected':
    case 'suspended':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : <WarningIcon sx={{ fontSize: 16 }} />}
        variant="filled"
        color="error"
        label={label}
      />;
    case 'deactivated':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : <WarningIcon sx={{ fontSize: 16 }} />}
        color="error"
        label={label}
      />;
    case 'in_progress':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : <CircleIcon sx={{ fontSize: 16 }} />}
        color="success"
        label={label}
      />;
    case 'resubmitted_docs':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : null}
        label={label}
      />;
    default:
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : null}
        label={label}
      />;
  }
};

const DriverStatusField = ({ driver }) => {
  const { driverApplicationStatus } = driver;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectStatus = async (v) => {
    handleClose();
    if (v !== driverApplicationStatus) {
      setIsSubmitting(true);
      await editDriver(driver, { driverApplicationStatus: v })(dispatch);
      setIsSubmitting(false);
      // update drivers count by status
      fetchDriversCountByStatus({ reset: false })(dispatch);
    }
  };

  return <div>
    <Button
      id="driver-status-button"
      aria-controls={open ? 'driver-status-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
      disabled={isSubmitting}
     >
      <StatusChip value={driverApplicationStatus} loading={isSubmitting} />
    </Button>
    <Menu
        id="driver-status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'driver-status-button',
        }}
      >
        <MenuItem disabled divider>
          <Typography>Change status ...</Typography>
        </MenuItem>
        { 
          DriverStatusOptions.map(({ value }) => <MenuItem
            key={value}
            onClick={() => onSelectStatus(value)}
            sx={{ paddingTop: '8px', paddingBottom: '8px' }}
            >
            <StatusChip value={value} />
          </MenuItem>)
        }
      </Menu>
  </div>
};

export default DriverStatusField;
