import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import { useAuth } from '../../hooks';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useAuth();

  return <Route {...rest} render={props => auth.token ?
    <Component {...props} />
    : <Redirect to={{pathname: "/login", state: { from: props.location }}}/>
  }/>
};

export default PrivateRoute;


