import client from "./client";
import { gql } from "@apollo/client";

const getLocationsForViewport = async (boundingBox) => {
  try {
    // Expect boundingBox to be an array [[lat, lng], [lat, lng], [lat, lng], [lat, lng]]
    const bounds = boundingBox;
    const resolution = 8; // or whatever value you need
    const GET_LOCATIONS_IN_VIEWPORT = gql`
      query getLocationsInViewport($bounds: [[Float!]!]!, $resolution: Float!) {
        getLocationsInViewport(bounds: $bounds, resolution: $resolution) {
          locations {
            location {
              lat
              lon
            }
            timestamp
            geoHash
            user {
              id
              firstName
              lastName
              email
              phoneNumber
              homeCity
            }
          }
        }
      }
    `;

    const data = await client.query(GET_LOCATIONS_IN_VIEWPORT, {
      bounds,
      resolution,
    });

    return data.getLocationsInViewport.locations;
  } catch (err) {
    throw err;
  }
};

export default {
  getLocationsForViewport,
};
