import hitchApi from '../hitchEndPointBase';
import axios from "axios";

const baseUrl = `${hitchApi}/auth/admin/`;
//const baseUrl = `http://localhost:8001/auth/admin/`;

const login = (email, password) => axios.post(`${baseUrl}login`, {email, password});

export {
  login,
}
