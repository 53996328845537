import { useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  IconButton,
  CircularProgress,
  Chip,
} from '../../../ui/core';
import { ExpandMoreIcon, MoreVertIcon } from '../../../ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { useToast } from '../../../hooks';
import {
  getPaymentSources,
  removePaymentSource,
  setDefaultPaymentSource,
} from '../../../redux/Users/actions';

import AmexSvg from '../../../assets/payment/amex.svg';
import DiscoverSvg from '../../../assets/payment/discover.svg';
import MaestroSvg from '../../../assets/payment/maestro.svg';
import McSvg from '../../../assets/payment/mc.svg';
import VisaSvg from '../../../assets/payment/visa.svg';
import CardSvg from '../../../assets/payment/card.svg';

const CardItem = ({ source, waiting, onDelete, onSetDefault }) => {
  const id = source.id;
  const isDefault = source.isDefault;
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  let icon = CardSvg;

  if (source.brand.toLowerCase() === 'amex') {
    icon = AmexSvg;
  }
  if (source.brand.toLowerCase() === 'discover') {
    icon = DiscoverSvg;
  }
  if (source.brand.toLowerCase() === 'maestro') {
    icon = MaestroSvg;
  }
  if (source.brand.toLowerCase() === 'mastercard') {
    icon = McSvg;
  }
  if (source.brand.toLowerCase() === 'visa') {
    icon = VisaSvg;
  }

  const closeMenu = () => setAnchorEl(null);

  const deleteCard = async () => {
    closeMenu();
    onDelete(source);
  };

  const setCardAsDefault = () => {
    closeMenu();
    onSetDefault(source);
  };

  return (<ListItem
    secondaryAction={<>
      <IconButton
        disabled={waiting}
        id={`${id}-menu-button`}
        aria-controls={menuOpen ? `${id}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        { waiting ? <CircularProgress size="24px" /> : <MoreVertIcon /> }
      </IconButton>
      <Menu
          id={`${id}-menu`}
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{'aria-labelledby': `${id}-menu-button`}}
        >
          <MenuItem onClick={deleteCard}>
            <Typography color="error">Delete card</Typography>
          </MenuItem>
          {
            !isDefault && <MenuItem onClick={setCardAsDefault}>
            <Typography>Set as default</Typography>
          </MenuItem>
          }
        </Menu>
    </>}
  >
    <ListItemIcon>
      <img height="24" width="35" src={icon} />
    </ListItemIcon>
    <ListItemText>
     <Stack direction="row" alignItems="center" spacing="16px">
        <span>•••• { source.last4 }</span>
        <span>{source.exp_month < 10 ? '0' : ''}{ source.exp_month }/{ source.exp_year }</span>
        { isDefault && <Chip label="Default" color="info" /> }
     </Stack>
    </ListItemText>
  </ListItem>)
};

const PaymentSourcesCard = ({ user }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.users.selection.paymentSources);
  const [waitingFor, setWaitingFor] = useState(null);
  const t = useToast();

  useEffect(() => {
    getPaymentSources(user.id)(dispatch);
  }, []);

  const deleteSource = async (source) => {
    setWaitingFor(source.id);
    const { success } = await removePaymentSource(user.id, source.id)(dispatch);
    setWaitingFor(null);
    success ?
      t.success(`${capitalize(source.object)} as been deleted`) :
      t.error(`Something went wrong`);
  };

  const setDefaultSource = async (source) => {
    setWaitingFor(source.id);
    const { success } = await setDefaultPaymentSource(user.id, source.id)(dispatch);
    setWaitingFor(null);
    success ?
      t.success(`${capitalize(source.object)} has been set as default`) :
      t.error(`Something went wrong`);
  };

  return (<Accordion variant="outlined" sx={{ padding: "0 24px"}}>
   <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
      <Typography variant="subtitle1">Ride Payment Cards</Typography>
   </AccordionSummary>
   <AccordionDetails sx={{ padding: '0 0 24px 0' }}>
    <Stack spacing="4px">
      {
        !data && <>
          <Skeleton variant="rectangular" height={48} />
          <Skeleton variant="rectangular" height={48} />
        </>
      }
      {
        data && <List>
          {
            data
              .filter((source) => source.object === 'card')
              .map((source) => (<CardItem
                key={source.id}
                user={user}
                source={source}
                waiting={waitingFor === source.id}
                onDelete={deleteSource}
                onSetDefault={setDefaultSource}
              />))
          }
        </List>
      }
    </Stack>
   </AccordionDetails>
  </Accordion>)
};

export default PaymentSourcesCard;