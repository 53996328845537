import { useState } from 'react';
import { Stack, Typography, IconButton, Popover, Box } from '../../../ui/core';
import { InfoIcon } from '../../../ui/icons';
import { useStationSchedule } from '../../../hooks';

const DAYS = ['Sunday', 'Monday', "Tuesday", 'Wednesday', "Thursday", "Friday", "Saturday"];


const ScheduleItem = ({ station, hours }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { formatTime } = useStationSchedule(station);
  const day = DAYS[hours.day];

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  return <Stack position="relative" direction="row" alignItems="center" spacing="40px" minHeight="30px">
    <Typography color="text.secondary" width="96px">{day}</Typography>
    <Typography>{ formatTime(hours) }</Typography>
    {
      !!hours.regularHours && <>
        <Typography
          sx={{
            position: "absolute",
            top: "-6px",
            left: "264px",
          }}
          padding="4px"
          aria-owns={open ? `mouse-over-popover-${day}` : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <InfoIcon onMouseEnter={handlePopoverOpen} sx={{ fontSize: "16px" }} />
        </Typography>
        <Popover
          id={`mouse-over-popover-${day}`}
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box p="24px" width="375px">
            <Typography>Regular schedule: { formatTime(hours.regularHours) }</Typography>
          </Box>
        </Popover>
      </>
    }
  </Stack>
};

const StationSchedule = ({ station }) => {
  const { today, currentSchedule, regularSchedule } = useStationSchedule(station);

  const schedule = [0, 1, 2, 3, 4, 5, 6].map((idx) => {
    const date = today.clone().add(idx, 'd');
    const currentHours = currentSchedule.find((h) => h.day === date.day() && h.date === date.format("YYYY-MM-DD"));
    const regularHours = regularSchedule.find((h) => h.day === date.day());
    const hours = currentHours || regularHours;

    if (currentHours && regularHours && (currentHours.opensAt !== regularHours.opensAt || currentHours.closesAt !== regularHours.closesAt)) {
      return { ...currentHours, regularHours };
    }
    return hours;
  }).filter((hours) => !!hours);

  return <Stack spacing="8px">
    {
      schedule.map((hours) => <ScheduleItem key={hours.day} hours={hours} station={station}  />)
    }
  </Stack>
};

export default StationSchedule;