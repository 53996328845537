import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowForwardIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 24 24">
   <path d="m12 20-1.425-1.4 5.6-5.6H4v-2h12.175l-5.6-5.6L12 4l8 8Z"/>
  </SvgIcon>
};

export default ArrowForwardIcon;
