import axios from "axios";
import Cookies from 'cookies-js';
import hitchApi from './hitchEndPointBase';

const parseResponse = (response) => {
  if (!response.ok) {
    return response.json().then((json) => {
      if (json.error) throw new Error(JSON.stringify(json))
    })
  }
  return response.text().then((text) => {
    try {
      return {
        statusCode: response.status,
        data: JSON.parse(text)
      }
    } catch (e) {
      return {
        statusCode: response.status,
        data: {text}
      }
    }
  })
};

// function to mock api calls
export default (
  data,
  delay = 500,
) => new Promise(resolve => setTimeout(() => resolve(parseResponse({
  status: 200,
  statusText: 'Success',
  data,
  text: () => Promise.resolve(JSON.stringify(data)),
  url: '',
  ok: true,
  json: () => Promise.resolve(data),
  headers: {},
})), delay))

export const get = endpoint => axios.get(`${hitchApi}${endpoint}`, {headers: {"Authorization": Cookies.get('user')}});
export const post = (endpoint, data = {}) => axios.post(`${hitchApi}${endpoint}`, data, {headers: {"Authorization": Cookies.get('user')}});
export const put = (endpoint, data = {}) => axios.put(`${hitchApi}${endpoint}`, data, {headers: {"Authorization": Cookies.get('user')}});
export const patch = (endpoint, data = {}) => axios.patch(`${hitchApi}${endpoint}`, data, {headers: {"Authorization": Cookies.get('user')}});
export const del = (endpoint) => axios.delete(`${hitchApi}${endpoint}`, {headers: {"Authorization": Cookies.get('user')}});
