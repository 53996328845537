import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography, Stack, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Autocomplete, TextField } from "../../../ui/core";
import { useTextMasks, useToast } from '../../../hooks';
import { addTripOverrideCity } from '../../../redux/Trips/actions';

const TripOverrideCitiesField = ({ trip }) => {
  const cities = useSelector((state) => state.cities.all.data);
  const dispatch = useDispatch();
  const m = useTextMasks();
  const t = useToast();

  const [open, setOpen] = useState(false);
  const [city, setCity] = useState(null);
  const [bonus, setBonus] = useState('0');
  const [isSaving, setIsSaving] = useState(false);

  const onSave = async () => {
    setIsSaving(true);
    const data = { bonus: Number.parseFloat(bonus), city_id: city.id };
    const { success } = await addTripOverrideCity(trip.id, data)(dispatch);
    success ? t.success("Override city added") : t.error("Override city could not be added");
    setIsSaving(false);
  };

  return (<>
    <Button onClick={() => setOpen(true)}>
      <Typography variant="body2" color="text.secondary" sx={{ textDecoration: "underline" }}>
        Add override cities
      </Typography>
    </Button>
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Add override city</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing="24px">
          <Autocomplete
            label="City"
            value={city}
            options={cities}
            getOptionLabel={(c) => `${c.cityName}, ${c.stateAbbr}`}
            onChange={(e, v) => setCity(v)}
          />
          <TextField
            label="Bonus"
            value={bonus}
            InputProps={{
              startAdornment: "$"
            }}
            mask={m.text.number2F}
            onChange={(e) => setBonus(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          disabled={!(city && bonus)}
          loading={isSaving}
          onClick={onSave}
        >Add city</Button>
      </DialogActions>
    </Dialog>
  </>);
};

export default TripOverrideCitiesField;