import { useState } from 'react';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  TextField,
  Button,
} from '../../../ui/core';
import { EditNoteIcon, CloseIcon } from '../../../ui/icons';

import { useForm, useFormState } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useToast } from '../../../hooks';

import { updateStation } from '../../../redux/Stations/actions';

const AddressEditDialog = ({ station, onClose }) => {
  const dispatch = useDispatch();
  const t = useToast();

  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      address: station.address || '',
    },
  });
  const { isDirty, isValid, isSubmitting } = useFormState({ control: form.control });

  const onSubmit = async (data) => {
    const { success } = await updateStation(station.id, data)(dispatch);
    success ? t.success("Station address updated") : t.error("Not saved");
    success && onClose();
  };

  return (<Dialog open onClose={onClose}>
    <Box display="flex" alignItems="center" justifyContent="space-between" padding="32px 32px 0 32px">
      <DialogTitle sx={{ padding: 0, marginRight: "120px" }}>Edit Station Address</DialogTitle>
      <IconButton onClick={onClose}><CloseIcon /></IconButton>
    </Box>
    <Box component="form" onSubmit={form.handleSubmit((data) => onSubmit(data))}>
      <Box padding="32px">
        <TextField
          label="Address"
          fullWidth
          control={form.control}
          name="address"
        />
      </Box>
      <Button
        type="submit"
        disabled={!(isValid && isDirty)}
        loading={isSubmitting}
        variant="contained"
        size="large"
        disableElevation
        sx={{ width: "100%", padding: "20px", borderRadius: "0 0 4px 4px" }}
      >Save</Button>
    </Box>
  </Dialog>)
};

const EditStationAddress = ({ station }) => {
  const [showEditDialog, setShowEditDialog] = useState(false);

  return (<>
    <IconButton onClick={() => setShowEditDialog(true)}>
      <EditNoteIcon sx={{ color: "primary.main", fontSize: "24px" }} />
    </IconButton>
    { showEditDialog && <AddressEditDialog station={station} onClose={() => setShowEditDialog(false)} />}
  </>);
};

export default EditStationAddress;
