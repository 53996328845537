import { CitiesActionsTypes } from "./actions";

const initialState = {
  all: { data: [], count: 0, loading: true },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CitiesActionsTypes.FETCH_CITIES_SUCCESS:
      return {
        ...state,
        all: action.payload.cities,
      }
    default:
      return {
        ...state,
      };
  }
  return state;
}