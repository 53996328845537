import { Chip } from '../../../ui/core';

import CheckCircleIcon from '../../../ui/icons/CheckCircleIcon';
import WarningIcon from '../../../ui/icons/WarningIcon';
import CircleIcon from '../../../ui/icons/CircleIcon';
import CancelIcon from '../../../ui/icons/CancelIcon';

const TripStatusChip = ({ status }) => {
  switch (status) {
    case 'completed':
      return <Chip
        icon={<CheckCircleIcon sx={{ fontSize: 16 }} />}
        color="info"
        label="Completed"
      />;
    case 'cancelled':
      return <Chip
        icon={<CancelIcon sx={{ fontSize: 16 }} />}
        label="Cancelled"
      />;
    case 'processing':
      return <Chip
        icon={<CircleIcon sx={{ fontSize: 16 }} />}
        color="warning"
        label="Processing"
      />;
    case 'in_progress':
      return <Chip
        icon={<CircleIcon sx={{ fontSize: 16 }} />}
        color="success"
        label="In Progress"
      />;
    case 'finalizing_details':
      return <Chip
        icon={<CircleIcon sx={{ fontSize: 16 }} />}
        color="warning"
        label="Finalizing details"
      />;
    case 'driver_cancelled':
      return <Chip
        icon={<CancelIcon sx={{ fontSize: 16 }} />}
        label="Driver Cancelled"
      />;
    case 'unmatched':
      return <Chip
        icon={<CancelIcon sx={{ fontSize: 16 }} />}
        label="Unmatched"
      />;
    default:
      return <Chip label={status} />;
  }
};

export default TripStatusChip;
