export const scrollY = {
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
    WebkitBoxShadow: 'inset 0 0 0px rgba(0, 0, 0, 0)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#AAAAAA',
    borderRadius: '8px',
    border: '4px solid rgba(0,0,0,0)',
    backgroundClip: 'padding-box',
  },
};

export const scrollX = {
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    height: '12px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
    WebkitBoxShadow: 'inset 0 0 0px rgba(0, 0, 0, 0)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#AAAAAA',
    borderRadius: '8px',
    height: '12px',
    border: '4px solid rgba(0,0,0,0)',
    backgroundClip: 'padding-box',
  },
};

export const scrollXY = {
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '12px',
    height: '12px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
    WebkitBoxShadow: 'inset 0 0 0px rgba(0, 0, 0, 0)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#AAAAAA',
    borderRadius: '8px',
    border: '4px solid rgba(0,0,0,0)',
    backgroundClip: 'padding-box',
  },
};