import client from './client';
import { gql } from '@apollo/client';

const searchDrivers = async ({ q, offset = 0, limit = 20 } = {}) => {
  const data = await client.query(
    gql`
      query searchDrivers($offset: Int!, $limit: Int!, $q: String!) {
        searchDrivers(offset: $offset, limit: $limit, q: $q) {
          id
          firstName
          lastName
          email
          phoneNumber
          driver_id
          isCustomDriver
          driverType
          driverApplicationStatus
          imageUrl
        }
      }
    `,
    { offset, limit, q },
  );
  return data.searchDrivers;
};

const searchUsers = async ({ q, offset = 0, limit = 20 } = {}) => {
  const data = await client.query(
    gql`
      query searchUsers($offset: Int!, $limit: Int!, $q: String!) {
        searchUsers(offset: $offset, limit: $limit, q: $q) {
          id
          firstName
          lastName
          email
          phoneNumber
          driver_id
        }
      }
    `,
    { offset, limit, q },
  );
  return data.searchUsers;
};

const searchPromos = async ({ q, offset = 0, limit = 20 } = {}) => {
  const data = await client.query(
    gql`
      query searchPromos($offset: Int!, $limit: Int!, $q: String!) {
        searchPromos(offset: $offset, limit: $limit, q: $q) {
          id
          code
          description
          subtitle
          type
          kind
          beforeEventCount
          afterEventCount
          startsOn
          endsOn
          startOn
          endOn
          maxUses
          minimumPurchaseAmount
          maximumDiscountAmount
          isReferral
          referralCredit
          personType
          shouldAutoApplyToNewUsers
          isActive
          options {
            id
            type
            amount
            priceHike
            routeType
            seatDiscounts
          }
        }
      }
    `,
    { offset, limit, q },
  );
  return data.searchPromos;
};

const searchStations = async ({ q, offset = 0, limit = 20 } = {}) => {
  const data = await client.query(
    gql`
      query searchStations($offset: Int!, $limit: Int!, $q: String!) {
        searchStations(offset: $offset, limit: $limit, q: $q) {
          id
          stationName
          stationAddress
          operational
        }
      }
    `,
    { offset, limit, q },
  );
  return data.searchStations;
};

export default {
  searchDrivers,
  searchUsers,
  searchPromos,
  searchStations,
};