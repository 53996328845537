import React from 'react';
import MuiTextField from '@mui/material/TextField';
import { useController } from 'react-hook-form';

const MaskedTextField = ({ mask, name, value, onChange, ...other }) => {
  return <MuiTextField
    name={name}
    value={mask(value)}
    onChange={e => onChange && onChange({ target: { name, value: mask(e.target.value) } })}
    {...other}
   />
};

const ValidatedTextField = ({ name, control, rules, mask,  ...other }) => {
  const { field, fieldState } = useController({ name, control, rules });

  if (mask) {
    return <MaskedTextField
      name={name}
      mask={mask}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      inputRef={field.ref}
      error={!!fieldState.error}
      helperText={fieldState.error ? fieldState.error.message : ''}
      {...other}
    />
  }

  return <MuiTextField
    name={name}
    value={field.value}
    onChange={field.onChange}
    onBlur={field.onBlur}
    inputRef={field.ref}
    error={!!fieldState.error}
    helperText={fieldState.error ? fieldState.error.message : ''}
    {...other}
  />
};

const TextField = ({ control, mask, ...other }) => {
  if (control) {
    return <ValidatedTextField control={control} mask={mask} {...other} />
  }
  if (mask) {
    return <MaskedTextField mask={mask} {...other} />
  }
  return <MuiTextField {...other} />
};

export default TextField;
