
import { useDispatch, useSelector } from 'react-redux';
import { logout, loginAction } from '../redux/Auth/actions';

const useAuth = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const doLogout = () => logout()(dispatch);

  const doLogin = async (email, password) => {
    loginAction(email, password)(dispatch);
  };

  return { login: doLogin, logout: doLogout, token };
};

export default useAuth;