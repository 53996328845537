import moment from "moment";
import {isUndefined} from 'lodash';
import {stringify, parse} from 'query-string'

export const parseBoolean = value => value === 'true';

export const parseStringToInt = value => {
  if (isUndefined(value)) return '';
  if (isNaN(parseInt(value[value.length - 1], 10))) {
    return value.slice(0, -1);
  }
  return parseInt(value, 10)
};

export const parseStringDate = (val) => {
  if (isUndefined(val)) return '';
  //Clears all alphabet inputs
  if (isNaN(parseInt(val[val.length - 1], 10))) {
    return val.slice(0, -1);
  }
  if (parseInt(val[3], 10) <= 9 && parseInt(val[3], 10) > 3) {
    val = val.slice(0, 3) + '0' + val.slice(3);
  }
  if (parseInt(val[0], 10) <= 9 && parseInt(val[0], 10) > 1) {
    val = '0' + val;
  }
  if (val.length === 2 || val.length === 5) {
    val += '/';
  }
  if (val.length >= 10) {
    return val.slice(0, 10);
  }
  return val;
};

export const parseStringMonthYearDate = (val) => {
  if (isUndefined(val)) return '';
  //Clears all alphabet inputs
  if (isNaN(parseInt(val[val.length - 1], 10))) {
    return val.slice(0, -1);
  }
  if (parseInt(val[0], 10) <= 9 && parseInt(val[0], 10) > 1) {
    val = '0' + val;
  }
  if (val.length === 2) {
    val += '/';
  }
  if (val.length >= 7) {
    return val.slice(0, 7);
  }
  return val;
};

export const parsePhone = (number) => number ? number.replace(/[^+\d]/g, '') : '';

export const parseDateCreatedDaysAgo = (id) => {
  const today = moment().tz('America/Chicago').utc();
  const timestamp = moment(id).tz('America/Chicago').utc();
  return today.diff(timestamp, 'days');
};

export const parseDateCreated = (id) => {
  let timestamp = id.toString().substring(0, 8);
  return moment(parseInt(timestamp, 16) * 1000).tz('America/Chicago').utc().format('MMM D, YYYY h:mm a');
};

export const parseDateCreatedToTime = (id) => {
  let timestamp = id.toString().substring(0, 8);
  return parseInt(timestamp, 16) * 1000;
};

export const parseObjectToQueryString = obj => !obj ? '' : `?${stringify(obj)}`;

export const parseQueryStringToObject = string => (string instanceof String || string.length < 2)
  ? undefined
  : parse(string);

export const parseDriveTripTime = (departureTime, arrivalTime) => moment.utc(
  moment(arrivalTime).diff(moment(departureTime), "seconds") * 1000
).format('HH:mm:ss');

export const parseTripToRides = (trip) => {
  const filled = trip.filled ? trip.filled.map((item) => ({
    ...item,
    status: "filled"
  })) : [];
  const unfilled = trip.unfilled ? trip.unfilled.map((item) => ({
    ...item,
    status: "unfilled"
  })) : [];

  // const filled = trip.filled ? trip.filled.reduce((acc, item) => {
  //   const { rides, ...rest } = item;
  //   if (!rides) return acc;

  //   return [
  //     ...acc,
  //     ...rides.map((ride) => ({
  //       ...ride,
  //       ...rest,
  //       tripStatus: "filled"
  //     }))
  //   ]
  // }, []) : [];
  // const unfilled = trip.unfilled ? trip.unfilled.reduce((acc, item) => {
  //   const { rides, status, ...rest } = item;
  //   if (!rides) return acc;

  //   return [
  //     ...acc,
  //     ...rides.map((ride) => ({
  //       ...ride,
  //       ...rest,
  //       tripStatus: "unfilled"
  //     }))
  //   ]
  // }, []) : [];

  return filled.concat(unfilled);
}
