export default {
  checkr: {
    baseUrl: "https://dashboard.checkr.com",
  },
  api: {
    baseUrl: "https://api.hitch.net",
  },
  pusher: {
    key: "56148a4a9779c4829bcf",
  },
  googleapis: {
    apiKey: "AIzaSyBRR79it9lPoLWhrAjMCfmTL0baZ90v_CY",
  },
};
