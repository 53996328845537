import moment from "moment";
import PhoneNumber from "awesome-phonenumber";

const DateFormats = {
  DATE_SHORT: "MM/DD/YYYY",
  DAY_SHORT: "DD",
  DATE_MED: "MMM DD, YYYY",
  DATE_MED_WITH_WEEKDAY: "ddd, MMM DD, YYYY",
  DAY_MONTH_MED: "MMM DD",
  MONTH_SHORT: "MM/YYYY",
  TIME_SIMPLE: "hh:mm A",
  TIME_SIMPLE_WITH_TIMEZONE: "hh:mm A z",
  DATETIME_SHORT: "MMM DD, YYYY hh:mm A",
  DATETIME_SHORT_WITH_TIMEZONE: "MMM DD, YYYY hh:mm A z",
  DATETIME_SIMPLE_WITH_TIMEZONE: "M/DD hh:mm A z",
  DATETIME_MED: "MMM DD, YYYY, hh:mm A",
  DATETIME_MED_WITH_SEC: "MMM DD, YYYY, hh:mm:ss A",
  WEEKDAY_MED: "",
  DATE_ISO: "YYYY-MM-DD",
};

const dateFormatter = {
  toLocaleString: (value, format) => {
    if (!value) {
      return "";
    }
    const d = moment(value);
    return d.isValid() ? d.format(format) : "";
  },
  toISO: (value) => {
    if (!value) {
      return "";
    }
    const d = moment(value);
    return d.isValid() ? d.toISOString() : "";
  },
  toTimezoneLocaleString: (value, timezone, format) => {
    if (!value) {
      return "";
    }
    const d = moment.utc(value).tz(timezone);
    return d.isValid() ? d.format(format) : "";
  },
  DateFormats,
};

const phoneFormatter = {
  toInternational: (value) => {
    if (value) {
      const phone = new PhoneNumber(value.toString());
      return phone.getNumber("international");
    } else return value;
  },
};

const numberFormatter = {
  decimal: (value) => {
    if (value === "") {
      return "";
    }
    return Intl.NumberFormat("en", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(value);
  },
  currency: (value) => {
    if (value === "") {
      return "";
    }
    return Intl.NumberFormat("en", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(value);
  },
  percent: (value) => {
    if (value === "") {
      return "";
    }
    return Intl.NumberFormat("en", {
      style: "percent",
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(value);
  },
};

const stringFormatter = {
  noun: (singular, plural, count) => {
    if (count === 1) return singular;
    return plural;
  },
};

const formatter = () => {
  return {
    date: dateFormatter,
    phone: phoneFormatter,
    number: numberFormatter,
    string: stringFormatter,
  };
};

export default formatter;
