import React, { useEffect } from 'react';
import { Switch } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import PrivateRoute from '../../components/shared/PrivateRoute';
import DashboardLayout from '../../layouts/DashoardLayout';
import { Avatar, Typography } from '../../ui/core';

import UserDetails from './UserDetails';
import UsersTable from './UsersTable';
import UsersSearch from '../../components/widgets/Users/UsersSearch';
import { fetchUsersCount } from '../../redux/Users/actions';
import { useFormatters } from '../../hooks';

const Rides =  () => {
  const dispatch = useDispatch();
  const f = useFormatters();
  const count = useSelector((state) => state.users.count);
  const navigation = [
    {
      label: "Users",
      link: "/users",
      sublinks: [
        {
          label: <><span>All</span><span>{count ? f.number.decimal(count) : ''}</span></>,
          link: "/users",
        },
      ],
    }
  ];

  useEffect(() => {
    fetchUsersCount()(dispatch);
  }, [dispatch]);

  return (
    <DashboardLayout
      title="Users"
      avatar={<Avatar sx={{ backgroundColor: '#732C59' }} variant="rounded">
        <Typography sx={{ color: '#FFF' }} variant="pageAvatar">U</Typography>
      </Avatar>}
      navigation={navigation}
      appBarContent={<UsersSearch />}
    >
      <Switch>
        <PrivateRoute exact path="/users" component={UsersTable}/>
        <PrivateRoute exact path="/users/:id" component={UserDetails}/>
      </Switch>
    </DashboardLayout>
  );
};

export default Rides;
