import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EditNoteIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 20 20">
    <rect fill="none" height="20" width="20"/>
    <path d="M3,5h9v1.5H3V5z M3,11.25h6v1.5H3V11.25z M3,8.12h9v1.5H3V8.12z M16.78,11.99l0.65-0.65c0.29-0.29,0.29-0.77,0-1.06 l-0.71-0.71c-0.29-0.29-0.77-0.29-1.06,0l-0.65,0.65L16.78,11.99z M16.19,12.58L11.77,17H10v-1.77l4.42-4.42L16.19,12.58z"/>
  </SvgIcon>
};

export default EditNoteIcon;