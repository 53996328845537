import { Stack } from '../../../ui/core';

const TimeRangeVisual = () => {
  return (<Stack justifyContent="center" alignItems="center" sx={{ height: '62px', width: '8px' }}>
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="3" stroke="#252525" strokeWidth="2"/>
  </svg>
  <svg width="4" height="45" viewBox="0 0 1 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.75" y="0.25" width="2" height="44.5" stroke="#ACACAC" strokeWidth="2" strokeDasharray="5 5"/>
  </svg>
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="4" fill="#686868"/>
  </svg>
  </Stack>);
};

export default TimeRangeVisual;