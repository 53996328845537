import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SearchIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 22 22">
    <path d="M21.4369 19.9905L14.6198 13.1734C15.6777 11.8058 16.2499 10.1337 16.2499 8.37497C16.2499 6.26972 15.4283 4.29573 13.9426 2.80736C12.4568 1.319 10.4776 0.5 8.37497 0.5C6.27235 0.5 4.29311 1.32162 2.80736 2.80736C1.319 4.29311 0.5 6.26972 0.5 8.37497C0.5 10.4776 1.32162 12.4568 2.80736 13.9426C4.29311 15.4309 6.26972 16.2499 8.37497 16.2499C10.1337 16.2499 11.8032 15.6777 13.1708 14.6224L19.9879 21.4369C20.0079 21.4569 20.0316 21.4728 20.0578 21.4836C20.0839 21.4944 20.1119 21.5 20.1402 21.5C20.1684 21.5 20.1964 21.4944 20.2226 21.4836C20.2487 21.4728 20.2724 21.4569 20.2924 21.4369L21.4369 20.295C21.4569 20.275 21.4728 20.2513 21.4836 20.2252C21.4944 20.1991 21.5 20.1711 21.5 20.1428C21.5 20.1145 21.4944 20.0865 21.4836 20.0604C21.4728 20.0343 21.4569 20.0105 21.4369 19.9905ZM12.5329 12.5329C11.42 13.6433 9.94471 14.2549 8.37497 14.2549C6.80522 14.2549 5.32998 13.6433 4.21698 12.5329C3.10661 11.42 2.49499 9.94471 2.49499 8.37497C2.49499 6.80522 3.10661 5.32735 4.21698 4.21698C5.32998 3.10661 6.80522 2.49499 8.37497 2.49499C9.94471 2.49499 11.4226 3.10399 12.5329 4.21698C13.6433 5.32998 14.2549 6.80522 14.2549 8.37497C14.2549 9.94471 13.6433 11.4226 12.5329 12.5329Z" />
  </SvgIcon>
};

export default SearchIcon;
