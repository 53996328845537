// require("dotenv").config();
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import Cookies from "cookies-js";
import config from "../../config";

const uri = `${config.api.baseUrl}/admin/graphql`;

const httpLink = createHttpLink({
  uri,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: Cookies.get("user"),
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: uri.replace("https://", "wss://").replace("http://", "ws://"),
    retryAttempts: 1000,
    retryWait: (retries) => {
      return new Promise((resolve) => {
        setTimeout(() => resolve(), Math.min((retries + 1) * 1000, 60000));
      });
    },
    shouldRetry: () => true,
    connectionParams: {
      authorization: Cookies.get("user"),
    },
  })
);

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink)
);

const cache = new InMemoryCache({
  typePolicies: {
    DecodedVINSchema: { keyFields: ["vin"] },
    DriverSchema: { keyFields: ["driver_id"] },
  },
});

export const apolloClient = new ApolloClient({
  cache: cache,
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

export const createGQLClient = () => {
  const query = (query, variables, fetchPolicy) => {
    return apolloClient
      .query({
        query,
        variables,
        fetchPolicy,
      })
      .then(({ data }) => data);
  };

  const mutate = (mutation, variables) => {
    return apolloClient
      .mutate({
        mutation,
        variables,
      })
      .then(({ data }) => data);
  };

  const subscribe = (query, variables, onNext) => {
    return apolloClient.subscribe({ query, variables }).subscribe(
      ({ data }) => onNext(data),
      () => {
        console.log("error hapenned");
        // try reconnect
        console.log("trying to reconnect");
        setTimeout(() => {
          subscribe(query, variables, onNext);
        }, 2000);
      }
    );
  };

  return { query, mutate, subscribe };
};

export default createGQLClient();
