import { Chip, CircularProgress } from '../../../ui/core';
import { WarningIcon, CheckCircleIcon, CircleIcon } from '../../../ui/icons';
import { DriverStatusOptions } from '../../../models/Drivers';

export default function DriverStatusChip({ value, loading }) {
  const option = DriverStatusOptions.find((o) => o.value === value);
  const label = option ? option.label : value;

  switch (value) {
    case 'waitlist':
    case 'submitted':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : null}
        color="info"
        label={label}
      />;
    case 'awaiting_docs':
    case 'background_check_invitation':
    case 'background_check':
    case 'consider':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : <WarningIcon sx={{ fontSize: 16 }} />}
        color="warning"
        label={label}
      />;
    case 'approved':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : <CheckCircleIcon sx={{ fontSize: 16 }} />}
        variant="filled"
        color="info"
        label={label}
      />;
    case 'rejected':
    case 'suspended':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : <WarningIcon sx={{ fontSize: 16 }} />}
        variant="filled"
        color="error"
        label={label}
      />;
    case 'deactivated':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : <WarningIcon sx={{ fontSize: 16 }} />}
        color="error"
        label={label}
      />;
    case 'in_progress':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : <CircleIcon sx={{ fontSize: 16 }} />}
        color="success"
        label={label}
      />;
    case 'resubmitted_docs':
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : null}
        label={label}
      />;
    default:
      return <Chip
        icon={loading ? <CircularProgress size={14} /> : null}
        label={label}
      />;
  }
};