import { useFormatters } from ".";
import moment from 'moment';

const DAYS = ['Sunday', 'Monday', "Tuesday", 'Wednesday', "Thursday", "Friday", "Saturday"];

const useStationSchedule = (station) => {
  const f = useFormatters();
  const today = moment();

  station = station || {};
  let { currentSchedule, regularSchedule, overrideSchedule  } = station;
  currentSchedule = currentSchedule || [];
  regularSchedule = regularSchedule || [];
  overrideSchedule = overrideSchedule || [];

  const findOverrideHours = (date) => {
    return overrideSchedule.find((override) => {
      switch (override.type) {
        case 'weekday':
          return moment(date).day() === override.day;
        case 'date':
          return date === override.date;
        case 'period':
          return moment(date).isSameOrAfter(override.period[0]) && moment(date).isSameOrBefore(override.period[1]);
        default:
          return null;
      }
    });
  };

  const formatPeriod = (item) => {
    const [from, to] = item.period;
    if (from.split('-').reverse()[2] === to.split('-').reverse()[2]) {
      return `${f.date.toLocaleString(from, f.date.DateFormats.DAY_MONTH_MED)} - ${f.date.toLocaleString(to, f.date.DateFormats.DATE_MED)}`
    }
    return `${f.date.toLocaleString(from, f.date.DateFormats.DATE_MED)} - ${f.date.toLocaleString(to, f.date.DateFormats.DATE_MED)}`
  };

  const formatTime = (item) => {
    return `${
      f.date.toLocaleString(moment(item.opensAt, 'HH:mm'), f.date.DateFormats.TIME_SIMPLE)
    } - ${
      f.date.toLocaleString(moment(item.closesAt, 'HH:mm'), f.date.DateFormats.TIME_SIMPLE)
    }`;
  };

  const formatDay = (item) => {
    return DAYS[item.day];
  }

  return {
    today,
    regularSchedule,
    currentSchedule,
    overrideSchedule,
    findOverrideHours,
    formatDay,
    formatPeriod,
    formatTime,
  };
};

export default useStationSchedule;