import React from 'react';
import MuiSwitch from '@mui/material/Switch';
import { useController } from 'react-hook-form';

const ValidatedSwitch = ({ control, rules, name, ...other }) => {
  const { field } = useController({ name, control, rules });

  return <MuiSwitch
    name={name}
    checked={field.value}
    onChange={field.onChange}
    {...other}
  />
};

const Switch = ({ control, ...other}) => {
  if (control) {
    return <ValidatedSwitch control={control} {...other} />
  }

  return <MuiSwitch {...other} />
};

export default Switch;
