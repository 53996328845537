import Box from "@mui/material/Box";

const TabPanel = (props) => {
  const { sx, children, value, id } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== id}
      id={id}
      {...props}
    >
      {value === id && (
        <Box sx={sx}>
          { children }
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
