import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SearchField, Typography, Box, Backdrop } from '../../../ui/core';
import PersonIcon from '../../../ui/icons/PersonIcon';
import { searchUsers } from '../../../redux/Search/action';

const style = {
  item: {
    padding: '0 !important',
    'a': {
      padding: '12px 18px',
    }
  },
  backdrop: {
    top: '84px',
    marginLeft: 0,
  },
};

const UsersSearch = () => {
  const [inputValue, setInputValue] = useState('');
  const [showBackdrop, setShowBackdrop] = useState(false);
  const { data: users, loading } = useSelector((state) => state.search.users);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      searchUsers({ q: inputValue })(dispatch);
    }, 500);
    return () => clearTimeout(timer);
  }, [inputValue, dispatch]);

  return <div>
    <SearchField
      id="users_search"
      sx={{ width: 500 }}
      placeholder="Search"
      value={null}
      inputValue={inputValue}
      options={users}
      getOptionLabel={(driver) => driver.id}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={() => setInputValue(inputValue)}
      renderOption={(props, user) => (<Box component="li" key={user.id} {...props} sx={style.item}>
        <Link to={`/users/${user.id}`}>
          <PersonIcon sx={{ fontSize: 14 }} />
          <Typography component="span" sx={{ marginLeft: "10px" }}>{user.email}</Typography>
          <Typography component="span" color="text.secondary" sx={{ marginLeft: "8px" }}>
            {user.firstName} {user.lastName}
          </Typography>
        </Link>
      </Box>)}
      blurOnSelect
      loading={loading}
      onOpen={() => setShowBackdrop(true)}
      onClose={() => setShowBackdrop(false)}
    />
    <Backdrop open={showBackdrop} sx={style.backdrop} />
  </div>
};

export default UsersSearch;