import { runBackgroundCheck, generateBackgroundCheckReport } from "../../helpers/api/drivers";
import { has } from 'lodash';
import {
  sendApprovalEmailToDriver,
  sendTypeEmailToDriver,
  sendRejectionEmailToDriver,
  sendAwaitingDocsEmailToDriver,
  sendBackgroundCheckEmailToDriver,
  sendWaitlistEmailToDriver,
} from "../Email/actions";
import moment from 'moment';

import api from '../../lib/api';

export const DriverActionsTypes = {
  FETCH_DRIVERS_STARTED: 'FETCH_DRIVERS_STARTED',
  FETCH_DRIVERS_SUCCESS: 'FETCH_DRIVERS_SUCCESS',
  FETCH_DRIVERS_FAILURE: 'FETCH_DRIVERS_FAILURE',
  FETCH_DRIVERS_COUNT_STARTED: 'FETCH_DRIVERS_COUNT_STARTED',
  FETCH_DRIVERS_COUNT_SUCCESS: 'FETCH_DRIVERS_COUNT_SUCCESS',
  FETCH_DRIVERS_COUNT_FAILURE: 'FETCH_DRIVERS_COUNT_FAILURE',
  FETCH_DRIVER_STARTED: 'FETCH_DRIVER_STARTED',
  FETCH_DRIVER_SUCCESS: 'FETCH_DRIVER_SUCCESS',
  FETCH_DRIVER_FAILURE: 'FETCH_DRIVER_FAILURE',
  EDIT_DRIVER_BYID_STARTED: 'EDIT_DRIVER_BYID_STARTED',
  EDIT_DRIVER_BYID_SUCCESS: 'EDIT_DRIVER_BYID_SUCCESS',
  EDIT_DRIVER_BYID_FAILURE: 'EDIT_DRIVER_BYID_FAILURE',
  UPLOAD_IMAGE_STARTED: 'UPLOAD_IMAGE_STARTED',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILURE: 'UPLOAD_IMAGE_FAILURE',
  BACKGROUND_CHECK_STARTED: 'BACKGROUND_CHECK_STARTED',
  BACKGROUND_CHECK_SUCCESS: 'BACKGROUND_CHECK_SUCCESS',
  BACKGROUND_CHECK_FAILURE: 'BACKGROUND_CHECK_FAILURE',
  DRIVER_LOCATION_UPDATE: 'DRIVER_LOCATION_UPDATE',
  RESET_STATE: 'RESET_STATE',
};

export const fetchDrivers = ({ offset = 0, limit = 11, driverApplicationStatus, homeCity } = {}) => async dispatch => {
  dispatch({
    type: DriverActionsTypes.FETCH_DRIVERS_STARTED,
    payload: { offset, limit, driverApplicationStatus, homeCity },
  });
   try {
    const drivers = await api.drivers.getDrivers({ offset, limit, driverApplicationStatus, homeCity });
    dispatch({
      type: DriverActionsTypes.FETCH_DRIVERS_SUCCESS,
      payload: drivers,
    });
   } catch(error) {
    dispatch({
      type: DriverActionsTypes.FETCH_DRIVERS_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    })
   }
};

export const fetchDriversCountByStatus = ({ reset = true } = {}) => async dispatch => {
  try {
    dispatch({
      type: DriverActionsTypes.FETCH_DRIVERS_COUNT_STARTED,
      payload: { reset },
    });
    const data = await api.drivers.getDriversCountByStatus();
    dispatch({
      type: DriverActionsTypes.FETCH_DRIVERS_COUNT_SUCCESS,
      payload: data,
    });
  } catch(e) {
    dispatch({
      type: DriverActionsTypes.FETCH_DRIVERS_COUNT_FAILURE,
    });
  }
};

export const fetchDriverById = (id) => async dispatch => {
  dispatch({
    type: DriverActionsTypes.FETCH_DRIVER_STARTED,
  });
  try {
    const driver = await api.drivers.getDriver(id);
    dispatch({
      type: DriverActionsTypes.FETCH_DRIVER_SUCCESS,
      payload: driver,
    });
   } catch(error) {
    dispatch({
      type: DriverActionsTypes.FETCH_DRIVER_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    })
   }
};

export const editDriver = (driver, update) => async dispatch => {
  try {
    dispatch({
      type: DriverActionsTypes.EDIT_DRIVER_BYID_STARTED,
    });

    if (has(update, 'dob')) {
      update.dob = update.dob ? moment.utc(update.dob).format('YYYY-MM-DD') : null;
    }
    if (has(update, 'licenseExpDate')) {
      update.licenseExpDate = update.licenseExpDate ? moment.utc(update.licenseExpDate).toISOString() : null;
    }
    if (has(update, 'insuranceExpDate')) {
      update.insuranceExpDate = update.insuranceExpDate ? moment.utc(update.insuranceExpDate).toISOString() : null;
    }
    if (has(update, 'vehicles')) {
      update.vehicles = update.vehicles.map((v) => ({
        active: v.active,
        licensePlate: v.licensePlate,
        make: v.make,
        model: v.model,
        type: v.type,
        vin: v.vin === '' ? null : v.vin,
        year: v.year === '' ? null : v.year,
        seatCount: v.seatCount === '' ? null : Number.parseInt(v.seatCount),
        registrationExpDate: v.registrationExpDate ? moment.utc(v.registrationExpDate, 'MM/YYYY').toISOString() : null,
        registrationImageUrl: v.registrationImageUrl || null,
        registrationInvalid: v.registrationInvalid,
      }));
    }
  
    const updatedDriver = await api.drivers.updateDriver(driver.driver_id, update);
    dispatch({
      type: DriverActionsTypes.EDIT_DRIVER_BYID_SUCCESS,
      payload: updatedDriver,
    });
    // send email to driver
    const canSendEmail = updatedDriver.email && !!updatedDriver.driverApplicationStatus;
    const shouldSendEmail = has(update, 'driverApplicationStatus') || has(update, 'isCustomDriver');
    if (canSendEmail && shouldSendEmail) {
      switch (updatedDriver.driverApplicationStatus) {
        case "approved":
          sendApprovalEmailToDriver(updatedDriver.email)(dispatch);
          sendTypeEmailToDriver(updatedDriver.firstName, updatedDriver.email, updatedDriver.isCustomDriver)(dispatch);
          break;
        case "rejected":
          sendRejectionEmailToDriver(updatedDriver.firstName, updatedDriver.email)(dispatch);
          break;
        case "awaiting_docs":
          sendAwaitingDocsEmailToDriver(
            {
              license: updatedDriver.invalidDocuments.includes('license'),
              registration: updatedDriver.invalidDocuments.includes('registration'),
              insurance: updatedDriver.invalidDocuments.includes('insurance'),
            },
            updatedDriver.email
          )(dispatch);
          break;
        case "background_check":
          sendBackgroundCheckEmailToDriver(updatedDriver.email)(dispatch);
          break;
        case "waitlist":
          sendWaitlistEmailToDriver(updatedDriver.email)(dispatch);
          break;
        default:
          break;
      }
    }
    // run background check on driver if needed
    has(update, 'driverApplicationStatus') && backgroundCheck(updatedDriver)(dispatch);

    return { success: true };
  } catch(error) {
    dispatch({
      type: DriverActionsTypes.EDIT_DRIVER_BYID_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    });
    return { success: false };
  }
};

export const decodeVIN = (vin) => async () => {
  try {
    const data =  await api.drivers.decodeVIN(vin);
    return { success: true, data };
  } catch(err) {
    return { success: false };
  }
};

export const getDocumentUploadUrl = (id) => async () => {
  try {
    const data =  await api.drivers.getDocumentUploadUrl(id);
    return { success: true, data };
  } catch(err) {
    return { success: false };
  }
};

export const backgroundCheck = user => async dispatch => {
  try {
    if (user.driverApplicationStatus === 'background_check_invitation' && user.candidate_id) {
      // checkr
      dispatch({
        type: DriverActionsTypes.BACKGROUND_CHECK_STARTED,
      });
      await api.drivers.createScreeningInvitation(user.driver_id);
      dispatch({
        type: DriverActionsTypes.BACKGROUND_CHECK_SUCCESS,
        payload: {}
      });
      return { success: true };
    }
    
    if (user.driverApplicationStatus === 'background_check' && user.check_id) {
      dispatch({
        type: DriverActionsTypes.BACKGROUND_CHECK_STARTED,
      });
      await runBackgroundCheck(user.driver_id);
      dispatch({
        type: DriverActionsTypes.BACKGROUND_CHECK_SUCCESS,
        payload: {}
      });
      return { success: true };
    }

    return { success: true };
  } catch(e) {
    return { success: false };
  }
};