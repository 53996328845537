import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Typography, Box, TextField } from '../../ui/core';
import logo from "../../assets/img/hitch-full-logo.svg";

import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAuth } from '../../hooks';

const Login = () => {
  const auth = useAuth();
  const history = useHistory();

  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
      password: ""
    },
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().required("Email is required").email("Email not valid"),
        password: yup.string().required("Password is required"),
      })
    )
  });
  const { isDirty, isValid, isSubmitting } = useFormState({ control: form.control });

  const onSubmit = (data) => {
    auth.login(data.email, data.password);
  };

  useEffect(() => {
    if (auth.token) {
      history.push("/home");
    }
  }, [auth.token]);

  return <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100vh"
    width="100%"
    padding="48px"
    backgroundColor="#FFF"
  >
    <Box display="flex"justifyContent="space-between" width="100%" height="40px">
      <img alt="Hitch" with="80" height="30" src={logo} />
    </Box>
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      marginTop="100px"
    >
      <Box textAlign="center" width="710px">
        <Typography
          variant="h1"
          fontSize="52px"
          lineHeight="64px"
          fontWeight="700"
        >
          Discovering truths through metrics
        </Typography>
        <Typography
          fontSize="22px"
          lineHeight="1.4"
          color="text.secondary"
          fontWeight="300"
          mt="12px"
        >
          Learn from data to make better decisions and iterate quickly
        </Typography>
      </Box>
      <Box
        component="form"
        onSubmit={form.handleSubmit((data) => onSubmit(data))}
        mt="64px"
        display="flex"
        gap="24px"
      >
        <TextField
          label="Email"
          name="email"
          control={form.control}
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          control={form.control}
        />
        <Button
          type="submit"
          disabled={!(isValid && isDirty)}
          loading={isSubmitting}
          variant="contained"
          sx={{ minWidth: "120px" }}
          disableElevation
        >Log In
        </Button>
      </Box>
    </Box>
  </Box>;
};

export default Login;
