import { getProductCatgories } from "../../helpers/api/products";

export const ProductsActions = {
  FETCH_PRODUCT_CATEGORIES_STARTED: 'FETCH_PRODUCT_CATEGORIES_STARTED',
  FETCH_PRODUCT_CATEGORIES_SUCCESS: 'FETCH_PRODUCT_CATEGORIES_SUCCESS',
  FETCH_PRODUCT_CATEGORIES_FAILURE: 'FETCH_PRODUCT_CATEGORIES_FAILURE',
};

export const fetchProductCategories = (type) => dispatch => {
  dispatch({
    type: ProductsActions.FETCH_PRODUCT_CATEGORIES_STARTED,
  });
  getProductCatgories()
    .then(response => {
      dispatch({
        type: ProductsActions.FETCH_PRODUCT_CATEGORIES_SUCCESS,
        payload: response.data
      });
    })
    .catch(error => dispatch({
      type: ProductsActions.FETCH_PRODUCT_CATEGORIES_FAILURE,
      payload: error && error.response ? error.response.data.error : error.message,
    }));
}
