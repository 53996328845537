import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const StationIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 16 16">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 7.05488C2 3.74117 4.68629 1 8 1C11.3137 1 14 3.74117 14 7.05488C14 9.64517 12.3586 11.8856 10.0591 12.7404C9.89614 12.801 9.75347 12.9083 9.65937 13.0545L8.63066 14.6528C8.33538 15.1116 7.66462 15.1116 7.36934 14.6528L6.34063 13.0545C6.24653 12.9083 6.10386 12.801 5.94088 12.7404C3.64143 11.8856 2 9.64517 2 7.05488ZM8 5.54116C8.82843 5.54116 9.5 6.22645 9.5 7.05488C9.5 7.88331 8.82843 8.5686 8 8.5686C7.17157 8.5686 6.5 7.88331 6.5 7.05488C6.5 6.22645 7.17157 5.54116 8 5.54116Z" />
  </SvgIcon>
};

export default StationIcon;
