import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const OpenFullscreenIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 48 48">
   <path d="M6 42V27h3v9.9L36.9 9H27V6h15v15h-3v-9.9L11.1 39H21v3Z"/>
  </SvgIcon>
};

export default OpenFullscreenIcon;
