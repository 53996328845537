import DataGrid from './DataGrid';
import GroupedDataGrid from './GroupedDataGrid';

export default function({ columns, ...other }) {
  const visibleColumns = columns.filter((c) => !c.hide);
  const groupingColumn = columns.find((c) => !!c.groupBy);

  if (groupingColumn) {
    return <GroupedDataGrid columns={visibleColumns} groupingColumn={groupingColumn} {...other} />
  }
  return <DataGrid columns={visibleColumns} {...other} />
};