import { useMemo } from "react";
import { Autocomplete, Checkbox, Chip, Typography } from "../../../ui/core";

const CitiesSelector = ({ options = [], value = [], onChange }) => {

  const sortedOptions = useMemo(() => {
    return [...options].sort((c1, c2) => {
      if (c1.stateAbbr === c2.stateAbbr) {
        return c1.cityName < c2.cityName ? -1 : 1;
      }
      return c1.stateAbbr < c2.stateAbbr ? -1 : 1
    });
  }, [options])

  return <Autocomplete
    id="cityFilter"
    multiple
    label="Cities"
    value={value}
    sx={{
      minWidth: '300px',
      '.MuiAutocomplete-input': { minWidth: '100px !important'},
      '.MuiAutocomplete-inputRoot': { flexWrap: 'nowrap !important'}
    }}
    disablePortal
    getOptionLabel={(c) => `${c.cityName}, ${c.stateAbbr}`}
    groupBy={(c) => c.stateAbbr}
    options={sortedOptions}
    onChange={(e, v) => onChange(v)}
    renderOption={(props, option, { selected }) => (
      <li {...props}>
        <Checkbox
          style={{ marginRight: 8, height: 32, width: 32 }}
          checked={selected}
        />
        {option.cityName}, {option.stateAbbr}
      </li>
    )}
    disableCloseOnSelect
    freeSolo
    renderTags={(value, getTagProps) => <>
      {
        value.slice(0, 2).map((option, index) => (
          <Chip label={`${option.cityName}, ${option.stateAbbr}`} {...getTagProps({ index })} />
        ))
      }
      {
        value.length > 2 && <Typography fontStyle="italic">+{value.slice(2).length} more</Typography>
      }
    </>}
  />
}

export default CitiesSelector;
