import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const StarIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 16 16">
   <path d="M14.5475 5.9298L10.3506 5.29107L8.47444 1.30812C8.4232 1.19907 8.3389 1.11079 8.23476 1.05713C7.97359 0.922113 7.65621 1.03463 7.52563 1.30812L5.64949 5.29107L1.45256 5.9298C1.33685 5.94711 1.23106 6.00423 1.15007 6.09078C1.05215 6.19617 0.998187 6.33796 1.00005 6.48499C1.00191 6.63202 1.05943 6.77227 1.15998 6.87491L4.19652 9.97507L3.47912 14.3527C3.4623 14.4545 3.47306 14.5592 3.51018 14.655C3.54731 14.7508 3.60931 14.8337 3.68916 14.8944C3.76901 14.9552 3.86352 14.9912 3.96196 14.9986C4.0604 15.0059 4.15883 14.9843 4.24611 14.936L8.00003 12.8692L11.754 14.936C11.8564 14.9931 11.9755 15.0122 12.0895 14.9914C12.3771 14.9395 12.5705 14.6539 12.5209 14.3527L11.8036 9.97507L14.8401 6.87491C14.9227 6.79009 14.9773 6.67931 14.9938 6.55814C15.0384 6.25522 14.8368 5.9748 14.5475 5.9298Z" />
  </SvgIcon>
};

export default StarIcon;
