
import { createTheme, lighten } from '@mui/material/styles';
import { scrollX, scrollY } from './scrollbars';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#252525',
    },
    white: {
      main: '#fff',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#D8364F',
      // light: lighten('#D8364F', 0.8)
    },
    success: {
      main: '#20A766',
    },
    warning: {
      main: '#BE8900',
    },
    accent: {
      main: '#2075CE',
    },
    spacer: {
      main: '#EAEAEA',
    },
    cancelled: {
      main: '#686868',
      contrastText: '#252525',
    },
    divider: '#dbdbdb',
    text: {
      primary: '#252525',
      secondary: '#686868',
      inactive: '#ACACAC',
    },
    background: {
      paper: '#FFFFFF',
      default: '#F2F2F2',
    },
    brand: {
      saltPonds:'#FF6837',
    }
  },
  typography: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    h1: {
      fontSize: '22px',
      lineHeight: '32px',
      fontWeight: 600,
    },
    h2: {
      fontSize: '20px',
      lineHeight: '32px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 700,
    },
    body1: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '24px',
    },
    pageAvatar: {
      fontSize: '22px',
      lineHeight: '16px',
      fontWeight:  700,
    },
    subtitle1: {
      fontSize: '22px',
      lineHeight: '32px',
      fontWeight: 600,
    },
    small: {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight:  500,
    }
  },
  components: {
    MuiSvgIcon: {
      defaultProps: {
        // color: 'primary',
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'text' },
          style: {

          },
        }
      ],
      defaultProps: {
        variant: 'text',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          const style = {
            padding: '4px 8px',
            borderRadius: 4,
            cursor: 'inherit',
          };
          if (ownerState.variant === 'text') {
            style.backgroundColor = theme.palette[ownerState.color] ? lighten(theme.palette[ownerState.color].main, 0.8) : '#eee';
          }
          return style;
        },
        icon: ({ ownerState, theme }) => {
          const style = { marginLeft: 0, marginRight: 6 };
          if (ownerState.variant === 'text') {
            style.color = theme.palette[ownerState.color] ? theme.palette[ownerState.color].main : theme.palette.text.primary;
          }
          return style;
        },
        label: ({ ownerState, theme }) => {
          const style = { fontWeight: 500, paddingLeft: 0, paddingRight: 0 };
          if (ownerState.variant === 'text') {
            style.color = theme.palette[ownerState.color] ? theme.palette[ownerState.color].main : theme.palette.text.primary;
          }
          return style;
        },
        deleteIcon: () => ({
          marginLeft: 5,
        })
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 32,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme}) => ({
          textTransform: 'none',
          padding: 4,
          fontSize: 16,
          lineHeight: 1.5,
          fontWeight: 500,
          minHeight: 32,
          color: theme.palette.text.inactive,
          '&:not(:last-child)': {
            marginRight: 32,
          }
        }),
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
          height: 16,
          width: 26,
        },
        switchBase: ({ ownerState }) => ({
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(10px)',
            '+ .MuiSwitch-track': {
              opacity: 1,
            }
          },
        }),
        thumb: ({ ownerState }) => ({
          height: 12,
          width: 12,
          backgroundColor: "#fff",
        }),
        input: {
          left: 0,
          width: '200%',
        },
        track: {
          backgroundColor: '#DBDBDB',
          opacity: 1,
          borderRadius: 20,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: `1px solid ${theme.palette.spacer.main}`,
        }),
        virtualScroller: {
          ...scrollX,
        },
        columnSeparator: {
          display: 'none',
        },
        columnHeaders: ({ theme}) => ({
          borderBottom: `1px solid ${theme.palette.spacer.main}`,
        }),
        columnHeader: {
          padding: '0 32px',
        },
        menuIcon: {
          display: 'none',
        },
        columnHeaderTitle: ({ theme}) => ({
          color: theme.palette.text.secondary,
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 500,
        }),
        cell: ({ theme}) => ({
          padding: '0 32px',
          borderBottom: `1px solid ${theme.palette.spacer.main}`,
        }),
        cellContent: ({ theme }) => ({
          color: theme.palette.text.primary,
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 500,
        }),
        footerContainer: {
          border: 'none',
        },
      },
    },
    MuiPagination: {
      defaultProps: {
        color: 'spacer',
        shape: 'rounded',
        siblingCount: 3,
        boundaryCount: 1,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: { 
          padding: '12px 16px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: { 
          padding: '0px 9px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
        disableAnimation: true,
      }
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          fontSize: 14,
          lineHeight: '32px',
          marginLeft: '8px',
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        defaultExpanded: true,
      },
      styleOverrides: {
        root: {
          '&:before': {
            opacity: 0,
          }
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        content: {
          margin: '24px 0 24px 0',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          ...scrollY,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.05)',
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingTop: '18px',
          paddingBottom: '18px',
        },
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: 'none',
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standard: ({ ownerState, theme }) => {
          const color = theme.palette[ownerState.color || ownerState.severity].main;
          const style = {
            backgroundColor: lighten(color, 0.8)
          };
          return style;
        },
      }
    }
  },
});

export default theme;