import { UsersActionsTypes as types } from './actions'

const initialState = {
  all: { data: [], count: 0, loading: true },
  count: null,
  selection: {
    user: null,
    riderCashFlow: null,
    driverCashFlow: null,
    paymentSources: null,
    sessions: null,
    driverScore: null,
    rideHistory: null,
    driveHistory: null,
    promotions: null,
    availablePromotions: null,
  },
  averageDriverScores: null,
  isPending: false,
  currentLocations: {},
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        isPending: false,
      }
    case types.FETCH_USERS_STARTED:
      return {
        ...state,
        all: { ...state.all, loading: true },
        isPending: true,
      }
    case types.FETCH_USERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isPending: false,
      }
    case types.FETCH_CURRENT_LOCATION_SUCCESS:
      return {
        ...state,
        currentLocations: {
          ...state.currentLocations,
          [action.payload.userId]: action.payload.location,
        },
      }
    case types.FETCH_CURRENT_LOCATION_FAILURE:
      return {
        ...state,
        currentLocations: {
          ...state.currentLocations,
          [action.payload.userId]: null,
        },
      }
    case types.FETCH_USERS_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload,
        isPending: false,
      }
    case types.FETCH_USER_BYID_STARTED:
      return {
        ...state,
        selection: {},
      }
    case types.FETCH_USER_BYID_SUCCESS:
      return {
        ...state,
        selection: { user: action.payload },
      }
    case types.RELOAD_USER_SUCCESS:
      return {
        ...state,
        selection: { ...state.selection, user: action.payload },
      }
    case types.EDIT_USER_BYID_SUCCESS:
      return {
        ...state,
        selection: { ...state.selection, user: action.payload },
      }
    case types.FETCH_RIDER_CASH_FLOW_SUCCESS:
      return {
        ...state,
        selection: {
          ...state.selection,
          riderCashFlow: action.payload || {},
        },
      }
    case types.FETCH_DRIVER_CASH_FLOW_SUCCESS:
      return {
        ...state,
        selection: {
          ...state.selection,
          driverCashFlow: action.payload || {},
        },
      }
    case types.FETCH_PAYMENT_SOURCES_SUCCESS:
      return {
        ...state,
        selection: {
          ...state.selection,
          paymentSources: action.payload || [],
        },
      }
    case types.FETCH_SESSIONS_SUCCESS:
      return {
        ...state,
        selection: {
          ...state.selection,
          sessions: action.payload || [],
        },
      }
    case types.FETCH_DRIVER_SCORE_SUCCESS:
      return {
        ...state,
        selection: {
          ...state.selection,
          driverScore: action.payload,
        },
      }
    case types.FETCH_AVERAGE_DRIVER_SCORES_SUCCESS:
      return {
        ...state,
        averageDriverScores: action.payload,
      }
    case types.FETCH_RIDE_HISTORY_SUCCESS:
      return {
        ...state,
        selection: {
          ...state.selection,
          rideHistory: action.payload || [],
        },
      }
    case types.REFUND_RIDE_SUCCESS:
      const ride = action.payload
      return {
        ...state,
        selection: {
          ...state.selection,
          rideHistory: {
            ...state.selection.rideHistory,
            data: state.selection.rideHistory.data.map((r) => {
              if (r.id === ride.id) {
                return { ...r, ...ride }
              }
              return r
            }),
          },
        },
      }
    case types.FETCH_DRIVE_HISTORY_SUCCESS:
      return {
        ...state,
        selection: {
          ...state.selection,
          driveHistory: action.payload || [],
        },
      }
    case types.FETCH_PROMOTIONS_SUCCESS:
      return {
        ...state,
        selection: {
          ...state.selection,
          promotions: action.payload || [],
        },
      }
    case types.FETCH_AVAILABLE_PROMOTIONS_SUCCESS:
      return {
        ...state,
        selection: {
          ...state.selection,
          availablePromotions: action.payload || [],
        },
      }
    case types.FETCH_BLACKLISTED_DATES_SUCCESS:
      return {
        ...state,
        selection: {
          ...state.selection,
          user: {
            ...state.selection.user,
            blacklistedDates: action.payload || [],
          },
        },
      }
    case types.FETCH_BLACKLISTED_TIMES_SUCCESS:
      return {
        ...state,
        selection: {
          ...state.selection,
          user: {
            ...state.selection.user,
            blacklistedTimes: action.payload || [],
          },
        },
      }
    case types.RESET_ERROR:
      return {
        ...state,
        error: null,
      }
    case types.RESET_STATE:
      return initialState
    default:
      return state
  }
}
