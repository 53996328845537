import { useEffect } from 'react';
import {
  Stack,
  Box,
  Typography,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from '../../../ui/core';
import { ExpandMoreIcon } from '../../../ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatters } from '../../../hooks';
import { getDriverScore, getAverageDriverScores } from '../../../redux/Users/actions';

const ScoreItem = ({ title, value, average, loading }) => {
  const f = useFormatters();

  return <Box display="flex" alignItems="center" justifyContent="space-between">
    <Box>
      <Typography>{ title }</Typography>
      <Typography color="text.secondary" display="flex">
        The average driver has a {
          average === null ? <Skeleton variant="rectangular" height={24} width={24} sx={{ margin: "0 5px"}} /> : f.number.decimal(average)
        } score
      </Typography>
    </Box>
    {
      loading || value === null || value === undefined ?
        <Skeleton variant="rectangular" height={32} width={60} /> :
        <Typography variant="subtitle1">{f.number.decimal(value)} / 5</Typography>
    }
  </Box>;
};

const DriverScoreCard = ({ user }) => {
  const dispatch = useDispatch();
  const averages = useSelector((state) => state.users.averageDriverScores);
  const data = useSelector((state) => state.users.selection.driverScore);
  const f = useFormatters();

  useEffect(() => {
    getDriverScore(user.id)(dispatch);
    if (!averages) {
      getAverageDriverScores()(dispatch);
    }
  }, []);

  return (<Accordion variant="outlined" sx={{ padding: "0 24px"}}>
   <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
      <Typography variant="subtitle1" mr="12px">Driver Score</Typography>
      { !data && <Skeleton variant="rectangular" height={32} width={32} /> }
      { data && <Chip label={f.number.decimal(data.totalScore)} color="success" /> }
   </AccordionSummary>
   <AccordionDetails sx={{ padding: '0 0 24px 0' }}>
    <Stack spacing="12px">
      <ScoreItem
        key="conversation"
        title="Conversation"
        value={data ? data.conversationScore : null }
        average={averages ? averages.conversationScore : null}
        loading={!data}
      />
      <ScoreItem
        key="cleanliness"
        title="Cleanliness"
        value={data ? data.cleanlinessScore : null }
        average={averages ? averages.cleanlinessScore : null}
        loading={!data}
      />
      <ScoreItem
        key="driving"
        title="Driving"
        value={data ? data.drivingQualityScore : null }
        average={averages ? averages.drivingQualityScore : null}
        loading={!data}
      />
      <ScoreItem
        key="car"
        title="Car Quality"
        value={data ? data.carQualityScore : null }
        average={averages ? averages.carQualityScore : null}
        loading={!data}
      />
    </Stack>
   </AccordionDetails>
  </Accordion>)
};

export default DriverScoreCard;