import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Switch, CircularProgress } from '../../../ui/core';
import { updateStation } from '../../../redux/Stations/actions';

const StationOperationalField = ({ station }) => {
  const { operational } = station;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onChange = async (e) => {
    setIsSubmitting(true);
    await updateStation(station.id, { operational: e.target.checked })(dispatch);
    setIsSubmitting(false);
  };

  return <Box display="flex" alignItems="center" height="32px" width="52px">
    <Switch
      sx={{ transform: "scale(2)" }}
      checked={!!operational}
      onChange={onChange}
    />
    { isSubmitting && <CircularProgress size={16} sx={{ ml: "24px" }} /> }
  </Box>
};

export default StationOperationalField;
