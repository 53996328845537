import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ChevronDownIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="m12 8-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"/>
  </SvgIcon>
};

export default ChevronDownIcon;
