import React, { useEffect } from 'react';
import { Dialog, DialogContent, Stack, IconButton, Box, Typography, Divider, Slide, Grid, Button } from '../../../ui/core';
import { ContentCopyIcon, CloseFullscreenIcon, OpenFullscreenIcon, StationIcon, CloseIcon } from '../../../ui/icons';

import { useCopyToClipboard, useToast } from '../../../hooks';

import PartnerTypeField from './PartnerTypeField';
import StationOperationalField from './StationOperationalField';
import StationAmenitiesField from './StationAmenitiesField';
import StationSchedule from './StationSchedule';
import StationScheduleOverride from './StationScheduleOverride';
import StationMap from './StationMap';
import EditStationAddress from './EditStationAddress';
import EditStationLocation from './EditStationLocation';

const style = {
  dataList: {
    display: 'grid',
    gridTemplateColumns: '124px 1fr',
    rowGap: '8px',
    margin: 0,
    '> dt': {
      gridColumn: 1,
      height: '48px',
      display: 'flex',
      alignItems: 'center',
    },
    '> dd': {
      gridColumn: 2,
      minHeight: '48px',
      display: 'flex',
      alignItems: 'center',
    }
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" timeout={900} ref={ref} {...props} />;
});

const StationDetailsDialog = ({ station, fullScreen, onClose, onFullscreen }) => {
  const c = useCopyToClipboard();
  const t = useToast();

  const copyIdToClipboard = () => {
    c.copy(station.id);
    t.success("Station id copied to clipboard");
  };

  return (<Dialog
    sx={{ left: 'auto', width: fullScreen ? '100%' : '60%', minWidth: '840px' }}
    fullScreen
    open={!!station}
    TransitionComponent={Transition}
    onClose={onClose}
  >
    <Stack
      position="relative"
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      spacing="16px"
      py="6px"
      px="48px"
      minHeight="56px"
    >
      <IconButton
        sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '8px' }}
        onClick={onClose}
      >
        <CloseIcon sx={{ color: "text.secondary" }} />
      </IconButton>
      {
        station && <>
          <Box display="flex" alignItems="center">
            <StationIcon sx={{ fontSize: '16px' }} />
            <Typography ml="5px" mr="10px">Station {station.id}</Typography>
           <IconButton size="small" onClick={copyIdToClipboard}>
            <ContentCopyIcon sx={{ fontSize: "16px" }} />
           </IconButton>
          </Box>
        </>
      }
      <IconButton
        sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '8px' }}
        onClick={() => onFullscreen(!fullScreen)}
      >
        {
          fullScreen ?
          <CloseFullscreenIcon sx={{ fontSize: '24px' }} /> :
          <OpenFullscreenIcon sx={{ fontSize: '24px' }} />
        }
      </IconButton>
    </Stack>
    <Divider />
    <DialogContent sx={{ padding: '32px 40px' }}>
      <Stack spacing="32px">
        <Typography fontSize="28px">{station && station.name}</Typography>
        <Box component="dl" direction="row" sx={style.dataList}>
          <dt><Typography color="text.secondary">Address</Typography></dt>
          <dd>
            { station && station.address }
            { station && <EditStationAddress station={station} />}
          </dd>
          <dt><Typography color="text.secondary">City</Typography></dt>
          <dd>{station && station.city ?   `${station.city.cityName}, ${station.city.stateAbbr}` : ''}</dd>
          <dt><Typography color="text.secondary">Type</Typography></dt>
          <dd style={{ marginLeft: "-8px"}}>
            {station && <PartnerTypeField station={station} />}
          </dd>
          <dt><Typography color="text.secondary">Operational</Typography></dt>
          <dd style={{ marginLeft: "16px"}}>
            {station && <StationOperationalField station={station} />}
          </dd>
          <dt><Typography color="text.secondary">Amenities</Typography></dt>
          <dd>{station && <StationAmenitiesField station={station} />}</dd>
        </Box>
        <Divider />
        <Stack spacing="24px">
          <Typography variant="h2">Availability</Typography>
          {
            station && <StationScheduleOverride station={station} />
          }
          {
            station && <StationSchedule station={station} />
          }
        </Stack>
        <Divider />
        <Stack spacing="24px">
          <Box display="flex" alignItems="center">
            <Typography variant="h2" mr="6px">Location</Typography>
            { station && <EditStationLocation station={station} /> }
          </Box>
          { station && <StationMap station={station} /> }
        </Stack>
      </Stack>
    </DialogContent>
  </Dialog>);
};

export default StationDetailsDialog;