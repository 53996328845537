import React from 'react';
import moment from "moment-timezone";
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import store from './redux/store';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './lib/api/client';
import PrivateRoute from "./components/shared/PrivateRoute";

import Login from "./pages/Login";
import Home from "./pages/Home";
import Trips from "./pages/Trips";
import Users from "./pages/Users";
import DriversApproval from "./pages/DriversApproval";
import Stations from "./pages/Stations";

import { ThemeProvider, theme } from './ui/styles';
import { ToastProvider } from './hooks/useToast';
import { CssBaseline } from './ui/core';

function App() {
  return (
    <Provider store={store}>
     <ThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <ToastProvider>
            <CssBaseline /> 
            <Router>
              <Switch>
                <Route exact path="/login" component={Login}/>
                <PrivateRoute path="/home" component={Home}/>
                <PrivateRoute path="/users" component={Users}/>
                <PrivateRoute path="/trips" component={Trips}/>
                <PrivateRoute path="/drivers-approval" component={DriversApproval}/>
                <PrivateRoute path="/stations" component={Stations}/>
                <Redirect to="/home" />
              </Switch>
            </Router>
          </ToastProvider>
        </ApolloProvider>
     </ThemeProvider>
    </Provider>
  );
}

export default App;
