import React from 'react';
import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useController } from 'react-hook-form';

const ValidatedAutocomplete = ({ name, control, rules, label, placeholder, ...other }) => {
  const { field } = useController({ name, control, rules });

  return <MuiAutocomplete
    renderInput={(params) => <TextField
      {...params}
      InputLabelProps={{ shrink: true }}
      label={label}
      placeholder={placeholder}
    />}
    {...other}
    value={field.value}
    onChange={field.onChange}
  />

};

const Autocomplete = ({ control, label, placeholder, ...other}) => {

  if (control) {
    return <ValidatedAutocomplete
      control={control}
      label={label}
      placeholder={placeholder}
      {...other}
    />
  }
  return <MuiAutocomplete
    renderInput={(params) => <TextField
      {...params}
      InputLabelProps={{ shrink: true }}
      label={label}
      placeholder={placeholder}
    />}
    {...other}
  />
};

export default Autocomplete;