import client from './client';
import { gql } from '@apollo/client';

const STATION_QUERY = `
id
name
address
type
timeZone
placeId
partnerType
operational
city {
  id
  cityName
  stateName
  stateAbbr
}
regularSchedule {
  day
  opensAt
  closesAt
}
currentSchedule {
  date
  day
  opensAt
  closesAt
}
overrideSchedule {
  type
  isOpen
  date
  period
  day
  opensAt
  closesAt
}
coord {
  type
  coordinates
}
amenities {
  id
  name
  iconUrl
}
`

const getStations = async ({ city, offset = 0, limit = 10 } = {}) => {
  const data = await client.query(
    gql`
      query stations($offset: Int!, $limit: Int!, $city: String) {
        stations(offset: $offset, limit: $limit, city: $city) {
          data {
            ${STATION_QUERY}
          }
          count
        }
      }
    `,
    { city, offset, limit },
  );
  return data.stations;
};

const getStation = async (id) => {
  const data = await client.query(
    gql`
      query station($id: String!) {
        station(id: $id) {
          ${STATION_QUERY}
        }
      }
    `,
    { id },
  );
  return data.station;
};

const getAmenities = async () => {
  const data = await client.query(
    gql`
      query amenities {
        amenities {
          id
          name
          iconUrl
        }
      }
    `,
  );
  return data.amenities;
};

const addScheduleOverride = async (id, override) => {
  const data = await client.query(
    gql`
      mutation addScheduleOverride($id: String!, $override: StationScheduleUpdateInput!) {
        addScheduleOverride(id: $id, override: $override) {
          ${STATION_QUERY}
        }
      }
    `,
    { id, override },
  );
  return data.addScheduleOverride;
};

const removeScheduleOverride = async (id, override) => {
  override = { ...override };
  if (!override.date) {
    delete override.date;
  }
  if (!override.period) {
    delete override.period;
  }

  delete override.__typename;

  const data = await client.query(
    gql`
      mutation removeScheduleOverride($id: String!, $override: StationScheduleUpdateInput!) {
        removeScheduleOverride(id: $id, override: $override) {
          ${STATION_QUERY}
        }
      }
    `,
    { id, override },
  );
  return data.removeScheduleOverride;
};

const updateStation = async (id, input) => {
  const data = await client.query(
    gql`
      mutation updateStation($id: String!, $input: StationUpdateInput!) {
        updateStation(id: $id, input: $input) {
          ${STATION_QUERY}
        }
      }
    `,
    { id, input },
  );
  return data.updateStation;
};


const createStation = async ({ input }) => {
  const data = await client.query(
    gql`
      mutation createStation($input: StationCreateInput!) {
        createStation(input: $input) {
          ${STATION_QUERY}
        }
      }
    `,
    { input },
  );
  return data.createStation;
};

export default {
  getStations,
  getStation,
  updateStation,
  addScheduleOverride,
  removeScheduleOverride,
  getAmenities,
  createStation,
};