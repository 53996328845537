import { useSelector } from "react-redux";
import { Typography, Tooltip } from "../../../ui/core";
import { useFormatters } from "../../../hooks";
import { getTripCurrentETA } from "../../../models/Trips";
import { TripStatus } from "../../../models/Trips";

const TripETA = ({ trip }) => {
  const f = useFormatters();
  const tripsEta = useSelector((state) => state.trips.tripsETA);

  const isInProgress = trip.status === TripStatus.in_progress;
  const eta = isInProgress ? getTripCurrentETA(trip, tripsEta[trip.id]) : null;

  const display = eta
    ? f.date.toTimezoneLocaleString(
        eta,
        trip.originCity.timeZone,
        f.date.DateFormats.TIME_SIMPLE_WITH_TIMEZONE,
      )
    : "";

  const lastUpdated = tripsEta[trip.id]?.updatedAt
    ? f.date.toTimezoneLocaleString(
        tripsEta[trip.id]?.updatedAt,
        trip.originCity.timeZone,
        f.date.DateFormats.DATETIME_MED_WITH_SEC,
      )
    : "N/A";

  const tooltipContent = `Last updated at: ${lastUpdated}`;

  return (
    <Tooltip
      title={tooltipContent}
      arrow
      enterDelay={500}
      leaveDelay={0}
      TransitionProps={{ timeout: 100 }}
    >
      <Typography
        sx={{
          textDecoration: "underline",
          textDecorationStyle: "dotted",
          cursor: "help",
        }}
      >
        {display}
      </Typography>
    </Tooltip>
  );
};

export default TripETA;
