import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { configurePusher } from 'pusher-redux';
import apiBase from '../helpers/api/hitchEndPointBase';
import config from '../config';

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

const options = {
	authEndpoint: `${apiBase}/location/pusher/auth`,
	cluster: 'us2'
}

configurePusher(store, config.pusher.key, options);

export default store;
