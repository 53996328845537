import CancelIcon from '../../../ui/icons/CancelIcon';
import ErrorIcon from '../../../ui/icons/ErrorIcon';
import SearchCircleIcon from '../../../ui/icons/SearchCircleIcon';
import CheckCircleIcon from '../../../ui/icons/CheckCircleIcon';
import CarCircleIcon from '../../../ui/icons/CarCircleIcon';
import CircleIcon from '../../../ui/icons/CircleIcon';

import { TripDisplayStatus } from '../../../models/Trips';

export const TripDisplayStatusIcon = ({ status }) => {
  switch (status) {
    case TripDisplayStatus.cancelled:
      return <CancelIcon sx={{ fontSize: 20 }} />
    case TripDisplayStatus.completed:
      return <CheckCircleIcon color="info" sx={{ fontSize: 20 }} />;
    case TripDisplayStatus.needs_atention:
      return <ErrorIcon color="error" sx={{ fontSize: 20 }} />;
    case TripDisplayStatus.finding_driver:
      return <SearchCircleIcon color="warning" sx={{ fontSize: 20 }} />;
    case TripDisplayStatus.in_progress:
      return <CarCircleIcon color="success" sx={{ fontSize: 20 }} />;
    case TripDisplayStatus.finalizing_details:
      return <CircleIcon color="warning" sx={{ fontSize: 20 }} />;
    default:
      return null;
  }
}