import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DriveIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 16 16">
    <path fillRule="evenodd" clipRule="evenodd" d="M1 8C1 4.13827 4.13827 1 8 1C11.8617 1 15 4.13827 15 8C15 11.8617 11.8617 15 8 15C4.13827 15 1 11.8617 1 8ZM3.12261 8.10431C5.35345 8.55771 7.15048 10.4867 7.24124 12.8198C4.93798 12.4601 3.17274 10.489 3.12261 8.10431ZM3.54662 6.00972C4.31008 4.30951 6.0198 3.12151 8 3.12151C9.97644 3.12151 11.6834 4.305 12.449 6.00004L3.54662 6.00972ZM12.8779 8.07573C12.8405 10.5309 10.9851 12.554 8.59097 12.8431C8.67093 10.4481 10.5714 8.47091 12.8779 8.07573Z" />
  </SvgIcon>
};

export default DriveIcon;
