import { useEffect, useState } from 'react';
import {
  Stack,
  Box,
  Typography,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '../../../ui/core';
import { scrollY } from '../../../ui/styles';
import { ExpandMoreIcon, LogoutIcon } from '../../../ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatters, useToast } from '../../../hooks';
import { getSessions, revokeSession } from '../../../redux/Users/actions';

const SessionItem = ({ session, waiting, onRevoke }) => {
  const f = useFormatters();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setShowConfirmDialog(true);
  };

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false);
  };

  const onRevokeConfirmed = () => {
    setShowConfirmDialog(false);
    onRevoke(session);
  };

  return <>
    <ListItem sx={{ display: "flex", alignItems: "flex-start", gap: "16px", justifyContent: "space-between" }}>
      <Stack spacing="2px">
        <Typography>{session.id}</Typography>
        <Typography>
          <Typography variant="small" component="span" color="text.secondary">Started: </Typography>
          <Typography variant="small" component="span">{f.date.toLocaleString(session.startedAt, f.date.DateFormats.DATETIME_MED)}</Typography>
        </Typography>
        <Typography>
          <Typography variant="small" component="span" color="text.secondary">Last Access: </Typography>
          <Typography variant="small" component="span">{f.date.toLocaleString(session.lastAccessedAt, f.date.DateFormats.DATETIME_MED)}</Typography>
        </Typography>
        <Typography>
          <Typography variant="small" component="span" color="text.secondary">Expires: </Typography>
          <Typography variant="small" component="span">{f.date.toLocaleString(session.expiresAt, f.date.DateFormats.DATETIME_MED)}</Typography>
        </Typography>
      </Stack>
      <IconButton
        disabled={waiting}
        onClick={openConfirmDialog}
      >
        { waiting && <CircularProgress size={16} color="inherit" /> }
        { !waiting && <LogoutIcon /> }
      </IconButton>
    </ListItem>
    <Dialog open={showConfirmDialog} onClose={closeConfirmDialog}>
      <DialogContent sx={{ maxWidth: "400px" }}>
        <Typography>Are you sure you want to logout</Typography>
        <Typography sx={{ fontWeight: 700 }} component="span">{session.id}</Typography>?
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={closeConfirmDialog}>Cancel</Button>
        <Button onClick={onRevokeConfirmed}>Confirm</Button>
      </DialogActions>
    </Dialog>
  </>
};

const SessionsCard = ({ user }) => {
  const dispatch = useDispatch();
  const t = useToast();
  const [waitingFor, setWaitingFor] = useState(null);
  const data = useSelector((state) => state.users.selection.sessions);

  useEffect(() => {
    getSessions(user.id)(dispatch);
  }, []);

  const doRevokeSession = async (session) => {
    setWaitingFor(session.id);
    const { success } = await revokeSession(user.id, session.id)(dispatch);
    success ?
      t.success(`Session has been revoked`) :
      t.error(`Something went wrong`);
    setWaitingFor(null);
  };

  return (<Accordion variant="outlined" sx={{ padding: "0 24px"}}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
        <Typography variant="subtitle1">Sessions</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ padding: '0 0 24px 0' }}>
      <Stack spacing="4px">
        {
          !data && <>
            <Skeleton variant="rectangular" height={48} />
            <Skeleton variant="rectangular" height={48} />
          </>
        }
        {
          data && <List
            sx={{
              maxHeight: "300px",
              ...scrollY,
            }}
          >
            {
              data
                .sort((d1, d2) => d1.startedAt < d2.startedAt ? 1 : -1)
                .map((s) => (<SessionItem
                  key={s.id}
                  session={s}
                  user={user}
                  waiting={waitingFor === s.id}
                  onRevoke={doRevokeSession}
                />))
            }
          </List>
        }
      </Stack>
    </AccordionDetails>
    </Accordion>)
};

export default SessionsCard;