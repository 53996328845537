import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {uniqueId}  from 'lodash';
import {
  Stack,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DatePicker,
  TimeField,
  Autocomplete,
  Switch,
  FormControlLabel,
  IconButton,
  CircularProgress,
  Tabs,
  Tab,
  TabPanel,
} from '../../../ui/core';
import { DeleteIcon } from '../../../ui/icons';
import { useFormatters, useStationSchedule } from '../../../hooks';
import { addStationScheduleOverride, removeStationScheduleOverride } from '../../../redux/Stations/actions';

const AddDateOverride = ({ station, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [date, setDate] = useState(null);
  const [openAt, setOpenAt] = useState(null);
  const [closedAt, setClosedAt] = useState(null);
  const dispatch = useDispatch();

  const isValid = date && date.isValid() &&
    (
      !isOpen ||
      isOpen && openAt && openAt.isValid() && closedAt && closedAt.isValid()
    );

  const onConfirm = async () => {
    let override = {
      type: 'date',
      isOpen,
      date: date.format("YYYY-MM-DD")
    };
    if (isOpen) {
      override = {
        ...override,
        opensAt: openAt.format("HH:mm"),
        closesAt: closedAt.format("HH:mm"),
      };
    }
    const { success } = await addStationScheduleOverride(station.id, override)(dispatch);
    success && onClose();
  };

  return <Stack spacing="24px">
    <Stack direction="row" spacing="8px" alignItems="center">
      <DatePicker
        fullWidth
        label="Date"
        value={date}
        moment
        onChange={(v) => setDate(v)}
      />
    </Stack>
    <Box ml="24px !important">
      <FormControlLabel
        control={<Switch
          sx={{ transform: "scale(2)", marginRight: "10px" }}
          checked={isOpen}
          onChange={(e) => setIsOpen(e.target.checked)}
          inputProps={{ 'aria-label': 'Is Open' }}
        />}
        label="Open"
      />
    </Box>
    <Stack
      visibility={isOpen ? "visible" : "hidden"}
      direction="row"
      spacing="8px"
      alignItems="center"
    >
      <TimeField
        label="Opens At"
        value={openAt}
        fullWidth
        onChange={(v) => setOpenAt(v)}
      />
      <Typography> - </Typography>
      <TimeField
        label="Closes At"
        value={closedAt}
        fullWidth
        onChange={(v) => setClosedAt(v)}
      />
    </Stack>
    <Stack direction="row" justifyContent="flex-end" spacing="16px">
      <Button onClick={onClose}>Cancel</Button>
      <Button 
        variant="contained"
        disabled={!isValid}
        onClick={onConfirm}
      >Confirm</Button>
    </Stack>
  </Stack>
};


const AddWeekdayOverride = ({ station, onClose }) => {
  const [weekday, setWeekday] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [openAt, setOpenAt] = useState(null);
  const [closedAt, setClosedAt] = useState(null);
  const dispatch = useDispatch();

  const options = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const isValid = weekday && (
    !isOpen ||
    isOpen && openAt && openAt.isValid() && closedAt && closedAt.isValid()
  );

  const onConfirm = async () => {
    let override = {
      type: 'weekday',
      day: weekday.id,
      isOpen,
    };
    if (isOpen) {
      override = {
        ...override,
        opensAt: openAt.format("HH:mm"),
        closesAt: closedAt.format("HH:mm"),
      };
    }
    const { success } = await addStationScheduleOverride(station.id, override)(dispatch);
    success && onClose();
  };

  return <Box>
    <Stack spacing="24px">
      <Autocomplete
        label="Weekday"
        options={options.map((o, idx) => ({ id: idx, label: o }) )}
        getOptionLabel={(option) => option.label}
        value={weekday}
        onChange={(_, v) => setWeekday(v)}
      />
      <Box ml="24px !important">
        <FormControlLabel
          control={<Switch
            sx={{ transform: "scale(2)", marginRight: "10px" }}
            checked={isOpen}
            onChange={(e) => setIsOpen(e.target.checked)}
            inputProps={{ 'aria-label': 'Is Open' }}
          />}
          label="Open"
        />
      </Box>
      <Stack
        visibility={isOpen ? "visible" : "hidden"}
        direction="row"
        spacing="8px"
        alignItems="center"
      >
        <TimeField
          label="Opens At"
          value={openAt}
          fullWidth
          onChange={(v) => setOpenAt(v)}
        />
        <Typography> - </Typography>
        <TimeField
          label="Closes At"
          value={closedAt}
          fullWidth
          onChange={(v) => setClosedAt(v)}
        />
      </Stack>
    </Stack>
    <Stack direction="row" justifyContent="flex-end" spacing="16px" mt="24px">
      <Button onClick={onClose}>Cancel</Button>
      <Button 
        variant="contained"
        disabled={!isValid}
        onClick={onConfirm}
      >Confirm</Button>
    </Stack>
  </Box>;
};

const AddPeriodOverride = ({ station, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [openAt, setOpenAt] = useState(null);
  const [closedAt, setClosedAt] = useState(null);
  const dispatch = useDispatch();

  const isValid = from && from.isValid() && to && to.isValid() &&
    (
      !isOpen ||
      isOpen && openAt && openAt.isValid() && closedAt && closedAt.isValid()
    );

  const onConfirm = async () => {
    let override = {
      type: 'period',
      isOpen,
      period: [from.format("YYYY-MM-DD"), to.format("YYYY-MM-DD")]
    };
    if (isOpen) {
      override = {
        ...override,
        opensAt: openAt.format("HH:mm"),
        closesAt: closedAt.format("HH:mm"),
      };
    }
    const { success } = await addStationScheduleOverride(station.id, override)(dispatch);
    success && onClose();
  };

  return <Stack spacing="24px">
    <Stack direction="row" spacing="8px" alignItems="center">
      <DatePicker
        label="From"
        value={from}
        moment
        onChange={(v) => {
          setFrom(v);
          !to && setTo(v);
        }}
      />
      <Typography> - </Typography>
      <DatePicker
        label="To"
        moment
        value={to}
        onChange={(v) => setTo(v)}
      />
    </Stack>
    <Box ml="24px !important">
      <FormControlLabel
        control={<Switch
          sx={{ transform: "scale(2)", marginRight: "10px" }}
          checked={isOpen}
          onChange={(e) => setIsOpen(e.target.checked)}
          inputProps={{ 'aria-label': 'Is Open' }}
        />}
        label="Open"
      />
    </Box>
    <Stack
      visibility={isOpen ? "visible" : "hidden"}
      direction="row"
      spacing="8px"
      alignItems="center"
    >
      <TimeField
        label="Opens At"
        value={openAt}
        fullWidth
        onChange={(v) => setOpenAt(v)}
      />
      <Typography> - </Typography>
      <TimeField
        label="Closes At"
        value={closedAt}
        fullWidth
        onChange={(v) => setClosedAt(v)}
      />
    </Stack>
    <Stack direction="row" justifyContent="flex-end" spacing="16px">
      <Button onClick={onClose}>Cancel</Button>
      <Button 
        variant="contained"
        disabled={!isValid}
        onClick={onConfirm}
      >Confirm</Button>
    </Stack>
  </Stack>
};

const AddDialog = ({ station, open, onClose }) => {
  const [tab, setTab] = useState("date");

  return <Dialog open={open} onClose={onClose}>
    <DialogContent sx={{ padding: 0 }}>
      <Box display="flex">
        <Tabs
          sx={{
            borderRight: 1,
            borderColor: 'divider'
          }}
          orientation="vertical"
          aria-label="schedule override tabs"
          value={tab}
          onChange={(_, v) => setTab(v)}
        >
          <Tab
            sx={{ padding: "16px", textAlign: "left" }}
            id="date"
            value="date"
            label="Date"
          />
          <Tab
            sx={{ padding: "16px"}}
            id="period"
            value="period"
            label="Period"
          />
          <Tab
            sx={{ padding: "16px"}}
            id="weekday"
            value="weekday"
            label="Weekday"
          />
        </Tabs>
        <TabPanel
          sx={{ padding: "32px 24px" }}
          id="date"
          value={tab}
        >
          <AddDateOverride
            station={station}
            onClose={onClose}
          />
        </TabPanel>
        <TabPanel
          sx={{ padding: "32px 24px" }}
          id="period"
          value={tab}
        >
          <AddPeriodOverride
            station={station}
            onClose={onClose}
          />
        </TabPanel>
        <TabPanel
          sx={{ padding: "32px 24px" }}
          id="weekday"
          value={tab}
        >
          <AddWeekdayOverride
            station={station}
            onClose={onClose}
          />
        </TabPanel>
      </Box>
    </DialogContent>
  </Dialog>
};

const OverrideItem = ({ station, item, onDelete, waiting }) => {
  const f = useFormatters();
  const { formatPeriod, formatTime, formatDay } = useStationSchedule(station);
  let when = '';
  if (item.type === 'weekday') {
    when = formatDay(item);
  }
  if (item.type === 'date') {
    when = f.date.toLocaleString(item.date, f.date.DateFormats.DATE_MED_WITH_WEEKDAY)
  }
  if (item.type === 'period') {
    when = formatPeriod(item);
  }

  return <Stack flex="1" direction="row" spacing="40px" alignItems="center">
    <Typography flex="1" maxWidth="180px">{when}</Typography>
    {
      item.isOpen && <Typography minWidth="162px">{formatTime(item)}</Typography>
    }
    {
      !item.isOpen && <Typography minWidth="162px" color="text.secondary">Closed</Typography>
    }
    <IconButton
      color="error"
      size="small"
      onClick={() => onDelete(item)}
    >
      { waiting ? <CircularProgress size="24px" /> : <DeleteIcon sx={{ fontSize: "24px" }} /> }
    </IconButton>
  </Stack>
};

const StationScheduleOverride = ({ station }) => {
  const dispatch = useDispatch();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [waitingFor, setWaitingFor] = useState(null);

  const openAddDialog = () => setShowAddDialog(true);
  const closeAddDialog = () => setShowAddDialog(false);

  const deleteOverride = async (item) => {
    setWaitingFor(item);
    await removeStationScheduleOverride(station.id, item)(dispatch);
    setWaitingFor(null);
  };

  return <Box>
    <Button color="info" onClick={openAddDialog}>+ Add an override</Button>
    <AddDialog station={station} open={showAddDialog} onClose={closeAddDialog} />
    <Stack spacing="8px" marginTop="16px" maxWidth="530px">
    {
      station.overrideSchedule.map((item) => <OverrideItem
        key={uniqueId()}
        item={item}
        waiting={waitingFor === item}
        station={station}
        onDelete={deleteOverride}
      />)
    }
    </Stack>
  </Box>;
};

export default StationScheduleOverride;