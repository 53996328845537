import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Chip, Stack, Menu, MenuItem, Typography, CircularProgress } from '../../../ui/core';
import RideIcon from '../../../ui/icons/RideIcon';
import DriveIcon from '../../../ui/icons/DriveIcon';
import { editDriver } from '../../../redux/Drivers/actions';

const formatDriverType = ({ isCustomDriver }) => {
  return isCustomDriver ? 'On-call driver' : 'Going anyway driver';
};

const TypeChip = ({ value, loading }) => {
  const label = formatDriverType({ isCustomDriver: value });

  if (value) {
    return <Chip
      icon={loading ? <CircularProgress size={14} /> : <DriveIcon sx={{ fontSize: 14 }} />}
      label={label}
    />
  }

  return <Chip
    icon={loading ? <CircularProgress size={14} /> : <RideIcon sx={{ fontSize: 14 }} />}
    label={label}
  />
};

const DriverTypeField = ({ driver }) => {
  const { isCustomDriver } = driver;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectType = async (v) => {
    handleClose();
    if (v !== isCustomDriver) {
      setIsSubmitting(true);
      await editDriver(driver, { isCustomDriver: v })(dispatch);
      setIsSubmitting(false);
    }
  };

  return <div>
    <Button
      id="driver-type-button"
      aria-controls={open ? 'driver-type-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
      disabled={isSubmitting}
     >
      <TypeChip value={isCustomDriver} loading={isSubmitting} />
    </Button>
    <Menu
        id="driver-type-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'driver-type-button',
        }}
      >
        <MenuItem disabled divider>
          <Typography>Change driver type</Typography>
        </MenuItem>
        <MenuItem value={true} onClick={() => onSelectType(true)}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <DriveIcon sx={{ fontSize: 14 }} color="text.primary" />
            <Typography>{formatDriverType({ isCustomDriver: true })}</Typography>
          </Stack>
        </MenuItem>
        <MenuItem value={false} onClick={() => onSelectType(false)}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <RideIcon sx={{ fontSize: 14 }} color="text.primary" />
            <Typography>{formatDriverType({ isCustomDriver: false })}</Typography>
          </Stack>
        </MenuItem>
      </Menu>
  </div>
};

export default DriverTypeField;
