import { useEffect, useState } from 'react';
import {
  Stack,
  Box,
  Typography,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  AutocompleteInline,
  Chip,
} from '../../../ui/core';
import { scrollY } from '../../../ui/styles';
import { ExpandMoreIcon, CircleIcon, DeleteIcon, CheckBoxBlankIcon, CheckBoxIcon } from '../../../ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatters, useToast } from '../../../hooks';
import { getPromotions, removePromotion, getAvailablePromotions, addPromotions } from '../../../redux/Users/actions';
import moment from 'moment';

const Promotion = ({ promotion }) => {
  const f = useFormatters();
  const options = promotion.options || [];

  return <Stack spacing="4px">
    <Box gap="12px" display="flex" alignItems="flex-start">
      <Typography fontWeight={400}>
        {promotion.code}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {promotion.subtitle || promotion.description}
      </Typography>
      <Typography color="text.secondary" variant="body2">
        {promotion.addedOn ? f.date.toLocaleString(promotion.addedOn, f.date.DateFormats.DATE_SHORT) : ''}
      </Typography>
    </Box>
    <Box gap="4px" display="flex" alignItems="center" flexWrap="wrap">
    {
      options.map((option) => {
        if (option.type === 'percent') {
          return <Chip key={option.id} label={`${option.amount}% Off`} size="small" />
        }
        if (option.type === 'usd') {
          return <Chip key={option.id} label={`$${option.amount} Off`} size="small" />
        }
        return null;
      })
    }
    </Box>
  </Stack>;
};

const AddPromotionDialog = ({ user, open, onClose }) => {
  const dispatch = useDispatch();
  const availablePromotions = useSelector(state => state.users.selection.availablePromotions);
  const userPromotions = useSelector((state) => state.users.selection.promotions);

  const options = availablePromotions && userPromotions ?
    availablePromotions.filter((p) => !userPromotions.find((up) => up.id === p.id)) :
    [];
  const [value, setValue] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const hasChanges = value.length > 0;


  console.log({ options });

  useEffect(() => {
    const timer = setTimeout(() => {
      getAvailablePromotions(inputValue)(dispatch);
    }, 500);
    return () => clearTimeout(timer);
  }, [inputValue, dispatch]);

  const isSelected = (o) => !!value.find((d) => d.id === o.id);

  const onInputChange = (_, value, reason) => {
    if (reason === 'input') {
      setInputValue(value);
    }
  }

  const save = async () => {
    setIsSaving(true);
    const { success } = await addPromotions(user.id, value.map((p) => p.code))(dispatch);
    setIsSaving(false);
    if (success) {
      onClose();
    }
  };

  return <Dialog open={open} onClose={onClose}>
    <DialogContent sx={{ minWidth: 600, minHeight: 400, padding: 0 }}>
      <AutocompleteInline
        sx={{
          '.MuiInputBase-root': {
            padding: '12px 16px',
            width: '100%'
          }
        }}
        open
        freeSolo
        multiple
        loading={!availablePromotions}
        autoSelect={false}
        renderTags={() => null}
        placeholder="Add promotions to user"
        filterOptions={(x) => x}
        options={options}
        getOptionLabel={(option) => option.id}
        isOptionEqualToValue={(o, v) => v.id === o.id}
        renderOption={(props, o) => (
          <Box
          key={o.id}
          component="li"
          sx={{ display: "flex", padding: "10px 20px !important" }}
          {...props}
          >
            <Box mr="8px" mt="4px" display="flex">
              { isSelected(o) ? <CheckBoxIcon sx={{ color: "text.secondary" }} /> : <CheckBoxBlankIcon sx={{ color: "text.secondary" }} /> }
            </Box>
            <Promotion promotion={o} />
          </Box>
        )}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onChange={(_, v) => setValue(v)}
      />
    </DialogContent>
    <Button
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        width: "100%",
        padding: "20px",
      }}
      size="large"
      variant="contained"
      color="primary"
      disabled={!hasChanges}
      loading={isSaving}
      onClick={save}
    >
        Confirm
    </Button>
  </Dialog>
};

const PromotionItem = ({ promotion, waiting, onRemove, active }) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setShowConfirmDialog(true);
  };

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false);
  };

  const onRemoveConfirmed = () => {
    setShowConfirmDialog(false);
    onRemove(promotion);
  };

  return <>
    <ListItem sx={{ display: "flex", alignItems: "flex-start", gap: "16px", justifyContent: "space-between", minHeight: "48px" }}>
      <Stack spacing="12px" direction="row" alignItems="flex-start">
        { active && <CircleIcon sx={{ fontSize: "12px", color: "success.main", marginTop: "8px" }} />}
        <Promotion promotion={promotion} />
      </Stack>
      {
        active && <IconButton
          disabled={waiting}
          size="small"
          onClick={openConfirmDialog}
        >
          { waiting && <CircularProgress size={24} color="inherit" /> }
          { !waiting &&  <DeleteIcon sx={{ color: "error.main", fontSize: "24px" }} /> }
        </IconButton>
      }
    </ListItem>
    <Dialog open={showConfirmDialog} onClose={closeConfirmDialog}>
      <DialogContent sx={{ maxWidth: "400px" }}>
        <Typography>Are you sure you want to remove promotion</Typography>
        <Typography sx={{ fontWeight: 700 }} component="span">{promotion.code}</Typography>?
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={closeConfirmDialog}>Cancel</Button>
        <Button onClick={onRemoveConfirmed}>Confirm</Button>
      </DialogActions>
    </Dialog>
  </>
};

const PromotionsCard = ({ user }) => {
  const dispatch = useDispatch();
  const t = useToast();
  const [waitingFor, setWaitingFor] = useState(null);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const data = useSelector((state) => state.users.selection.promotions);
  const rideHistory = useSelector((state) => state.users.selection.rideHistory);

  const activePromos = [];
  const usedPromos = [];
  const expiredPromos = [];
  const unavailablePromos = [];
  if (data && rideHistory) {
    const now = moment();
    data.forEach((promo) => {
      if (promo.usesLeft <= 0) {
        return usedPromos.push(promo);
      }
      if (promo.endsOn && now.isAfter(moment(promo.endsOn))) {
        return expiredPromos.push(promo);
      }
      if (promo.endOn && now.isAfter(moment(promo.endOn))) {
        return expiredPromos.push(promo);
      }
      if (promo.startsOn && now.isBefore(moment(promo.startsOn))) {
        return unavailablePromos.push(promo);
      }
      if (promo.startOn && now.isBefore(moment(promo.startOn))) {
        return unavailablePromos.push(promo);
      }
      if (!!promo.beforeEventCount && rideHistory.completed && rideHistory.completed + 1 >= promo.beforeEventCount) {
        return expiredPromos.push(promo);
      }
      if (!!promo.afterEventCount && rideHistory.completed && rideHistory.completed < promo.afterEventCount) {
        return unavailablePromos.push(promo);
      }
      activePromos.push(promo);
    });
  }

  useEffect(() => {
    getPromotions(user.id)(dispatch);
  }, []);

  const onRemovePromotion = async (promotion) => {
    setWaitingFor(promotion.id);
    const { success } = await removePromotion(user.id, promotion.id)(dispatch);
    success ?
      t.success(`Promotion has been removed`) :
      t.error(`Something went wrong`);
    setWaitingFor(null);
  };

  return (<Accordion variant="outlined" sx={{ padding: "0 24px"}}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
        <Typography variant="subtitle1">Promotions</Typography>
    </AccordionSummary>
    <AccordionDetails
      sx={{
        padding: '0 0 24px 0',
        maxHeight: "300px",
        ...scrollY,
      }}
    >
      <Typography mb="0px" variant="h2">Active</Typography>
      {
        !data && <Stack spacing="4px">
          <Skeleton variant="rectangular" height={48} />
          <Skeleton variant="rectangular" height={48} />
        </Stack>
      }
      <List>
        {
          activePromos
            .map((p) => (<PromotionItem
              key={p.id}
              promotion={p}
              waiting={waitingFor === p.id}
              onRemove={onRemovePromotion}
              active
            />))
        }
      </List>
      <Button sx={{ marginBottom: "24px" }} color="info" onClick={() => setShowAddDialog(true)}>+ Add a new promotion</Button>
     {
      expiredPromos.length > 0 && <>
        <Typography mb="0px" variant="h2">Expired</Typography>
        <List>
          {
            expiredPromos
              .map((p) => (<PromotionItem
                key={p.id}
                promotion={p}
              />))
          }
        </List>
      </>
      }
     {
      usedPromos.length > 0 && <>
        <Typography mb="0px" variant="h2">Used Promotions</Typography>
        <List>
          {
            usedPromos
              .map((p) => (<PromotionItem
                key={p.id}
                promotion={p}
              />))
          }
        </List>
      </>
     }
    {
      unavailablePromos.length > 0 && <>
        <Typography mb="0px" variant="h2">Unavailable</Typography>
        <List>
          {
            unavailablePromos
              .map((p) => (<PromotionItem
                key={p.id}
                promotion={p}
              />))
          }
        </List>
      </>
     }
     {
        showAddDialog && <AddPromotionDialog
          user={user}
          open
          onClose={() => setShowAddDialog(false)}
        />
     }
    </AccordionDetails>
    </Accordion>)
};

export default PromotionsCard;