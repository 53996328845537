import {ProductsActions as types} from "./actions";


const initialState = {
  categories: [],
  error: null,
  isPending: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case  types.FETCH_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        isPending: false,
      };
    case types.FETCH_PRODUCT_CATEGORIES_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case types.FETCH_PRODUCT_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isPending: false,
      };
    default:
      return state;
  }
}
