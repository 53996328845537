import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uniqueId } from 'lodash';
import {
  Box,
  Stack,
  Grid,
  Divider,
  Typography,
  TextField,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button
} from '../../../ui/core';
import ExpandMoreIcon from '../../../ui/icons/ExpandMoreIcon';
import { useForm , useFormState, useFieldArray } from 'react-hook-form';
import { USAStates } from '../../../models/Drivers';
import { useTextMasks, useValidators, useFormatters } from '../../../hooks';
import { scrollY } from '../../../ui/styles';
import DriverVehicleList from './DriverVehiclesList';
import VinField from './VinField';
import ValidDocSwitch from './ValidDocSwitch';
import { editDriver } from '../../../redux/Drivers/actions';

const style = {
  container: {
    position: 'relative',
  },
  sections: {
    padding: '24px 32px',
    maxHeight: 'calc(100vh - 260px)',
    ...scrollY,
  },
  section: {
    position: 'relative',
  },
  sectionActions: {
    position: 'absolute',
    top: 24,
    left: 120,
    zIndex: 2,
    padding: '0 20px',
  },
  sectionContent: {
    paddingBottom: '24px',
  },
  footerPlaceholder: {
    height: '70px',
  },
  footer: {
    position: 'absolute',
    left: '1px',
    right: '1px',
    bottom: '1px',
    padding: '16px 32px',
    backgroundColor: '#fff',
    borderTop: '1px solid #dbdbdb',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    zIndex: 2,
  }
};

const DriverInfoForm = ({ driver, uploads, setUploads, onSectionFocus }) => {
  const v = useValidators();
  const m = useTextMasks();
  const f = useFormatters();

  const dispatch = useDispatch();
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      invalidDocuments: {
        license: driver.invalidDocuments.includes('license'),
        insurance: driver.invalidDocuments.includes('insurance'),
      },
      insuranceExpDate: f.date.toLocaleString(driver.insuranceExpDate, f.date.DateFormats.DATE_SHORT),
      licenseExpDate: f.date.toLocaleString(driver.licenseExpDate, f.date.DateFormats.DATE_SHORT),
      dob: f.date.toLocaleString(driver.dob, f.date.DateFormats.DATE_SHORT),
      gender: driver.gender || '',
      address: {
        line1: driver.address.line1 || '',
        line2: driver.address.line2 || '',
        city: driver.address.city || '',
        state: driver.address.state || '',
        postal_code: driver.address.postal_code || '',
      },
      vehicles: driver.vehicles.map((v) => ({
        _id: uniqueId('vehicle'),
        registrationExpDate: f.date.toLocaleString(v.registrationExpDate, f.date.DateFormats.MONTH_SHORT),
        registrationImageUrl: v.registrationImageUrl,
        registrationInvalid: v.registrationInvalid,
        licensePlate: v.licensePlate || '',
        make: v.make || '',
        model: v.model || '',
        year: v.year || '',
        seatCount: v.seatCount === null ? '' : v.seatCount,
        vin: v.vin || '',
        type: v.type || '',
        active: !!v.active,
        pending: false,
      })),
    },
  });
  const { isDirty, isValid, isSubmitting, dirtyFields, isValidating } = useFormState({ control: form.control });
  const { fields: vehicles, ...vehiclesFieldArray } = useFieldArray({ name: 'vehicles', control: form.control });
  const [selectedVehicleId, setSelectedVehicleId] = useState(vehicles.find((v) => !!v.active)._id);
  const selectedVehicleIdx = vehicles.findIndex(v => v._id === selectedVehicleId);

  const onLicenseFocus = () => onSectionFocus('license');
  const onRegistrationFocus = () => onSectionFocus('registration');
  const onInsuranceFocus = () => onSectionFocus('insurance');

  const onSubmit = async (data) => {
    const _data = {
      ...data,
      invalidDocuments: [
        ...(data.invalidDocuments.license ? ['license'] : []),
        ...(data.invalidDocuments.insurance ? ['insurance'] : []),
      ],
      vehicles: data.vehicles.map((v) => ({
        ...v,
        registrationImageUrl: uploads.files[v._id] || v.registrationImageUrl,
      })),
    };
    const update = Object.keys(dirtyFields)
      .reduce((u, k) => ({ ...u, ...(dirtyFields[k] ? { [k]: _data[k] } : {} )}), {});

    const { success } = await editDriver(driver, update)(dispatch);
    if (success) {
      form.reset(data);
    }
  };

  useEffect(() => {
    // update uploads document when selected vehicle changed
    setUploads({
      ...uploads,
      files: {
        ...uploads.files,
        [selectedVehicleId]: uploads.files[selectedVehicleId] ||
          form.getValues(`vehicles[${selectedVehicleIdx}].registrationImageUrl`),
      },
      uploadId: selectedVehicleId,
      vehicleIdx: selectedVehicleIdx,
      canSaveUpload: !form.getValues(`vehicles[${selectedVehicleIdx}].pending`),
    });
  }, [selectedVehicleId]);

  return <Box
    component="form"
    sx={style.container}
    onSubmit={form.handleSubmit((data) => onSubmit(data))}
  >
    <Box sx={style.sections}>
      <Box component="section" sx={style.section}>
        <Stack direction="row" sx={{ ...style.sectionActions, left: 100 }}>
          <ValidDocSwitch
            name="invalidDocuments.insurance"
            control={form.control}
            onFocus={onInsuranceFocus}
          />
        </Stack>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h2">Insurance</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={style.sectionContent}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  key={vehicles[selectedVehicleIdx].id}
                  id={vehicles[selectedVehicleIdx].id}
                  name={`vehicles[${selectedVehicleIdx}].make`}
                  label="Make"
                  control={form.control}
                  fullWidth
                  disabled
                  />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  key={vehicles[selectedVehicleIdx].id}
                  id={vehicles[selectedVehicleIdx].id}
                  name={`vehicles[${selectedVehicleIdx}].model`}
                  label="Model"
                  control={form.control}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  key={vehicles[selectedVehicleIdx].id}
                  id={vehicles[selectedVehicleIdx].id}
                  name={`vehicles[${selectedVehicleIdx}].type`}
                  label="Vehicle Type"
                  control={form.control}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  key={vehicles[selectedVehicleIdx].id}
                  id={vehicles[selectedVehicleIdx].id}
                  name={`vehicles[${selectedVehicleIdx}].year`}
                  label="Year"
                  placeholder="YYYY"
                  control={form.control}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  key={vehicles[selectedVehicleIdx].id}
                  id={vehicles[selectedVehicleIdx].id}
                  name={`vehicles[${selectedVehicleIdx}].seatCount`}
                  label="Seat Count"
                  control={form.control}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <VinField
                  key={vehicles[selectedVehicleIdx].id}
                  id={vehicles[selectedVehicleIdx].id}
                  form={form}
                  idx={selectedVehicleIdx}
                  control={form.control}
                  onFocus={onInsuranceFocus}
                  fullWidth
                  />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="insuranceExpDate"
                  label="Expiration Date"
                  placeholder="MM/DD/YYYY"
                  control={form.control}
                  rules={{ validate: { date: v.date.dateShort } }}
                  mask={m.date.dateShort}
                  fullWidth
                  onFocus={onInsuranceFocus}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Divider />
      <Box sx={style.section}>
        <Stack direction="row" sx={{ ...style.sectionActions, left: 80 }}>
          <ValidDocSwitch
            name="invalidDocuments.license"
            control={form.control}
            onFocus={onLicenseFocus}
          />
        </Stack>
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
          <Typography variant="h2">License</Typography>
        </AccordionSummary>
        <AccordionDetails sx={style.sectionContent}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
            <TextField
              id="dob"
              name="dob"
              label="D.O.B."
              placeholder="MM/DD/YYYY"
              control={form.control}
              rules={{ validate: { date: v.date.dateShort } }}
              mask={m.date.dateShort}
              fullWidth
              onFocus={onLicenseFocus}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="licenseExpDate"
                name="licenseExpDate"
                label="Expiration date"
                placeholder="MM/DD/YYYY"
                control={form.control}
                mask={m.date.dateShort}
                rules={{ validate: { date: v.date.dateShort} }}
                fullWidth
                onFocus={onLicenseFocus}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="gender"
                name="gender"
                select
                label="Sex"
                control={form.control}
                fullWidth
                onFocus={onLicenseFocus}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="x">x</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="address.line1"
                label="Address 1"
                control={form.control}
                fullWidth
                onFocus={onLicenseFocus}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                  name="address.line2"
                  label="Address 2"
                  control={form.control}
                  fullWidth
                  onFocus={onLicenseFocus}
                />
            </Grid>
            <Grid item xs={6}>
              <TextField
                  name="address.city"
                  label="City"
                  control={form.control}
                  fullWidth
                  onFocus={onLicenseFocus}
                />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="address.state"
                select
                label="State"
                control={form.control}
                fullWidth
                onFocus={onLicenseFocus}
                >
                  { USAStates.map((state) => <MenuItem key={state.value} value={state.value}>{ state.label }</MenuItem>)}
                </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="address.postal_code"
                label="Postal code"
                control={form.control}
                fullWidth
                onFocus={onLicenseFocus}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      </Box>
      <Divider />
      <Box sx={style.section}>
        <Box sx={style.sectionContent}>
          <DriverVehicleList
            form={form}
            selectedVehicleIdx={selectedVehicleIdx}
            fields={vehicles}
            fieldArray={vehiclesFieldArray}
            onSelect={(_id) => setSelectedVehicleId(_id)}
          />
        </Box>
      </Box>
      <Divider />
      <Box sx={style.section}>
      <Stack direction="row" sx={style.sectionActions}>
        <ValidDocSwitch
          key={`${vehicles[selectedVehicleIdx].id}-registrationInvalid`}
          name={`vehicles[${selectedVehicleIdx}].registrationInvalid`}
          control={form.control}
          onFocus={onRegistrationFocus}
        />
      </Stack>
      <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
        <Typography variant="h2">Registration</Typography>
      </AccordionSummary>
      <AccordionDetails sx={style.sectionContent}>
        <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                key={vehicles[selectedVehicleIdx].id}
                id={vehicles[selectedVehicleIdx].id}
                name={`vehicles[${selectedVehicleIdx}].licensePlate`}
                label="License Plate"
                control={form.control}
                fullWidth
                onFocus={onRegistrationFocus}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                  key={vehicles[selectedVehicleIdx].id}
                  id={vehicles[selectedVehicleIdx].id}
                  name={`vehicles[${selectedVehicleIdx}].registrationExpDate`}
                  label="Expiration Date"
                  placeholder="MM/YYYY"
                  control={form.control}
                  rules={{ validate: { date: v.date.monthShort } }}
                  mask={m.date.monthShort}
                  fullWidth
                  onFocus={onRegistrationFocus}
                />
            </Grid>
          </Grid>
      </AccordionDetails>
    </Accordion>
      </Box>
      { isDirty && <Box sx={style.footerPlaceholder}/>}
    </Box>
    { isDirty && (<>
      <Box component="section" sx={style.footer}>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="success"
            disabled={!isValid || isValidating}
            loading={isSubmitting}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </>)}
  </Box>
};

export default DriverInfoForm;
