import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { xor, sortBy } from 'lodash';
import {
  MenuItem,
  Dialog,
  DialogContent,
  Typography,
  Box,
  Button,
  AutocompleteInline,
} from '../../../../ui/core';
import { VisibilityIcon, CheckBoxBlankIcon, CheckBoxIcon } from '../../../../ui/icons';
import UserCard from '../UserCard';

import { searchDrivers } from '../../../../redux/Search/action';
import { updateDeniedDrivers} from '../../../../redux/Trips/actions';
import { useToast } from '../../../../hooks';

const style = {
  autocomplete: {
    '.MuiInputBase-root': {
      padding: '12px 16px',
      width: '100%'
    }
  }
}

const BlackList = ({ trip, value, onChange }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const { data: searchResult, loading } = useSelector((state) => state.search.drivers);
  const options = inputValue ?
    searchResult :
    sortBy(
      [...trip.deniedDrivers, ...value.filter((d) => !trip.deniedDrivers.find((_d) => _d.id === d.id))],
      'id'
    );

  const onInputChange = (_, value, reason) => {
    if (reason === 'input') {
      setInputValue(value);
    }
  }

  const isSelected = (o) => !!value.find((d) => d.driver_id === o.driver_id);

  useEffect(() => {
    const timer = setTimeout(() => {
      searchDrivers({ q: inputValue })(dispatch);
    }, 500);
    return () => clearTimeout(timer);
  }, [inputValue, dispatch]);

  return <AutocompleteInline
    sx={style.autocomplete}
    open
    freeSolo
    multiple
    loading={loading}
    autoSelect={false}
    renderTags={() => null}
    value={value}
    placeholder="Add drivers to deny list"
    filterOptions={(x) => x}
    options={options}
    getOptionLabel={(option) => option.driver_id}
    isOptionEqualToValue={(o, v) => v.driver_id === o.driver_id}
    renderOption={(props, o) => (
      <Box
        key={o.driver_id}
        component="li"
        sx={{ display: "flex", alignItems: "center !important", padding: "10px 20px !important" }}
        {...props}
      >
        <Box mr="8px" display="flex">
          { isSelected(o) ? <CheckBoxIcon sx={{ color: "text.secondary" }} /> : <CheckBoxBlankIcon sx={{ color: "text.secondary" }} /> }
        </Box>
        <UserCard user={o} />
      </Box>)
    }
    onChange={(_, v) => onChange(v)}
    inputValue={inputValue}
    onInputChange={onInputChange}
  />;
};

const ActionBlacklist = ({ trip, onClose }) => {
  const dispatch = useDispatch();
  const t = useToast();
  const [showDialog, setShowDialog] = useState(false);
  const [deniedDrivers, setDeniedDrivers] = useState(trip.deniedDrivers);
  const [updating, setUpdating] = useState(false);
  const hasChanges = xor(trip.deniedDrivers.map((d) => d.id), deniedDrivers.map((d) => d.id)).length > 0;

  const save = async () => {
    setUpdating(true);
    const { success } = await updateDeniedDrivers(trip.id, deniedDrivers.map((d) => d.driver_id))(dispatch);
    setUpdating(false);
    if (success) {
      t.success("Trip deny list updated.");
      close();
    }
  };

  const close = () => {
    setShowDialog(false);
    onClose();
  };

  return (<>
    <MenuItem onClick={() => setShowDialog(true)}>
      <VisibilityIcon />
      <Typography ml="5px">Attach an invalid driver</Typography>
    </MenuItem>
    <Dialog open={showDialog} onClose={close}>
      <DialogContent sx={{ minWidth: 400, minHeight: 400, padding: 0 }}>
        <BlackList
          trip={trip}
          value={deniedDrivers}
          onChange={(v) => setDeniedDrivers(v)}
        />
      </DialogContent>
      <Button
          sx={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            width: "100%",
            padding: "20px",
          }}
          size="large"
          variant="contained"
          color="primary"
          disabled={!hasChanges}
          loading={updating}
          onClick={save}
        >
          Confirm
      </Button>
    </Dialog>
  </>);
};

export default ActionBlacklist;