import { get, patch, post } from "..";
import { parseObjectToQueryString } from "../../utils/parser";

const getDrives = (query) =>
  get(`/requests/drive${parseObjectToQueryString(query)}`);
// const getRides = (trip_id) => get(`/requests/trip/${trip_id}/rides`);
// const updateRidesDrive = (data) => patch(`/requests/rides/swap`, data);
const cancelDriver = (trip_id) => post(`/requests/trip/cancel/${trip_id}`);
const createDriverCancellationEstimate = (trip_id) =>
  post(`/requests/trip/cancel/estimate/${trip_id}`);
const cancelRide = (ride_id, reason) =>
  post(`/requests/ride/cancel/${ride_id}`, { reason: reason });
const getRiderHistory = (rider_id) =>
  get(`/requests/history/rider/${rider_id}`);
const getDriverHistory = (driver_id) =>
  get(`/requests/history/driver/${driver_id}`);
const getTrips = (query) =>
  get(`/requests/trip${parseObjectToQueryString(query)}`);
const getTrip = (trip_id) => get(`/requests/trip/${trip_id}`);
// const getDrive = (drive_id) => get(`/requests/drive/${drive_id}`);
const dispatchDriver = (trip_id, data) =>
  post(`/dispatch/opportunities/${trip_id}/claim`, data);
const updatePayout = (trip_id, amount) =>
  patch(`/dispatch/payout/${trip_id}/bonus`, { amount });
const addOverrideCity = (trip_id, data) =>
  patch(`/dispatch/payout/${trip_id}/addOverrideCity`, data);
const getCities = () => get(`/requests/cities`);
const reinstateRide = (ride_id, data) =>
  post(`/requests/ride/reinstate/${ride_id}`, data);
const pickUpRide = (trip_id, data) =>
  post(`/requests/trip/${trip_id}/pickup`, data);
const dropOffRide = (trip_id, data) =>
  post(`/requests/trip/${trip_id}/dropOff`, data);

export {
  cancelDriver,
  cancelRide,
  // getRides,
  getDrives,
  getTrips,
  getTrip,
  // getDrive,
  // updateRidesDrive,
  getRiderHistory,
  getDriverHistory,
  dispatchDriver,
  updatePayout,
  createDriverCancellationEstimate,
  addOverrideCity,
  getCities,
  reinstateRide,
  pickUpRide,
  dropOffRide,
};
