import React from 'react';
import { Button, Typography, Box, Stack, Link, Avatar } from '../../ui/core';
import { useAuth, usePermissions } from '../../hooks';
import logo from "../../assets/img/hitch-full-logo.svg";

const PageAvatar = ({ color, backgroundColor, children }) => {

  return <Avatar
    sx={{
      color,
      backgroundColor,
      borderRadius: "10px",
      fontSize: "20px",
      fontWeight: "700",
    }}
  >
    {children}
  </Avatar>
};

const HomeLink = ({ to, avatar, title, subtitle }) => {
  return <Link
    to={to}
    sx={{
      textDecoration: "none",
      padding: "24px",
      display: "flex",
      flexDirection: "column",
      width: "280px",
      borderRadius: "12px",
      border: "1px solid",
      borderColor: "divider",
      boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.06)",
    }}
  >
    <Stack direction="row" alignItems="center" spacing="12px" mb="8px">
      {avatar}
      <Typography fontSize="20px" fontWeight="700">{title}</Typography>
    </Stack>
    <Typography color="text.secondary" fontWeight="300">{subtitle}</Typography>
  </Link>;
};


const Home = () => {
  const auth = useAuth();
  const permissions = usePermissions();

  return <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100vh"
    width="100%"
    padding="48px"
    backgroundColor="#FFF"
  >
    <Box display="flex"justifyContent="space-between" width="100%">
      <img alt="Hitch" with="80" height="30" src={logo} />
      <Button variant="outlined" onClick={auth.logout}>Logout</Button>
    </Box>
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      marginTop="100px"
    >
      <Box textAlign="center" width="710px">
        <Typography
          variant="h1"
          fontSize="52px"
          lineHeight="64px"
          fontWeight="700"
        >
          Discovering truths through metrics
        </Typography>
        <Typography
          fontSize="22px"
          lineHeight="1.4"
          color="text.secondary"
          fontWeight="300"
          mt="12px"
        >
          Learn from data to make better decisions and iterate quickly
        </Typography>
      </Box>
      <Box
        mt="64px"
        display="flex"
        justifyContent="center"
        gap="24px"
        flexWrap="wrap"
        width="90%"
      >
        {
          permissions.canViewRides && <HomeLink
            to="/trips/daily"
            avatar={<PageAvatar backgroundColor="#97D2BE" color="#0D3A17">T</PageAvatar>}
            title="Trips"
            subtitle="Track and edit past, current, and future trips"
          />
        }
        {
          permissions.canViewUsers && <HomeLink
            to="/users?page=0&limit=13"
            avatar={<PageAvatar backgroundColor="#732C59" color="#FFFFFF">U</PageAvatar>}
            title="User Data"
            subtitle="Modify driver and rider data"
          />
        }
        {
          permissions.canViewDriverApprovals && <HomeLink
            to="/drivers-approval/drivers-triage/waitlist?page=0&limit=7"
            avatar={<PageAvatar backgroundColor="#FF6837" color="#86371E">D</PageAvatar>}
            title="Driver Approvals"
            subtitle="Triage driver applications through our custom funnel"
          />
        }
        {
          permissions.canViewStations && <HomeLink
            to="/stations"
            avatar={<PageAvatar backgroundColor="#FCA5A5" color="#B91C1C">S</PageAvatar>}
            title="Stations"
            subtitle="Manage available stations"
          />
        }
      </Box>
    </Box>
  </Box>;
};

export default Home;
