import api from '../../lib/api';

export const TagsActionsTypes = {
  FETCH_TAGS_STARTED: 'FETCH_TAGS_STARTED',
  FETCH_TAGS_SUCCESS: 'FETCH_TAGS_SUCCESS',
  FETCH_TAGS_FAILURE: 'FETCH_TAGS_FAILURE',
};

export const getTags = () => async dispatch => {
  dispatch({
    type: TagsActionsTypes.FETCH_TAGS_STARTED,
  });

  try {
    const tags = await api.tags.getAll();
    dispatch({
      type: TagsActionsTypes.FETCH_TAGS_SUCCESS,
      payload: tags,
    });
    return { success: true, tags };
  } catch(e) {
    dispatch({
      type: TagsActionsTypes.FETCH_TAGS_FAILURE,
    });
    return { success: false, tags: [] };
  }
};

export const addTag = (name) => async dispatch => {
  try {
    await api.tags.addTag(name);
    return getTags()(dispatch);
  } catch (e) {
    const { tags } = await getTags()(dispatch);
    if (tags.includes(name)) {
      return { success: true };
    }
    return { success: false };
  }
};