import { useRef, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  height: '100%',
  position: 'absolute',
  right: 0,
  cursor: 'col-resize',
  'margin-left': 'auto',
  'margin-right': -12,
}));

const ColumnSeparator = ({ colIdx, onResize }) => {
  const container = useRef(null);
  const colWidth = useRef(null);
  const pageX = useRef(0);
  const diffX = useRef(0);

  const onMouseDown = (e) => {
    if (container.current) {
      colWidth.current = container.current.parentElement.getBoundingClientRect().width;
      pageX.current = e.pageX;
      diffX.current = 0;
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    }
  };
  const onMouseMove = (e) => {
    if (container.current && onResize) {
      diffX.current = e.pageX - pageX.current;
      const newWidth = Math.max(colWidth.current + diffX.current, 100);
      onResize(colIdx, newWidth);
    }
  };
  const onMouseUp = () => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  return (<Container
    ref={container}
    className="DataGrid-columnHeader-separator"
    display="flex"
    alignItems="center"
    justifyContent="center"
    onMouseDown={onMouseDown}
  >
    <svg height="24" width="24" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
      <path fill="#EAEAEA" d="M11 19V5h2v14z"></path>
    </svg>
  </Container>);
};

export default ColumnSeparator;