import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  Stack,
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DatePicker,
  TimeField,
  Switch,
  FormControlLabel,
  CircularProgress,
} from '../../../ui/core';
import { scrollY } from '../../../ui/styles';
import { ExpandMoreIcon, DeleteIcon } from '../../../ui/icons';
import { useFormatters } from '../../../hooks';
import {
  addBlacklistedDate,
  addBlacklistedDateTime,
  removeBlacklistedDate,
  removeBlacklistedDateTime,
  
} from '../../../redux/Users/actions';

const AddDialog = ({ user, onClose }) => {
  const [date, setDate] = useState(null);
  const [hasTime, setHasTime] = useState(false);
  const dispatch = useDispatch();

  const isValid = date && date.isValid();

  const onConfirm = async () => {
    if (hasTime) {
      const dateTime = moment.tz(date.format("YYYY-MM-DD HH:mm"), user.homeCity.timeZone).format();
      const { success } = await addBlacklistedDateTime(user.id, dateTime)(dispatch);
      success && onClose();
    } else {
      const { success } = await addBlacklistedDate(user.id, date.format("YYYY-MM-DD"))(dispatch);
      success && onClose();
    }
  };

  return <Dialog open onClose={onClose}>
    <DialogContent sx={{ paddingTop: "32px" }}>
      <Stack spacing="48px" width="400px">
        <Stack direction="row" spacing="8px" alignItems="center">
          <DatePicker
            fullWidth
            label="Date"
            value={date}
            moment
            onChange={(v) => setDate(v)}
          />
        </Stack>
        <Box ml="24px !important">
          <FormControlLabel
            control={<Switch
              sx={{ transform: "scale(2)", marginRight: "10px" }}
              checked={hasTime}
              onChange={(e) => setHasTime(e.target.checked)}
              inputProps={{ 'aria-label': 'Add time' }}
            />}
            label="Add time"
          />
        </Box>
        <Stack
          visibility={hasTime ? "visible" : "hidden"}
          direction="row"
          spacing="8px"
          alignItems="center"
        >
          <TimeField
            label="Time"
            value={date}
            fullWidth
            onChange={(v) => setDate(v)}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing="16px">
          <Button onClick={onClose}>Cancel</Button>
          <Button 
            variant="contained"
            disabled={!isValid}
            onClick={onConfirm}
          >Confirm</Button>
        </Stack>
      </Stack>
    </DialogContent>
  </Dialog>;
};

const BlockedDateItem = ({ user, value, hasTime, onDelete, waiting }) => {
  const f = useFormatters();
  return <Stack
    direction="row"
    spacing="40px"
    alignItems="center"
    width="300px"
  >
    <Typography flex="1">
      {
        hasTime ?
          f.date.toTimezoneLocaleString(value, user.homeCity.timeZone, f.date.DateFormats.DATETIME_SHORT_WITH_TIMEZONE) :
          f.date.toLocaleString(value, f.date.DateFormats.DATE_MED)
      }
    </Typography>
    <IconButton
      color="error"
      size="small"
      onClick={() => onDelete(value)}
    >
      { waiting ? <CircularProgress size="24px" /> : <DeleteIcon sx={{ fontSize: "24px" }} /> }
    </IconButton>
  </Stack>;
};

const DriverBlockDates = ({ user }) => {
  const dispatch = useDispatch();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [waitingFor, setWaitingFor] = useState(null);

  const openAddDialog = () => setShowAddDialog(true);
  const closeAddDialog = () => setShowAddDialog(false);

  const deleteBlockedDate = async (value) => {
    setWaitingFor(value);
    await removeBlacklistedDate(user.id, value)(dispatch);
    setWaitingFor(null);
  };

  const deleteBlockedDateTime = async (value) => {
    setWaitingFor(value);
    await removeBlacklistedDateTime(user.id, value)(dispatch);
    setWaitingFor(null);
  };

  return <Accordion variant="outlined" sx={{ padding: "0 24px"}}>
     <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
      <Typography variant="subtitle1">Driver Blacklisted Dates</Typography>
   </AccordionSummary>
   <AccordionDetails sx={{ padding: '0 0 24px 0' }}>
    <Button color="info" onClick={openAddDialog}>+ Add blacklisted date</Button>
    { showAddDialog && <AddDialog user={user} onClose={closeAddDialog} /> }
    <Stack spacing="8px" mt="24px" maxHeight="300px" sx={{ ...scrollY }}>
      {
        user.blacklistedDates.map((date) => <BlockedDateItem
          key={date}
          user={user}
          value={date}
          waiting={waitingFor === date}
          onDelete={deleteBlockedDate}
        />)
      }
      {
        user.blacklistedTimes.map((time) => <BlockedDateItem
          key={time}
          user={user}
          value={time}
          hasTime
          waiting={waitingFor === time}
          onDelete={deleteBlockedDateTime}
        />)
      }
    </Stack>
   </AccordionDetails>
  </Accordion>
};

export default DriverBlockDates;
