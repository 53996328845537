import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Chip, Button, Menu, MenuItem, Typography, CircularProgress } from '../../../ui/core';
import RouteIcon from '../../../ui/icons/RouteIcon';
import { editDriver } from '../../../redux/Drivers/actions';

const getLabel = (city) => [city.cityName, city.stateAbbr].filter(v => !!v).join(',');


const DriverCityField = ({ driver }) => {
  const { homeCity } = driver;
  const cities = useSelector((state) => state.cities.all.data);
  const city = cities.find((c) => c.id === homeCity) || {};

  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCitySelected = async (city) => {
    handleClose();
    if (city.id !== homeCity) {
      setIsSubmitting(true);
      await editDriver(driver, { homeCity: city.id })(dispatch);
      setIsSubmitting(false);
    }
  };

  return (<div>
    <Button
      id="driver-city-button"
      aria-controls={open ? 'driver-city-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
      disabled={isSubmitting}
    >
      <Chip
        icon={isSubmitting ? <CircularProgress size={14} /> : <RouteIcon sx={{ fontSize: 14 }} />}
        label={getLabel(city)}
      />
    </Button>
    <Menu
      id="driver-city-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'driver-city-button',
      }}
    >
      <MenuItem disabled divider>
        <Typography>Change home city ...</Typography>
      </MenuItem>
      {
        cities.map((c) => <MenuItem
          key={c.id}
          value={c.id}
          onClick={() => onCitySelected(c)}
        >
          <Typography>{ getLabel(c) }</Typography>
        </MenuItem>)
      }
    </Menu>
  </div>);
};

export default DriverCityField;
