import { useState } from 'react';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  TextField,
  Button,
} from '../../../ui/core';
import { EditNoteIcon, CloseIcon } from '../../../ui/icons';

import { useForm, useFormState } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useToast } from '../../../hooks';

import { updateStation } from '../../../redux/Stations/actions';

const EditDialog = ({ station, onClose }) => {
  const dispatch = useDispatch();
  const t = useToast();

  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      lat: station.coord  && station.coord.coordinates ? station.coord.coordinates[1] : '',
      lon: station.coord  && station.coord.coordinates ? station.coord.coordinates[0] : '',
    },
  });
  const { isDirty, isValid, isSubmitting } = useFormState({ control: form.control });

  const onSubmit = async (data) => {
    const coordinates = [Number.parseFloat(data.lon), Number.parseFloat(data.lat)];
    const input = {
      coord: coordinates[0] && coordinates[1] ? coordinates : null,
    };
    const { success } = await updateStation(station.id, input)(dispatch);
    success ? t.success("Station location updated") : t.error("Not saved");
    success && onClose();
  };

  return (<Dialog open onClose={onClose}>
    <Box display="flex" alignItems="center" justifyContent="space-between" padding="32px 32px 0 32px">
      <DialogTitle sx={{ padding: 0, marginRight: "120px" }}>Edit Station Location</DialogTitle>
      <IconButton onClick={onClose}><CloseIcon /></IconButton>
    </Box>
    <Box component="form" onSubmit={form.handleSubmit((data) => onSubmit(data))}>
      <Box display="flex" padding="32px" gap="24px">
        <TextField
          label="Lat"
          fullWidth
          control={form.control}
          name="lat"
        />
        <TextField
          label="Lon"
          fullWidth
          control={form.control}
          name="lon"
        />
      </Box>
      <Button
        type="submit"
        disabled={!(isValid && isDirty)}
        loading={isSubmitting}
        variant="contained"
        size="large"
        disableElevation
        sx={{ width: "100%", padding: "20px", borderRadius: "0 0 4px 4px" }}
      >Save</Button>
    </Box>
  </Dialog>)
};

const EditStationLocation = ({ station }) => {
  const [showEditDialog, setShowEditDialog] = useState(false);

  return (<>
    <IconButton onClick={() => setShowEditDialog(true)}>
      <EditNoteIcon sx={{ color: "primary.main", fontSize: "24px" }} />
    </IconButton>
    { showEditDialog && <EditDialog station={station} onClose={() => setShowEditDialog(false)} />}
  </>);
};

export default EditStationLocation;
