import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { AppBar, Toolbar, Divider, Drawer, Box, Stack, Typography, IconButton, Button } from '../ui/core';
import HitchLogoIcon from '../ui/icons/HitchLogo';
import HamburgerIcon from '../ui/icons/HamburgerIcon';
import { styled } from '../ui/styles';
import { useAuth } from '../hooks';

const drawerWidth = 256;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    }),
  }),
);

const Spacer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '84px',
}));

const Nav = styled('nav')(({ theme }) => ({
  paddingTop: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  'a': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 12px',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    '&.active-parent': {
      ...theme.typography.body1,
      color: theme.palette.text.primary,
      fontWeight: 700,
    },
    '&.active': {
      ...theme.typography.body1,
      color: theme.palette.text.primary,
      fontWeight: 700,
      borderLeft: '4px solid',
      paddingLeft: '28px',
      borderColor: theme.palette.text.primary,
      backgroundColor:  theme.palette.background.default,
    },
  },
  '> div': {
    'a': {
      paddingLeft: '32px',
    }
  }
}));

const DashboardLayout = ({ title, avatar, navigation, appBarContent, children }) => {
  const auth = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(!!navigation);

  const onLogout = () => auth.logout();

  return (<Box sx={{ display: 'flex' }}>
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} color="white" elevation={0}>
      <Toolbar>
        <Stack sx={{ mr: 11 }} direction="row" alignItems="center" spacing={4}>
          <Link to="/home">
            <HitchLogoIcon sx={{ fontSize: 40 }} />
          </Link>
          <Divider orientation="vertical" flexItem />
          {
            navigation && <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
              <HamburgerIcon sx={{ fontSize: 32 }} />
            </IconButton>
          }
        </Stack>
        <Stack sx={{ flex: 1 }} direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={20}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {avatar}
              <Typography variant="h1">{title}</Typography>
            </Stack>
            { appBarContent }
          </Stack>
          <Button variant="outlined" onClick={onLogout}>Logout</Button>
        </Stack>
      </Toolbar>
    </AppBar>
    {
      navigation && (
        <Drawer  variant="persistent" open={drawerOpen}>
          <Spacer />
          <Box sx={{ overflow: 'auto', width: drawerWidth }}>
            <Nav>
              {
                navigation.map(({ label, link, sublinks }) => <React.Fragment key={link}>
                  <NavLink
                    activeClassName={sublinks && sublinks.length > 0 ? 'active-parent' : 'active'}
                    to={link}
                  >{label}</NavLink>
                  {
                    sublinks && sublinks.length > 0 && <div>
                      { sublinks.map((i) => <NavLink exact key={i.link} to={i.link}>{i.label}</NavLink>) }
                    </div>
                  }
                </React.Fragment>)
              }
            </Nav>
          </Box>
        </Drawer>
      )
    }
    <Main open={drawerOpen}>
      <Spacer />
      { children }
    </Main>
  </Box>);
};

export default DashboardLayout;
