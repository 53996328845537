import api from '../../lib/api';

export const CitiesActionsTypes = {
  FETCH_CITIES_STARTED: 'FETCH_CITIES_STARTED',
  FETCH_CITIES_SUCCESS: 'FETCH_CITIES_SUCCESS',
  FETCH_CITIES_FAILURE: 'FETCH_CITIES_FAILURE',
};

export const getCities = () => async dispatch => {
  dispatch({
    type: CitiesActionsTypes.FETCH_CITIES_STARTED,
  });

  try {
    const cities = await api.cities.getCities();
    dispatch({
      type: CitiesActionsTypes.FETCH_CITIES_SUCCESS,
      payload: cities,
    });
  } catch(e) {
    dispatch({
      type: CitiesActionsTypes.FETCH_CITIES_FAILURE,
    });
  }
};