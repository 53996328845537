import Cookies from 'cookies-js';
import jwtDecode from 'jwt-decode';
import {AuthActions as types} from './actions'


const initialState = {
  token: Cookies.get('user') || null,
  decodedToken: Cookies.get('user') ? jwtDecode(Cookies.get('user')) : null,
  isPending: false,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        decodedToken: jwtDecode(action.payload),
        isPending: false,
        error: null,
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        error: action.payload,
        isPending: false,
      };
    case types.RESET_ERROR:
      return  {
        ...state,
        error: null,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        decodedToken: null,
        isPending: false,
        error: null,
      }
    default:
      return state;
  }
}
