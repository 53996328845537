import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ChevronLeftIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 48 48">
    <path d="M28.05 36 16 23.95 28.05 11.9l2.15 2.15-9.9 9.9 9.9 9.9Z"/>
  </SvgIcon>
};

export default ChevronLeftIcon;
