import { Stack, Avatar, Typography } from '../../../ui/core';

const UserCard = ({ user }) => {
  if (!user) {
    return null;
  }

  return <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
    <Avatar sx={{ width: 32, height: 32 }} src={user.imageUrl} />
    <Typography>{user.firstName || ''} {user.lastName || ''}</Typography>
  </Stack>
};

export default UserCard;
