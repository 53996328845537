import api from '../../lib/api';

export const StationsActionsTypes = {
  FETCH_STATIONS_SUCCESS: 'FETCH_STATIONS_SUCCESS',
  FETCH_STATIONS_FAILURE: 'FETCH_STATIONS_FAILURE',
  STATION_UPDATE_SUCCESS: 'STATION_UPDATE_SUCCESS',
  FETCH_AMENITIES_SUCCESS: 'FETCH_AMENITIES_SUCCESS',
  SET_SELECTED_STATION: 'SET_SELECTED_STATION'
}

export const fetchStations = ({ city, offset, limit }) => async dispatch => {
  try {
    const data = await api.stations.getStations({ city, offset, limit });
    dispatch({
      type: StationsActionsTypes.FETCH_STATIONS_SUCCESS,
      payload: data,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const fetchAmenities = () => async dispatch => {
  try {
    const data = await api.stations.getAmenities();
    dispatch({
      type: StationsActionsTypes.FETCH_AMENITIES_SUCCESS,
      payload: data,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const fetchStationsCount = () => async dispatch => {

};

export const fetchSelectedStation = (stationId) => async dispatch => {
  try {
    const station = await api.stations.getStation(stationId);
    dispatch({
      type: StationsActionsTypes.SET_SELECTED_STATION,
      payload: station,
    });
  } catch {
    return { success: false };
  }
};

export const setSelectedStation = (station) => async dispatch => {
  dispatch({
    type: StationsActionsTypes.SET_SELECTED_STATION,
    payload: station,
  });
};

export const createStation = (input) => async dispatch => {
  try {
    const station = await api.stations.createStation({ input });
    return { success: true, station };
  } catch {
    return { success: false };
  }
};

export const updateStation = (stationId, update) => async dispatch => {
  try {
    const data = await api.stations.updateStation(stationId, update);
    dispatch({
      type: StationsActionsTypes.STATION_UPDATE_SUCCESS,
      payload: data,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const addStationScheduleOverride = (stationId, override) => async dispatch => {
  try {
    const data = await api.stations.addScheduleOverride(stationId, override);
    dispatch({
      type: StationsActionsTypes.STATION_UPDATE_SUCCESS,
      payload: data,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const removeStationScheduleOverride = (stationId, override) => async dispatch => {
  try {
    const data = await api.stations.removeScheduleOverride(stationId, override);
    dispatch({
      type: StationsActionsTypes.STATION_UPDATE_SUCCESS,
      payload: data,
    });
    return { success: true };
  } catch {
    return { success: false };
  }
};