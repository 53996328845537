import React, { useRef, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useValidators, useTextMasks } from '../../../hooks';
import { TextField, CircularProgress } from '../../../ui/core';
import { decodeVIN } from '../../../redux/Drivers/actions';

const VinField = ({ control, form, idx, ...other }) => {
  const v = useValidators();
  const m = useTextMasks();
  const decodingInfo = useRef({ v: null, data: null });
  const { field, fieldState: { error }, formState: { isValidating } } = useController({
    control,
    name: `vehicles[${idx}].vin`,
    rules: {
      validate: {
        init: (val) => {
          if (decodingInfo.current.v === null) {
            decodingInfo.current.v = val;
          }
          return true;
        },
        length: v.text.length(17),
        decode: async (val) => {
          if (decodingInfo.current.v !== val) {
            const { data } = await decodeVIN(val)();
            decodingInfo.current.v = v;
            decodingInfo.current.data = data;
          }
          return true;
        },
      }
    },
  });

  const onVinDecoded = (data) => {
    const vehicle = form.getValues(`vehicles[${idx}]`);
    form.setValue(`vehicles[${idx}].make`, data.make, { shouldDirty: true, shouldTouch: true });
    form.setValue(`vehicles[${idx}].model`, data.model, { shouldDirty: true, shouldTouch: true });
    form.setValue(`vehicles[${idx}].type`, data.type, { shouldDirty: true, shouldTouch: true });
    form.setValue(`vehicles[${idx}].year`, data.year, { shouldDirty: true, shouldTouch: true });
    form.setValue(`vehicles[${idx}].seatCount`, vehicle.seatCount === '' ? '3' : vehicle.seatCount, { shouldDirty: true, shouldTouch: true });
  };
  

  useEffect(() => {
    if (!isValidating && decodingInfo.current.data) {
      onVinDecoded(decodingInfo.current.data);
    }
  }, [isValidating]);

  return <TextField
    name={`vehicles[${idx}].vin`}
    label="VIN"
    mask={m.text.uppercase}
    value={field.value}
    onChange={field.onChange}
    onBlur={field.onBlur}
    inputRef={field.ref}
    error={!!error}
    helperText={error ? error.message : ''}
    InputProps={{
      endAdornment: isValidating ? <CircularProgress size={16} color="inherit" /> : null,
      readOnly: isValidating,
    }}
    {...other}
  />;
};

export default VinField;
