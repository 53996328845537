import { useLocation } from "react-router-dom";

const useQuery = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const entries = [...params.entries()];

  return entries.reduce((data, [key, value]) => {
    data[key] = value;
    return data;
  }, {});
};

export default useQuery;
