import { useEffect } from 'react';
import {
  Stack,
  Box,
  Typography,
  Paper,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../../ui/core';
import { ExpandMoreIcon } from '../../../ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatters } from '../../../hooks';
import { getRiderCashFlow } from '../../../redux/Users/actions';

const RiderCashFlowCard = ({ user }) => {
  const dispatch = useDispatch();
  const f = useFormatters();
  const data = useSelector((state) => state.users.selection.riderCashFlow);

  useEffect(() => {
    getRiderCashFlow(user.id)(dispatch);
  }, []);

  return (<Accordion variant="outlined" sx={{ padding: "0 24px"}}>
   <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
      <Typography variant="subtitle1">Rider Cash Flow</Typography>
   </AccordionSummary>
   <AccordionDetails sx={{ padding: '0 0 24px 0' }}>
    <Box display="flex" gap="16px" flexWrap="wrap">
        <Stack spacing="8px">
          <Typography color="text.secondary">Ride Credit</Typography>
          <Typography variant="subtitle1">{f.number.currency(user.creditAmount)}</Typography>
        </Stack>
        <Stack spacing="8px">
          <Typography color="text.secondary">Cancellation Credit</Typography>
          <Typography variant="subtitle1">{f.number.currency(user.cancellationCredit)}</Typography>
        </Stack>
        <Stack spacing="8px">
          <Typography color="text.secondary">Referral Credit</Typography>
          <Typography variant="subtitle1">{f.number.currency(user.referralCredit)}</Typography>
        </Stack>
        <Stack spacing="8px">
          <Typography color="text.secondary">Total Seats</Typography>
          {
            data ?
              <Typography variant="subtitle1">{f.number.decimal(data.totalSeats)}</Typography> :
              <Skeleton variant="rectangular" height={32} />
          }
        </Stack>
        <Stack spacing="8px">
          <Typography color="text.secondary">Total Rides</Typography>
          {
            data ?
              <Typography variant="subtitle1">{f.number.decimal(data.totalRides)}</Typography> :
              <Skeleton variant="rectangular" height={32} />
          }
        </Stack>
        <Stack spacing="8px">
          <Typography color="text.secondary">Total Tipped</Typography>
          {
            data ?
              <Typography variant="subtitle1">{f.number.currency(data.totalTipped)}</Typography> :
              <Skeleton variant="rectangular" height={32} />
          }
        </Stack>
        <Stack spacing="8px">
          <Typography color="text.secondary">Volume</Typography>
          {
            data ?
              <Typography variant="subtitle1">{f.number.currency(data.volume)}</Typography> :
              <Skeleton variant="rectangular" height={32} />
          }
        </Stack>
      </Box>
   </AccordionDetails>
  </Accordion>)
};

export default RiderCashFlowCard;