import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Stack, LinearProgress, Pagination, PaginationItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { scrollXY } from '../../styles/scrollbars';

import ColumnSeparator from './ColumnSeparator';

const LoadingOverlay = () => {
  return <Stack
    alignItems="center"
    justifyContent="center"
    sx={{ height: "100%", backgroundColor: "rgba(255,255,255,0.5)" }}
  >
    <LinearProgress sx={{ width: "300px" }} />
  </Stack>;
};

const GridContainer = styled(Box)(({ theme }) => ({
  position:'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '.DataGrid-main': {
    ...scrollXY,
    display: 'flex',
    flexDirection: 'column',
    '.DataGrid-headers': {
      position: 'sticky',
      top: 0,
      zIndex: 3,
      background: '#FFF',
      borderBottom: `1px solid ${theme.palette.spacer.main}`,
      '.DataGrid-header': {
        padding: '0 24px',
        minHeight: '56px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'relative',
        p: {
          width: '100%',
          overflow: 'hidden',
        }
      },
    }
  },
  '.DataGrid-content': {
    position: 'relative',
    flex: 1,
  },
  '.DataGrid-row': {
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.spacer.main}`,
    '&:hover, &.Mui-selected': {
      backgroundColor: theme.palette.action.hover,
    }
  },
  '.DataGrid-row-group': {
    backgroundColor: theme.palette.action.hover,
    '&:hover, &.Mui-selected': {
      backgroundColor: theme.palette.action.selected,
    },
    '.DataGrid-cell': {
      overflow: 'visible',
    }
  },
  '.DataGrid-cell': {
    padding: '0 24px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '.DataGrid-footer': {
    display: 'flex',
    minHeight: '52px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }
}));

const DataGrid = ({
  columns,
  rows,
  onRowClick,
  getRowClassName,
  getRowHeight,
  getRowId,
  hideFooterPagination,
  loading,
  page,
  pageSize,
  paginationMode,
  onPageChange,
  rowCount,
  ...other
}) => {
  const container = useRef(null);
  const resizing = useRef([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  const trySelectRow = (index) => {
    const rowItems = document.querySelectorAll('.DataGrid-row');
    rowItems.forEach((item) => item.classList.remove('Mui-hovered'));
    // if there are no rows do nothingjjj
    if (!rowItems.length) {
      return false;
    }
    // get min and max index of rendered rows
    const minIndex = +rowItems[0].getAttribute('data-rowindex');
    const maxIndex = +rowItems[rowItems.length - 1].getAttribute('data-rowindex');

    const _index = Math.max(Math.min(index, maxIndex), minIndex);
    const el = document.querySelector(`.DataGrid-row[data-rowindex="${_index}"]`);
    if (el) {
      el.classList.add('Mui-hovered');
      setSelectedRowIndex(_index);
      return true;
    }
    return false;
  };

  const onColResize = (colIdx, width) => {
    if (container.current) {
      const rows = container.current.querySelectorAll("[role=row]");
      let gridTemplateColumns = '';
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (!gridTemplateColumns) {
          const cols = Array.from(row.children);
          gridTemplateColumns = cols.map((c, idx) => idx === colIdx ? `${width}px` : `${c.offsetWidth}px`).join(' ');
          resizing.current = cols.map((c, idx) => idx ===colIdx ? width : c.offsetWidth);
        }
        row.style.gridTemplateColumns = gridTemplateColumns;
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const targetType = event.target.tagName.toUpperCase() || event.srcElement.tagName.toUpperCase();
      if (targetType !== 'INPUT') {
        const key = event.key && event.key.toLowerCase();
        switch (key) {
          case 'j':
            trySelectRow(selectedRowIndex + 1) && event.preventDefault();
            break;
          case 'k':
            trySelectRow(selectedRowIndex - 1) && event.preventDefault();
            break;
          case 'enter':
            rows[selectedRowIndex] && onRowClick && onRowClick({ row: rows[selectedRowIndex] });
            break;
          default:
            return;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [rows, selectedRowIndex, onRowClick]);

  const gridTemplateColumns = columns.reduce((style, c, idx) => {
    if (resizing.current[idx]) {
      return [...style, `${resizing.current[idx]}px`];
    }
    if (c.minWidth) {
      return [...style, `minmax(${c.minWidth}px,${c.flex || 1}fr)`];
    }
    if (c.maxWidth) {
      return [...style, `minmax(${c.flex || 1}fr,${c.maxWidth}px)`];
    }
    if (c.flex) {
      return [...style, `${c.flex}fr`];
    }
    if (c.width) {
      return [...style, `${c.width}px`];
    }
    return [...style, '1fr'];
  }, []).join(' ');

  return <GridContainer
    {...other}
    ref={container}
    className="DataGrid-root"
    role="grid"
    aria-colcount={columns.length}
    aria-rowcount={rows.length}
    aria-multiselectable={false}
  >
    <Box
      className="DataGrid-main"
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={hideFooterPagination ? 0 : 52}
    >
      <Box
        className="DataGrid-headers"
        role="rowgroup"
        width="fit-content"
        minWidth="100%"
      >
        <Box
          role="row"
          aria-rowindex="1"
          display="grid"
          style={{ gridTemplateColumns }}
          width="fit-content"
          minWidth="100%"
        >
          {
            columns.filter((c) => !c.hide).map((c, idx) => (
              <Box
                key={c.field}
                className="DataGrid-header"
                role="columnheader"
                aria-colindex={idx}
                display="flex"
                alignItems="center"
              >
                <Typography color="text.secondary">{ c.headerName }</Typography>
                <ColumnSeparator onResize={onColResize} colIdx={idx} />
              </Box>
            ))
          }
        </Box>
      </Box>
      <Box
        className="DataGrid-content"
        width="fit-content"
        minWidth="100%"
      >
        {
          loading && <LoadingOverlay />
        }
        {
          !loading && rows.map((row, rowIdx) => (
            <Box
              key={getRowId ? getRowId(row) : row.id}
              className={classNames(
                "DataGrid-row",
                rowIdx === selectedRowIndex ? "Mui-selected" : "",
                getRowClassName ? getRowClassName({ row }) : "",
              )}
              role="row"
              data-rowid={getRowId ? getRowId(row) : row.id}
              aria-rowindex={2 + rowIdx}
              data-rowindex={rowIdx}
              display="grid"
              style={{ gridTemplateColumns }}
              width="fit-content"
              minWidth="100%"
              position="relative"
              onClick={(e) => {
                setSelectedRowIndex(rowIdx);
                onRowClick({ row });
              }}
            >
              {
              columns.filter((c) => !c.hide).map((c, colIdx) => (
                <Box
                  key={c.field}
                  className="DataGrid-cell"
                  role="cell"
                  aria-colindex={colIdx}
                  height={getRowHeight ? getRowHeight({ model: row }) : "56px"}
                  display="flex"
                  alignItems="center"
                  position="relative"
                >
                  {
                    c.renderCell && c.renderCell({ row })
                  }
                  {
                    !c.renderCell && <Typography>{ row[c.field]}</Typography>
                  }
                </Box>
              ))
            }
            </Box>
          ))
        }
      </Box>
    </Box>
    {
      !hideFooterPagination && (
      <Box
        className="DataGrid-footer"
        position="absolute"
        bottom="0"
        left="0"
        right="0"
      >
        <Pagination
          color="spacer"
          page={Math.max(1, page + 1)}
          count={Math.ceil(rowCount / pageSize) || 1}
          renderItem={(p) => <PaginationItem {...p} disableRipple />}
          onChange={(_, v) => onPageChange && onPageChange(v - 1)}
        />
      </Box>
      )
    }
  </GridContainer>;
};

export default DataGrid;