import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ContentCopyIcon = (props) => {
  return <SvgIcon {...props} viewBox="0 0 24 24">
   <path d="M5 22q-.825 0-1.413-.587Q3 20.825 3 20V6h2v14h11v2Zm4-4q-.825 0-1.412-.587Q7 16.825 7 16V4q0-.825.588-1.413Q8.175 2 9 2h9q.825 0 1.413.587Q20 3.175 20 4v12q0 .825-.587 1.413Q18.825 18 18 18Zm0-2h9V4H9v12Zm0 0V4v12Z"/>
  </SvgIcon>
};

export default ContentCopyIcon;
