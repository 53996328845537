import React, { forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CheckCircleIcon from '../icons/CheckCircleIcon';
import WarningIcon from '../icons/WarningIcon';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

const Toast = ({ open, severity, variant, onClose, children }) => {
  return <Snackbar
    open={open}
    onClick={onClose}
    onClose={onClose}
    autoHideDuration={5000}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  >
    <Alert
      severity={severity}
      variant={variant}
      onClose={onClose}
      sx={{ width: '100%' }}
      iconMapping={{
        success: <CheckCircleIcon />,
        warning: <WarningIcon />,
        error: <WarningIcon />
      }}
    >
      {children}
    </Alert>
  </Snackbar>
};

export default Toast;